import React from 'react'
import { Button } from 'react-bootstrap'
import PropTypes from 'prop-types'
import TootipItem from './TootipItem'

function CardTool({ setting }) {
  const {
    handleAdd = () => {},
    handleRegenerate = () => {},
    handleDelete = () => {},
    onlyRegenerate = false,
    hideRegenerate = false,
    addText = '新增',
    delText = '刪除',
    renGenText = '重新生成',
  } = setting

  return (
    <>
      {!onlyRegenerate && (
        <>
          <Button
            className="btn-text"
            onClick={() => {
              handleAdd()
            }}
          >
            <TootipItem title={addText}>
              <div className="svg-icon-wrap">
                <div
                  className="svgIcon"
                  style={{
                    maskImage: `url('/images/svgicons/add.svg')`,
                  }}
                >
                  {addText}
                </div>
              </div>
            </TootipItem>
          </Button>
          <hr />
        </>
      )}
      {!hideRegenerate && (
        <Button
          className="btn-text"
          onClick={() => {
            handleRegenerate()
          }}
        >
          <TootipItem title={renGenText}>
            <div className="svg-icon-wrap">
              <div
                className="svgIcon"
                style={{
                  maskImage: `url('/images/svgicons/generate.svg')`,
                }}
              >
                {renGenText}
              </div>
            </div>
          </TootipItem>
        </Button>
      )}
      {!onlyRegenerate && (
        <>
          <hr />
          <Button
            className="btn-text"
            onClick={() => {
              handleDelete()
            }}
          >
            <TootipItem title={delText}>
              <div className="svg-icon-wrap">
                <div
                  className="svgIcon"
                  style={{
                    maskImage: `url('/images/svgicons/trash.svg')`,
                  }}
                >
                  {delText}
                </div>
              </div>
            </TootipItem>
          </Button>
        </>
      )}
    </>
  )
}

CardTool.propTypes = {
  setting: PropTypes.shape().isRequired,
}

export default CardTool
