import React, { useContext, useEffect, useState } from 'react'
import { EditWrapper } from '../../components'
import { DraftContext } from '../../components/ContextProvider'
import Subnav from './Subnav'
import TableRow from './TableRow'
import TableColumn from './TableColumn'
import { activeInput } from '../../lib/utility'
import useCompetitiveStore from '../../store/useCompetitiveStore'
import {
  addItem,
  deleteCard,
  textEdit,
  arrayEdit,
  initCompetitive,
} from '../../components/CompetitiveUtils'

function Product() {
  const { draft, showGen, setShowGen } = useContext(DraftContext)
  const [module] = useState(draft.draft_id && draft ? draft : {})
  const [records, setRecords] = useState([])
  const [boardStyle, setBoardStyle] = useState('row')
  const { competitive, setField, price_symbol } = useCompetitiveStore(
    (state) => ({
      competitive: state.competitive,
      setField: state.setField,
      price_symbol: state.price_symbol,
    })
  )

  const boards = [
    {
      id: 1,
      title: '橫列比對',
      image: '/images/boards/board-row.svg',
      key: 'row',
    },
    {
      id: 2,
      title: '直欄比對',
      image: '/images/boards/board-column.svg',
      key: 'column',
    },
  ]

  const noteText = 'AI會根據您輸入的品牌名稱，生成對應的內容。例如：'
  const noteContent = [
    '自行車產業：Giant、Merida',
    '健身服務：ClassPass、Peloton',
  ]

  const switchBoard = (board) => {
    setBoardStyle(board)
  }

  useEffect(() => {
    if (module.module2) {
      setRecords(
        module.module2.product.history && module.module2.product.history
      )
    }
  }, [module])

  useEffect(() => {
    activeInput()
    if (module.module2) {
      initCompetitive(setField, module.module2.json_url)
    }
  }, [])

  // useEffect(() => {
  //   if (competitive.length === 0) {
  //     if (module.module2) {
  //       console.log('aaaa', module.module2.json_url)
  //       initCompetitive(setField, module.module2.json_url)
  //     }
  //   }
  // }, [competitive, setField])

  const reorder = (cardlist, startIndex, endIndex) => {
    const result = Array.from(cardlist)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)

    return result
  }

  const onDragEnd = (result) => {
    const { source, destination } = result

    if (!destination) {
      return
    }

    setField(
      'competitive',
      reorder(competitive, source.index, result.destination.index)
    )
  }

  // loading
  const [showLoading, setShowLoading] = useState(true)
  const [keyword, setKeyword] = useState('')

  useEffect(() => {
    if (!module.title) {
      setKeyword('')
      setShowLoading(false)
      setShowGen({
        ...showGen,
        show: true,
        isToolBarBtn: true,
        noteText:
          '生成競品分析有兩個關鍵要素：<strong>「市場」</strong>和<strong>「產業/服務」</strong>，您可用習慣的句型輸入提示語。',
        noteContent: [
          '<strong>歐洲</strong>可以賣<strong>珍珠奶茶</strong>嗎？',
          '<strong>泰國</strong> <strong>電動輔助自行車</strong> 城鄉需求',
          '我想分析Netflix造成<strong>韓國旅遊市場</strong>的新興趨勢',
        ],
        showOther: '*若無輸入特定參數，AI會根據上下文自動匹配適合的內容。',
        modal: 0,
        func: () => {},
        placeholder: '我想分析 [市場] 的 [產業/服務]',
      })
    } else {
      setKeyword('分析台灣市場的自行車產業')
    }

    if (showLoading) {
      setTimeout(() => {
        setShowLoading(false)
      }, 1000)
    }
  }, [])

  return (
    module.module2 && (
      <EditWrapper
        setting={{
          showInput: module.show_input,
          toolbarTitle: '生成競品分析',
          toolbarTitleDesc:
            '生成競品分析有兩個關鍵要素：<strong>「市場」</strong>和<strong>「產業/服務」</strong>。',
          moduleTitle: module.title,
          moduleKeyword: keyword,
          records,
          boards,
          boardStyle,
          switchBoard,
        }}
      >
        <>
          <Subnav setting={{ currentID: 1 }} />
          <div className="tab-panel">
            {boardStyle === 'row' && (
              <TableRow
                setting={{
                  module: competitive,
                  onDragEnd,
                  addItem: (labelID) => addItem(labelID, competitive, setField),
                  deleteCard: (labelID) =>
                    deleteCard(labelID, competitive, setField),
                  textEdit: (id, value, label) =>
                    textEdit(id, value, label, competitive, setField),
                  arrayEdit: (label, value, type, i) =>
                    arrayEdit(label, value, type, i, competitive, setField),
                  priceType: price_symbol,
                  setPriceType: (value) => {
                    setField('price_symbol', value)
                  },
                  noteText,
                  noteContent,
                  showLoading,
                }}
              />
            )}

            {boardStyle === 'column' && (
              <TableColumn
                setting={{
                  module: competitive,
                  onDragEnd,
                  addItem: (labelID) => addItem(labelID, competitive, setField),
                  deleteCard: (labelID) =>
                    deleteCard(labelID, competitive, setField),
                  textEdit: (id, value, label) =>
                    textEdit(id, value, label, competitive, setField),
                  arrayEdit: (label, value, type, i) =>
                    arrayEdit(label, value, type, i, competitive, setField),
                  priceType: price_symbol,
                  noteText,
                  noteContent,
                  showLoading,
                }}
              />
            )}
          </div>
        </>
      </EditWrapper>
    )
  )
}

export default Product
