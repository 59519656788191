import React from 'react'
import PropTypes from 'prop-types'

function SvgEmoji(props) {
  if (props.value === 'smile') {
    return <Smile x={props.x} y={props.y} />
  }

  if (props.value === 'bad') {
    return <Bad x={props.x} y={props.y} />
  }

  if (props.value === 'normal') {
    return <Normal x={props.x} y={props.y} />
  }

  return ''
}

function Normal({ x = 0, y = 0 }) {
  return (
    <svg className="emojiIcon" fill="#FFD764" y={y} x={x}>
      <g>
        <path
          d="M16 31C24.2843 31 31 24.2843 31 16C31 7.71573 24.2843 1 16 1C7.71573 1 1 7.71573 1 16C1 24.2843 7.71573 31 16 31Z"
          stroke="none"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10 22H22"
          stroke="#212121"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10 16C11.1046 16 12 15.1046 12 14C12 12.8954 11.1046 12 10 12C8.89543 12 8 12.8954 8 14C8 15.1046 8.89543 16 10 16Z"
          fill="#212121"
        />
        <path
          d="M22 16C23.1046 16 24 15.1046 24 14C24 12.8954 23.1046 12 22 12C20.8954 12 20 12.8954 20 14C20 15.1046 20.8954 16 22 16Z"
          fill="#212121"
        />
      </g>
    </svg>
  )
}

function Smile({ x = 0, y = 0 }) {
  return (
    <svg className="emojiIcon" fill="#FFD764" y={y} x={x}>
      <g>
        <path
          d="M16 31C24.2843 31 31 24.2843 31 16C31 7.71573 24.2843 1 16 1C7.71573 1 1 7.71573 1 16C1 24.2843 7.71573 31 16 31Z"
          stroke="none"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10 16C11.1046 16 12 15.1046 12 14C12 12.8954 11.1046 12 10 12C8.89543 12 8 12.8954 8 14C8 15.1046 8.89543 16 10 16Z"
          fill="#212121"
        />
        <path
          d="M22 16C23.1046 16 24 15.1046 24 14C24 12.8954 23.1046 12 22 12C20.8954 12 20 12.8954 20 14C20 15.1046 20.8954 16 22 16Z"
          fill="#212121"
        />
        <path
          d="M21.2 22C20.674 22.9139 19.9163 23.673 19.0034 24.2009C18.0905 24.7287 17.0546 25.0067 16 25.0067C14.9455 25.0067 13.9096 24.7287 12.9967 24.2009C12.0838 23.673 11.3261 22.9139 10.8 22"
          stroke="#212121"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  )
}

function Bad({ x = 0, y = 0 }) {
  return (
    <svg className="emojiIcon" fill="#FFD764" y={y} x={x}>
      <g>
        <path
          d="M16 31C24.2843 31 31 24.2843 31 16C31 7.71573 24.2843 1 16 1C7.71573 1 1 7.71573 1 16C1 24.2843 7.71573 31 16 31Z"
          stroke="none"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10 16C11.1046 16 12 15.1046 12 14C12 12.8954 11.1046 12 10 12C8.89543 12 8 12.8954 8 14C8 15.1046 8.89543 16 10 16Z"
          fill="#212121"
        />
        <path
          d="M22 16C23.1046 16 24 15.1046 24 14C24 12.8954 23.1046 12 22 12C20.8954 12 20 12.8954 20 14C20 15.1046 20.8954 16 22 16Z"
          fill="#212121"
        />
        <path
          d="M21.2 23.9998C20.674 23.0859 19.9163 22.3268 19.0034 21.7989C18.0905 21.2711 17.0546 20.9932 16 20.9932C14.9455 20.9932 13.9096 21.2711 12.9967 21.7989C12.0838 22.3268 11.3261 23.0859 10.8 23.9998"
          stroke="#212121"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  )
}

SvgEmoji.propTypes = {
  value: PropTypes.string,
  x: PropTypes.number,
  y: PropTypes.number,
}

Smile.propTypes = {
  x: PropTypes.number,
  y: PropTypes.number,
}

Bad.propTypes = {
  x: PropTypes.number,
  y: PropTypes.number,
}

Normal.propTypes = {
  x: PropTypes.number,
  y: PropTypes.number,
}

export default SvgEmoji
