import React from 'react'
import PropTypes from 'prop-types'
import { Row, Col } from 'react-bootstrap'
import ParentSize from '@visx/responsive/lib/components/ParentSize'
import { Pie } from '@visx/shape'
import { Group } from '@visx/group'

const defaultMargin = { top: 0, right: 0, bottom: 0, left: 0 }

function CircleItem({ pieProps }) {
  const { width, height, margin = defaultMargin, values, colors } = pieProps
  const innerWidth = width - margin.left - margin.right
  const innerHeight = height - margin.top - margin.bottom
  const radius = Math.min(innerWidth, innerHeight) / 2
  const centerY = innerHeight / 2
  const centerX = innerWidth / 2
  const top = centerY + margin.top
  const left = centerX + margin.left
  const pieSortValues = (a, b) => {
    if (a > b) {
      return a - b
    }

    return b - a
  }

  // const colors = ['#3CFFDD', '#A167EC', '#012DFF']
  // const values = [
  //   { symbol: 'File Image', value: 20 },
  //   { symbol: 'File 2', value: 30 },
  //   { symbol: 'File 3', value: 50 },
  // ]

  return (
    <div className="circleItem">
      <svg width={width} height={height}>
        <Group top={top} left={left}>
          <Pie
            data={values}
            pieValue={(data) => data.value}
            pieSortValues={pieSortValues}
            outerRadius={radius}
            innerRadius={radius - 40}
          >
            {(pie) =>
              pie.arcs.map((arc, index) => {
                const { letter } = arc.data
                const arcPath = pie.path(arc)

                return (
                  <g key={`arc-${letter}-${index}`}>
                    <path d={arcPath} fill={colors[index]} />
                  </g>
                )
              })
            }
          </Pie>
        </Group>
      </svg>
    </div>
  )
}

function PieChat({ setting }) {
  const { values, colors } = setting
  return (
    <div className="articleBlock text-start">
      <Row>
        <Col>
          <ParentSize>
            {({ width }) => (
              <CircleItem
                pieProps={{
                  width,
                  height: '182',
                  values,
                  colors,
                }}
              />
            )}
          </ParentSize>
        </Col>
      </Row>
    </div>
  )
}

PieChat.propTypes = {
  setting: PropTypes.shape().isRequired,
}

CircleItem.propTypes = {
  pieProps: PropTypes.shape().isRequired,
}

export default PieChat
