/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useContext } from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFloppyDisk } from '@fortawesome/free-solid-svg-icons'
import {
  Form,
  Button,
  Image,
  DropdownButton,
  OverlayTrigger,
  Tooltip,
} from 'react-bootstrap'
import { DraftContext } from './ContextProvider'
import ImportBtn from './ImportBtn'

function ToolBar({ setting }) {
  const {
    openRecord = () => {},
    openBoard = () => {},
    moduleKeyword = '',
    toolbarTitle = '',
    toolbarTitleDesc = '',
  } = setting
  const { cursor, history, handleRecover, currentID } = useContext(DraftContext)
  const [saveText, setSaveText] = useState(false)
  const showUndoRedo = true

  const showSaveText = () => {
    setSaveText(true)
    const timer = setTimeout(() => {
      setSaveText(false)
    }, 3000)
    return () => clearTimeout(timer)
  }

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      上傳文件讓AI根據您提供的資料生成客製化內容
    </Tooltip>
  )

  const [inputFocus, setInputFocus] = useState(false)

  return (
    <div
      id="editToolbar"
      className={`edit-toolbar ${inputFocus ? 'focus' : ''}`}
    >
      <Form className="toolbar-form">
        <strong className="toolbar-title">
          {toolbarTitle}
          <OverlayTrigger
            placement="bottom"
            overlay={
              <Tooltip className="style-dark">
                <p
                  className="note-text"
                  dangerouslySetInnerHTML={{ __html: toolbarTitleDesc }}
                />
              </Tooltip>
            }
          >
            <span className="note-span border-0">
              <Image
                src="/images/svgicons/tooltip.svg"
                alt=""
                className="img-fluid"
              />
            </span>
          </OverlayTrigger>
        </strong>
        <div className="form-control-wrap">
          <OverlayTrigger
            placement="bottom"
            delay={{ show: 250, hide: 400 }}
            overlay={renderTooltip}
          >
            <Form.Label className="mb-0 file-input" htmlFor="file">
              <div
                className="svgIcon"
                style={{
                  maskImage: `url('/images/svgicons/keyword.svg')`,
                }}
              >
                keyword
              </div>
            </Form.Label>
          </OverlayTrigger>
          <Form.Control
            type="file"
            id="file"
            name="file"
            defaultValue={null}
            className="d-none"
            onChange={() => {}}
          />
          <Form.Control
            id="keyword"
            name="keyword"
            type="text"
            placeholder={
              currentID === 1 || currentID === 2
                ? '我想分析 [市場] 的 [產業/服務]'
                : '經常 [描述行為特徵] 的 [角色/職業]，想要 [達成什麼目標/任務]'
            }
            className="border-0 flex-1 mx-0 bg-white"
            aria-label="keyword"
            defaultValue={moduleKeyword}
            onFocus={() => {
              setInputFocus(true)
            }}
            onBlur={() => {
              setInputFocus(false)
            }}
          />
          <Button className="btn-regen" onClick={() => {}}>
            <span className="regen-text">重新生成</span>
            <span
              className="svgIcon"
              style={{
                maskImage: `url('/images/svgicons/generate.svg')`,
              }}
            >
              重新生成
            </span>
          </Button>
        </div>
        <Button
          className="btn-primary"
          style={{
            whiteSpace: 'nowrap',
          }}
          onClick={() => {
            showSaveText()
          }}
        >
          <FontAwesomeIcon
            icon={faFloppyDisk}
            style={{
              transform: 'translateY(1px)',
              marginRight: '1rem',
            }}
          />
          儲存
        </Button>
        <div className={`saveText text-nowrap ${saveText ? 'show' : ''} `}>
          <Button className="btn-text btn-link" onClick={() => openRecord()}>
            查看其他儲存紀錄
          </Button>
        </div>
      </Form>
      <div className="d-flex justify-content-end align-items-center">
        {showUndoRedo && (
          <div className="module-setting control-bar">
            <ul className="toggle-wrap-nav nav-hor">
              <li>
                <Button
                  disabled={!cursor}
                  className="btn-text"
                  onClick={() => handleRecover(cursor - 1)}
                >
                  <Image src="/images/svgicons/undo.svg" alt="" />
                  UNDO
                </Button>
              </li>
              <li>
                <Button
                  className="btn-text"
                  disabled={cursor === history.length - 1}
                  onClick={() => handleRecover(cursor + 1)}
                >
                  <Image src="/images/svgicons/redo.svg" alt="" />
                  REDO
                </Button>
              </li>
            </ul>
          </div>
        )}
        <div className="module-setting">
          <DropdownButton
            title={
              <div
                className="align-items-center d-flex"
                style={{ height: '30px' }}
              >
                <div
                  className="svgIcon settingIcon bg-primary"
                  style={{
                    maskImage: `url('/images/svgicons/setting.svg')`,
                  }}
                >
                  設定
                </div>
              </div>
            }
          >
            <div className="toggle-wrap shadow-block">
              <div className="toggle-btn toggle-btn1">
                <Button className="btn-text">
                  <div
                    className="svgIcon"
                    style={{
                      maskImage: `url('/images/svgicons/xlsx.svg')`,
                      transform: 'translateY(1px)',
                    }}
                  >
                    xlsx
                  </div>
                  匯出 xlsx
                </Button>
              </div>
              <div className="toggle-btn toggle-btn2">
                <Button className="btn-text">
                  <div
                    className="svgIcon"
                    style={{
                      maskImage: `url('/images/svgicons/pdf.svg')`,
                      transform: 'translateY(1px)',
                    }}
                  >
                    pdf
                  </div>
                  匯出 pdf
                </Button>
              </div>
              <div className="toggle-btn toggle-btn3">
                <ImportBtn
                  setting={{
                    className: 'btn-text',
                    currentID,
                  }}
                />
              </div>
              <div className="toggle-btn">
                <Button className="btn-text" onClick={() => openRecord()}>
                  <Image src="/images/svgicons/clock.svg" alt="" />
                  版本紀錄
                </Button>
              </div>
            </div>
          </DropdownButton>
        </div>
        <div className="module-setting">
          <Button
            className="btn-primary"
            onClick={() => {
              openBoard()
            }}
          >
            <Image src="/images/svgicons/board.svg" className="mx-3" alt="" />
            <span>更換模板</span>
          </Button>
        </div>
      </div>
    </div>
  )
}

ToolBar.propTypes = {
  setting: PropTypes.shape().isRequired,
}

export default ToolBar
