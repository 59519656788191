/* eslint-disable no-promise-executor-return */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect, useContext } from 'react'
import PropTypes from 'prop-types'
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd'
import { Form, Image } from 'react-bootstrap'
import ImgCard from '../../tools/ImgCard'
import { DraftContext } from '../../components/ContextProvider'
import TootipItem from '../../components/TootipItem'
import CardTool from '../../components/CardTool'

function TableColumn({ setting }) {
  const {
    module,
    onDragEnd = () => {},
    addItem = () => {},
    textEdit = () => {},
    arrayEdit = () => {},
    deleteCard = () => {},
    priceType,
    noteText,
    noteContent = [],
    showLoading,
  } = setting

  // 新增時, 判斷是否loading
  const [newColumn, setNewColumn] = useState(-1)
  const isNewColumn = (newIndex) => {
    setNewColumn(newIndex)
    setTimeout(() => {
      setNewColumn(-1)
    }, 1000)
  }

  const dragItemStyle = (isDragging, draggableStyle) => ({
    userSelect: 'none',
    border: isDragging ? '2px solid #3F75ED' : 'none',
    ...draggableStyle,
  })

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <div className="edit-block">
        <div className="d-flex">
          <div className="table-column-header">
            <div className="table-column-title" style={{ height: '192px' }}>
              品牌
            </div>
            <div className="table-column-title" style={{ height: '212px' }}>
              優勢
            </div>
            <div className="table-column-title" style={{ height: '212px' }}>
              劣勢
            </div>
            <div className="table-column-title" style={{ height: '43px' }}>
              價格區間
            </div>
            <div className="table-column-title" style={{ height: '72px' }}>
              產品特點
            </div>
            <div className="table-column-title" style={{ height: '50px' }}>
              主要客群
            </div>
          </div>
          <Droppable droppableId="columnDrag" direction="horizontal">
            {(cprovided) => (
              <div className="table-column-wrap" ref={cprovided.innerRef}>
                <div className="table-column-inner">
                  {module.map((column, index) => (
                    <Draggable
                      key={column.id}
                      draggableId={column.id}
                      index={index}
                    >
                      {(dragProvided, dragSnapshot) => (
                        <div
                          className="table-column-2 table-column-info has-drag"
                          ref={dragProvided.innerRef}
                          {...dragProvided.draggableProps}
                          style={dragItemStyle(
                            dragSnapshot.isDragging,
                            dragProvided.draggableProps.style
                          )}
                          key={`column-${index}`}
                        >
                          <TootipItem title="拖曳變更排序">
                            <div
                              className="drag-bar"
                              {...dragProvided.dragHandleProps}
                            >
                              <div className="module-control-btn">
                                <Image
                                  src="/images/toolicons/drag.svg"
                                  alt=""
                                />
                              </div>
                            </div>
                          </TootipItem>
                          <div
                            className="table-column-box d-flex align-items-center justify-content-center"
                            style={{ height: '192px' }}
                          >
                            <div>
                              <div className="img text-center">
                                {column?.logo ? (
                                  <ImgCard
                                    setting={{
                                      img: column?.logo,
                                      isZoom: false,
                                      target_blank: false,
                                      isRegenerate: false,
                                      isDownload: false,
                                      showLoading:
                                        newColumn === index
                                          ? true
                                          : showLoading,
                                    }}
                                  />
                                ) : (
                                  <ImgCard
                                    setting={{
                                      img: '/images/none.svg',
                                      isZoom: false,
                                      target_blank: false,
                                      isRegenerate: false,
                                      isDownload: false,
                                      showLoading:
                                        newColumn === index
                                          ? true
                                          : showLoading,
                                    }}
                                  />
                                )}
                              </div>
                              <Form.Control
                                name="brand_name"
                                type="text"
                                placeholder="品牌名稱"
                                defaultValue={column.brand_name}
                                className="text-center"
                                onChange={(e) => {
                                  textEdit(
                                    column.id,
                                    e.target.value,
                                    'brand_name'
                                  )
                                }}
                              />
                            </div>
                          </div>
                          <ColumnItem
                            setting={{
                              labelID: column.id,
                              addItem,
                              deleteCard,
                              column,
                              arrayEdit,
                              textEdit,
                              priceType,
                              showLoading: newColumn === index,
                              index,
                              isNewColumn,
                              noteText,
                              noteContent,
                            }}
                          />
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {cprovided.placeholder}
                </div>
              </div>
            )}
          </Droppable>
        </div>
      </div>
    </DragDropContext>
  )
}

function ColumnItem({ setting }) {
  const {
    labelID,
    addItem = () => {},
    deleteCard = () => {},
    column,
    arrayEdit = () => {},
    textEdit = () => {},
    priceType,
    showLoading,
    index,
    isNewColumn,
    noteText,
    noteContent = [],
  } = setting
  const { showWarn, setShowWarn, showGen, setShowGen } =
    useContext(DraftContext)

  const [itemLoading, setItemLoading] = useState(showLoading)

  useEffect(() => {
    if (itemLoading) {
      setTimeout(() => {
        setItemLoading(false)
      }, 1000)
    }
  }, [itemLoading])

  const handleAdd = () => {
    setShowGen({
      ...showGen,
      show: true,
      noteText,
      noteContent,
      modal: 0,
      func: () => {
        isNewColumn(index + 1)
        addItem(labelID)
      },
      titleLabel: '品牌名稱',
      placeholder: '例: Gogoro',
    })
  }

  const handleDelete = () => {
    setShowWarn({
      ...showWarn,
      show: true,
      text: '確定移除列表？',
      func: () => {
        isNewColumn(-1)
        deleteCard(labelID)
      },
    })
  }

  const handleRegenerate = () => {
    setItemLoading(true)
  }

  return (
    <>
      <div className="card-tool pos-left d-block">
        <CardTool
          setting={{
            handleAdd,
            handleRegenerate,
            handleDelete,
          }}
        />
      </div>
      <div className="table-column-box" style={{ height: '212px' }}>
        {itemLoading && <Loading setting={{ style: '' }} />}
        <div className={`fade ${itemLoading ? '' : 'show'}`}>
          {column.strengths.map((value, j) => (
            <div key={`advances${j}`}>
              <Form.Control
                name="advances[]"
                as="textarea"
                placeholder="優勢"
                defaultValue={value}
                style={{
                  height: '100%',
                  resize: 'none',
                  overflowY: 'hidden',
                }}
                onChange={(e) => {
                  arrayEdit(column.id, e.target.value, 'strength', j)
                }}
              />
            </div>
          ))}
        </div>
      </div>
      <div className="table-column-box" style={{ height: '212px' }}>
        {itemLoading && <Loading setting={{ style: '' }} />}
        <div className={`fade ${itemLoading ? '' : 'show'}`}>
          {column.weaknesses.map((value, j) => (
            <div className="table-column-info" key={`weak${j}`}>
              <Form.Control
                name="weak[]"
                as="textarea"
                placeholder="劣勢"
                defaultValue={value}
                style={{
                  height: '100%',
                  resize: 'none',
                  overflowY: 'hidden',
                }}
                onChange={(e) => {
                  arrayEdit(column.id, e.target.value, 'weaknesses', j)
                }}
              />
            </div>
          ))}
        </div>
      </div>
      <div className="table-column-box" style={{ height: '43px' }}>
        {itemLoading && <Loading setting={{ style: 'one' }} />}
        <div className={`fade ${itemLoading ? '' : 'show'}`}>
          <div className="d-flex align-items-center">
            <Form.Control
              name="price1"
              type="text"
              placeholder="價格"
              defaultValue={`${priceType} ${column.price_banding_min}`}
              onChange={(e) => {
                textEdit(column.id, e.target.value, 'price_banding_min')
              }}
              className="text-end"
            />
            <span className="px-2">~</span>
            <Form.Control
              name="price2"
              type="text"
              placeholder="價格"
              defaultValue={`${priceType} ${column.price_banding_max}`}
              onChange={(e) => {
                textEdit(column.id, e.target.value, 'price_banding_max')
              }}
            />
          </div>
        </div>
      </div>
      <div className="table-column-box" style={{ height: '72px' }}>
        {itemLoading && <Loading setting={{ style: 'one' }} />}
        <div className={`fade ${itemLoading ? '' : 'show'}`}>
          <Form.Control
            name="sp"
            as="textarea"
            placeholder="產品特點"
            defaultValue={column.features}
            style={{
              height: '100%',
              resize: 'none',
              overflowY: 'hidden',
            }}
            onChange={(e) => {
              textEdit(column.id, e.target.value, 'features')
            }}
          />
        </div>
      </div>
      <div className="table-column-box" style={{ height: '50px' }}>
        {itemLoading && <Loading setting={{ style: 'one' }} />}
        <div className={`fade ${itemLoading ? '' : 'show'}`}>
          <Form.Control
            name="audience"
            as="textarea"
            placeholder="主要客群"
            defaultValue={column.audiences}
            style={{ height: '100%' }}
            onChange={(e) => {
              textEdit(column.id, e.target.value, 'audiences')
            }}
          />
        </div>
      </div>
    </>
  )
}

function Loading({ setting }) {
  const { style } = setting

  if (style === 'one') {
    return (
      <div
        className="loading-wrap"
        style={{
          position: 'static',
          marginTop: '10px',
          marginLeft: '10px',
          marginRight: '10px',
        }}
      >
        <div className="loading-bar">loading</div>
      </div>
    )
  }

  return (
    <div className="loading-wrap">
      <div className="loading-bar mb-2">loading</div>
      <div className="loading-bar mb-4">loading</div>
      <div className="loading-bar mb-2">loading</div>
      <div className="loading-bar mb-4">loading</div>
      <div className="loading-bar mb-2">loading</div>
      <div className="loading-bar">loading</div>
    </div>
  )
}

Loading.propTypes = {
  setting: PropTypes.shape().isRequired,
}

ColumnItem.propTypes = {
  setting: PropTypes.shape().isRequired,
}

TableColumn.propTypes = {
  setting: PropTypes.shape().isRequired,
}

export default TableColumn
