import React from 'react'
import { useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Image, Button, Modal } from 'react-bootstrap'

function Warn({ setting }) {
  const {
    show = false,
    text,
    icon,
    handleClose,
    handleConfirm,
    editText = '確定',
    cancelText = '關閉',
    parent = '/module1',
  } = setting
  const navigate = useNavigate()

  return (
    <Modal
      show={show !== false}
      onHide={() => handleClose(false)}
      className="warning-block"
      centered
    >
      <Modal.Body className="">
        {icon && (
          <div className="warning-icon">
            <Image
              src={icon}
              width="104"
              height="auto"
              className="img-fluid mx-auto d-block"
            />
          </div>
        )}
        <div className="warning-title">{text}</div>
      </Modal.Body>
      {handleConfirm ? (
        <Modal.Footer className="justify-content-center border-0">
          <Button
            variant="primary"
            size="lg"
            onClick={() => {
              handleConfirm()
              handleClose()
            }}
          >
            {editText}
          </Button>
          <Button variant="primary" size="lg" onClick={handleClose}>
            {cancelText}
          </Button>
        </Modal.Footer>
      ) : (
        <Modal.Footer className="justify-content-center border-0">
          <Button
            variant="primary"
            size="lg"
            onClick={() => {
              navigate(parent)
            }}
          >
            返回專案
          </Button>
          <Button variant="primary" size="lg" onClick={handleClose}>
            繼續編輯
          </Button>
        </Modal.Footer>
      )}
    </Modal>
  )
}

Warn.propTypes = {
  setting: PropTypes.shape().isRequired,
}

export default Warn
