import { v4 as uuid } from 'uuid'

export const moduleData = [
  {
    draft_id: 100,
    title: '電動自行車專案',
    note: '瀏覽所有生成好的：PESTEL、競品分析、顧客旅程、人物誌。#電動自行車 #台灣 #上班族',
    status: 'AI設計策略',
    update_date: '2023-12-02 12:27',
    img: '01.png',
    is_enable: true,
    module1: {
      json_urls: [
        {
          url: 'data/100/pestel/politic.json',
          dataName: 'politic',
        },
        {
          url: 'data/100/pestel/economic.json',
          dataName: 'economic',
        },
        {
          url: 'data/100/pestel/social.json',
          dataName: 'social',
        },
        {
          url: 'data/100/pestel/technology.json',
          dataName: 'technology',
        },
        {
          url: 'data/100/pestel/environment.json',
          dataName: 'environment',
        },
        {
          url: 'data/100/pestel/law.json',
          dataName: 'law',
        },
      ],
    },
    module2: {
      json_url: '/data/100/competitive_product_analysis/competitive.json',
      product: {},
      matrix: {},
      design: {
        json_urls: [
          {
            url: '/data/100/competitive_product_analysis/design.json',
            dataName: 'opportunities_and_challenges',
            target: '2-3_opportunities-and-challenges',
          },
        ],
      },
      inovation: {
        json_urls: [
          {
            url: '/data/100/competitive_product_analysis/product_innovation.json',
            dataName: 'product_innovation',
            target: '2-4-1_product-innovation',
          },
          {
            url: '/data/100/competitive_product_analysis/service_innovation.json',
            dataName: 'service_innovation',
            target: '2-4-2_service-innovation',
          },
          {
            url: '/data/100/competitive_product_analysis/business_model.json',
            dataName: 'business_model',
            target: '2-4-3_business-model',
          },
          {
            url: '/data/100/competitive_product_analysis/infra_develop.json',
            dataName: 'infra_develop',
            target: '2-4-4_infra-develop',
          },
        ],
      },
    },
    module3: {
      title: '中年男子/騎電動自行車上班',
      json_urls: [
        {
          url: 'data/100/customer_journey/user_info.json',
          dataName: 'user_info',
        },
        {
          url: 'data/100/customer_journey/journeys.json',
          dataName: 'journeys',
        },
        {
          url: 'data/100/customer_journey/emotions.json',
          dataName: 'emotions',
        },
        {
          url: 'data/100/customer_journey/opportunities_and_painpoints.json',
          dataName: 'opportunities_and_painpoints',
        },
      ],
    },
    module4: {
      title: '帶粗框眼鏡/穿西裝/中年上班族男子',
      json_urls: [
        {
          url: 'data/100/persona/user_info.json',
          dataName: 'user_info',
        },
        {
          url: 'data/100/persona/quote.json',
          dataName: 'quote',
        },
        {
          url: 'data/100/persona/health_advocate_profile.json',
          dataName: 'health_advocate_profile',
        },
      ],
      category: [
        {
          id: uuid(),
          newList: {
            [uuid()]: {
              cards: [],
            },
          },
        },
      ],
    },
  },
  {
    draft_id: 99,
    title: '',
    note: '',
    status: '',
    update_date: '2024-01-02 12:27',
    img: '02.png',
    is_enable: true,
    module1: {
      json_urls: [
        {
          url: 'data/99/pestel/politic.json',
          dataName: 'politic',
        },
        {
          url: 'data/99/pestel/economic.json',
          dataName: 'economic',
        },
        {
          url: 'data/99/pestel/social.json',
          dataName: 'social',
        },
        {
          url: 'data/99/pestel/technology.json',
          dataName: 'technology',
        },
        {
          url: 'data/99/pestel/environment.json',
          dataName: 'environment',
        },
        {
          url: 'data/99/pestel/law.json',
          dataName: 'law',
        },
      ],
    },
    module2: {
      json_url: '/data/99/competitive_product_analysis/competitive.json',
      product: {},
      matrix: {},
      design: {
        json_urls: [
          {
            url: '/data/99/competitive_product_analysis/design.json',
            dataName: 'opportunities_and_challenges',
            target: '2-3_opportunities-and-challenges',
          },
        ],
      },
      inovation: {
        json_urls: [
          {
            url: '/data/99/competitive_product_analysis/product_innovation.json',
            dataName: 'product_innovation',
            target: '2-4-1_product-innovation',
          },
          {
            url: '/data/99/competitive_product_analysis/service_innovation.json',
            dataName: 'service_innovation',
            target: '2-4-2_service-innovation',
          },
          {
            url: '/data/99/competitive_product_analysis/business_model.json',
            dataName: 'business_model',
            target: '2-4-3_business-model',
          },
          {
            url: '/data/99/competitive_product_analysis/infra_develop.json',
            dataName: 'infra_develop',
            target: '2-4-4_infra-develop',
          },
        ],
      },
    },
    module3: {
      title: '',
      json_urls: [
        {
          url: 'data/99/customer_journey/user_info.json',
          dataName: 'user_info',
        },
        {
          url: 'data/99/customer_journey/journeys.json',
          dataName: 'journeys',
        },
        {
          url: 'data/99/customer_journey/emotions.json',
          dataName: 'emotions',
        },
        {
          url: 'data/99/customer_journey/opportunities_and_painpoints.json',
          dataName: 'opportunities_and_painpoints',
        },
      ],
    },
    module4: {
      title: '',
      json_urls: [
        {
          url: 'data/99/persona/user_info.json',
          dataName: 'user_info',
        },
        {
          url: 'data/99/persona/quote.json',
          dataName: 'quote',
        },
        {
          url: 'data/99/persona/health_advocate_profile.json',
          dataName: 'health_advocate_profile',
        },
      ],
      category: [
        {
          id: uuid(),
          newList: {
            [uuid()]: {
              cards: [],
            },
          },
        },
      ],
    },
  },
]

export default ''
