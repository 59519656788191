import React from 'react'
import PropTypes from 'prop-types'
import TextArea from './TextArea'

function Title({ setting }) {
  const { content = '', showLoading = true } = setting
  return (
    <div className="text-wrap">
      <TextArea
        setting={{
          value: content,
          labelId: 0,
          showLoading,
        }}
      />
    </div>
  )
}

Title.propTypes = {
  setting: PropTypes.shape().isRequired,
}

export default Title
