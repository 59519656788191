import React, { useState, useEffect, useContext } from 'react'
import { DraftContext } from '../components/ContextProvider'
import { EditWrapper } from '../components'
import SuggestCard from '../components/SuggestCard'
import { activeInput, initMultipleDataFromJson } from '../lib/utility'
import { usePestelStore } from '../store'

function Module1() {
  const { draft, showGen, setShowGen } = useContext(DraftContext)
  const [module] = useState(draft.draft_id && draft ? draft : { module1: {} })
  const [boardStyle, setBoardStyle] = useState('card')
  const { politic, economic, social, technology, environment, law, setField } =
    usePestelStore((store) => ({
      politic: store.politic,
      economic: store.economic,
      social: store.social,
      technology: store.technology,
      environment: store.environment,
      law: store.law,
      setField: store.setField,
    }))

  const boards = [
    {
      id: 1,
      title: '卡片式',
      image: '/images/boards/board-card.svg',
      key: 'card',
    },
    {
      id: 2,
      title: '列表式',
      image: '/images/boards/board-list.svg',
      key: 'list',
    },
  ]

  const switchBoard = (board) => {
    setBoardStyle(board)
  }

  // loading
  const [showLoading, setShowLoading] = useState(false)

  const noteText =
    '生成PESTEL有兩個關鍵要素：<strong>「市場」</strong>和<strong>「產業/服務」</strong>'
  const [keyword, setKeyword] = useState('')

  useEffect(() => {
    activeInput()
    const jsonUrl = module.module1.json_urls
    // const json_urls = [
    //   {
    //     url: 'data/pestel/politic.json',
    //     dataName: 'politic',
    //   },
    //   {
    //     url: 'data/pestel/economic.json',
    //     dataName: 'economic',
    //   },
    //   {
    //     url: 'data/pestel/social.json',
    //     dataName: 'social',
    //   },
    //   {
    //     url: 'data/pestel/technology.json',
    //     dataName: 'technology',
    //   },
    //   {
    //     url: 'data/pestel/environment.json',
    //     dataName: 'environment',
    //   },
    //   {
    //     url: 'data/pestel/law.json',
    //     dataName: 'law',
    //   },
    // ]
    initMultipleDataFromJson(jsonUrl, setField)

    if (!module.title) {
      setKeyword('')
      setShowLoading(false)
      setShowGen({
        ...showGen,
        show: true,
        isToolBarBtn: true,
        noteText: `${noteText}，您可用習慣的句型輸入提示語。`,
        noteContent: [
          '<strong>歐洲</strong>可以賣<strong>珍珠奶茶</strong>嗎？',
          '<strong>泰國</strong> <strong>電動輔助自行車</strong> 城鄉需求',
          '我想分析Netflix造成<strong>韓國旅遊市場</strong>的新興趨勢',
        ],
        showOther: '*若無輸入特定參數，AI會根據上下文自動匹配適合的內容。',
        modal: 0,
        func: () => {
          setShowLoading(true)
        },
        placeholder: '我想分析 [市場] 的 [產業/服務]',
      })
    } else {
      setShowLoading(true)
      setTimeout(() => {
        setShowLoading(false)
      }, 1000)
      setKeyword('分析台灣市場的自行車產業')
    }
  }, [])

  // const [tab] = useState(0)

  return (
    <EditWrapper
      setting={{
        showInput: module.show_input,
        toolbarTitle: '生成PESTEL',
        toolbarTitleDesc:
          '生成PESTEL有兩個關鍵要素：<strong>「市場」</strong>和<strong>「產業/服務」</strong>。',
        moduleTitle: module.title,
        moduleKeyword: keyword,
        // records,
        boards,
        boardStyle,
        switchBoard,
      }}
    >
      <div className="tab-panel">
        <SuggestCard
          setting={{
            module: [politic, economic, social, technology, environment, law],
            boardStyle,
            showLoading,
          }}
        />
      </div>
    </EditWrapper>
  )
}

export default Module1
