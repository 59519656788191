import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { Image, Button } from 'react-bootstrap'

// 點選外面關閉功能
function useComponentVisible(isVisible) {
  const [isComponentVisible, setIsComponentVisible] = useState(isVisible)
  const ref = useRef(null)

  const handleClickOutside = (event) => {
    // console.log({ refCurrent: ref.current, evt: event.target })
    if (ref.current && !ref.current.contains(event.target)) {
      setIsComponentVisible(false)
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true)
    return () => {
      document.removeEventListener('click', handleClickOutside, true)
    }
  })

  return { ref, isComponentVisible, setIsComponentVisible }
}

function Board({ setting }) {
  const {
    closeBoard = () => {},
    boards = [],
    boardStyle,
    switchBoard,
  } = setting
  const { ref, isComponentVisible, setIsComponentVisible } =
    useComponentVisible(true)

  useEffect(() => {
    if (!isComponentVisible) {
      closeBoard()
    }
  }, [isComponentVisible])

  return (
    <div ref={ref}>
      {isComponentVisible && (
        <div className="rightSidenav">
          <div className="sidenav-header">
            <Image src="/images/svgicons/board.svg" alt="" />
            更換模板
            <Button
              className="btn-text close-btn"
              onClick={() => {
                closeBoard()
                setIsComponentVisible(false)
              }}
            >
              <div
                className="svgIcon"
                style={{
                  maskImage: `url('/images/svgicons/close.svg')`,
                }}
              >
                關閉
              </div>
            </Button>
          </div>
          <div className="scroll-sidebar">
            {boards.map((item, itemIndex) => (
              <div
                className="record-item bg-transparent"
                key={`record-item${itemIndex}`}
              >
                <Button
                  className="d-block btn-board"
                  active={boardStyle === item.key}
                  onClick={() => switchBoard(item.key)}
                >
                  <div className="img">
                    <Image src={item.image} className="w-100 d-block" alt="" />
                  </div>
                  {item.title}
                </Button>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  )
}

Board.propTypes = {
  setting: PropTypes.shape().isRequired,
}

export default Board
