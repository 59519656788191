import React from 'react'
import { Image, OverlayTrigger, Tooltip } from 'react-bootstrap'
import ExampleTooltip from '../ExampleTooltip'

function Market() {
  return (
    <div className="create-note">
      在市場研究中，有兩個關鍵要素：「
      <OverlayTrigger
        placement="bottom"
        overlay={
          <Tooltip className="style-dark">
            <p className="note-text">
              指國家、城市或特定人口特徵（如，亞太地區、台北、Z世代）。
            </p>
          </Tooltip>
        }
      >
        <span className="note-span">
          市場
          <Image
            src="/images/svgicons/tooltip-primary.svg"
            alt=""
            className="img-fluid"
          />
        </span>
      </OverlayTrigger>
      」和「
      <OverlayTrigger
        placement="bottom"
        overlay={
          <Tooltip className="style-dark">
            <p className="note-text">
              指需要了解的產業、服務類型、技術或趨勢（如，電動升降桌、到府安裝服務、永續設計趨勢）。
            </p>
          </Tooltip>
        }
      >
        <span className="note-span">
          產業/服務
          <Image
            src="/images/svgicons/tooltip-primary.svg"
            alt=""
            className="img-fluid"
          />
        </span>
      </OverlayTrigger>
      」。
      <ExampleTooltip
        setting={{
          text: '例如',
          title: '您可用習慣的句型輸入提示語：',
          content: [
            '<strong>歐洲</strong>可以賣<strong>珍珠奶茶</strong>嗎？',
            '<strong>泰國</strong> <strong>電動輔助自行車</strong> 城鄉需求',
            '我想分析Netflix造成<strong>韓國旅遊市場</strong>的新興趨勢',
          ],
          option: 1,
        }}
      />
      <span className="otherText d-block mt-2">
        * 若無輸入特定關鍵字，AI會根據上下文自動匹配適合的內容。
      </span>
    </div>
  )
}

export default Market
