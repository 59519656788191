import React, { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Form } from 'react-bootstrap'

function AutoHeightText({ setting }) {
  const {
    value,
    textName = '',
    placeholder = '',
    onChange = () => {},
    onEnter = () => {},
    showBorder = false,
    className = '',
  } = setting
  const [temp, setTemp] = useState(value)
  const textAreaRef = useRef(null)

  const resizeTextArea = () => {
    textAreaRef.current.style.height = 'auto'
    if (textAreaRef.current.scrollHeight > 0) {
      textAreaRef.current.style.height = `${textAreaRef.current.scrollHeight}px`
    }
  }

  useEffect(resizeTextArea, [temp])

  // useEffect(() => {
  //   setTemp(value)
  // }, [value])

  return (
    <div className="textarea-group">
      <Form.Control
        ref={textAreaRef}
        className={className}
        style={{
          resize: 'none',
          overflowY: 'hidden',
          border: showBorder ? '1px solid #E2E4E7' : 'none',
        }}
        name={textName}
        placeholder={placeholder}
        value={temp}
        as="textarea"
        onChange={(e) => {
          setTemp(e.target.value)
          onChange(e.target.value)
        }}
        onKeyDown={(e) => {
          onEnter(e)
        }}
      />
    </div>
  )
}

AutoHeightText.propTypes = {
  setting: PropTypes.shape().isRequired,
}

export default AutoHeightText
