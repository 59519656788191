import React, { useState, useEffect, useContext } from 'react'
import PropTypes from 'prop-types'
import { Image, Button, Form, Modal } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faDownload,
  faArrowUpRightFromSquare,
} from '@fortawesome/free-solid-svg-icons'
import { DraftContext } from '../components/ContextProvider'

function ImgCard({ setting }) {
  const {
    img = '/images/none.svg',
    uploadBtn = false,
    target_blank = true,
    isRegenerate = true,
    isDownload = true,
    isZoom = true,
    showLoading = true,
  } = setting
  const [show, setShow] = useState(false)
  const [showImg, setShowImg] = useState(img)
  const [mediaShow, setMediaShow] = useState(false)
  const [isLoading, setIsLoading] = useState(showLoading)
  const { showWarn, setShowWarn } = useContext(DraftContext)
  const handleOpen = () => {
    setShow(true)
  }
  const handleClose = () => {
    setShow(false)
  }

  const mediaHandle = (value) => {
    setMediaShow(value)
  }

  const regenerate = () => {
    setShow(false)
    setIsLoading(true)
    setTimeout(() => {
      setIsLoading(false)
    }, 1000)
  }

  useEffect(() => {
    setIsLoading(showLoading)
    if (isLoading) {
      setTimeout(() => {
        setIsLoading(false)
      }, 1000)
    }
  }, [showLoading])

  // 從媒體庫選擇圖片後更新圖片
  const updateImg = (newImg) => {
    setShowImg(newImg)
  }

  const deleteImg = () => {
    setShow(false)
    setShowWarn({
      ...showWarn,
      show: true,
      text: '確定移除圖片',
      func: () => {
        setShowImg('/images/none.svg')
      },
    })
  }

  return (
    <>
      <div
        className="img-card"
        onClick={handleOpen}
        onKeyDown={handleOpen}
        role="button"
        tabIndex={0}
      >
        <div className={`img-mask ${isLoading ? 'opacity-0' : ''}`}>
          {uploadBtn ? (
            <Button
              variant="btn-dai text-light m-auto"
              title="上傳"
              onClick={(e) => {
                e.stopPropagation()
              }}
            >
              <img src="/images/svgicons/replace.svg" alt="" />
            </Button>
          ) : (
            <Button
              variant="btn-dai text-light m-auto"
              title="取代"
              onClick={(e) => {
                setMediaShow(true)
                e.stopPropagation()
              }}
            >
              <img src="/images/svgicons/replace.svg" alt="" />
            </Button>
          )}
          {isRegenerate && (
            <Button
              title="重新生成"
              variant="btn-dai"
              href={img}
              onClick={(e) => {
                e.stopPropagation()
                e.preventDefault()
                regenerate()
              }}
            >
              <img src="/images/svgicons/generate-white.svg" alt="" />
            </Button>
          )}

          <Button
            variant="btn-dai text-light m-auto"
            title="刪除"
            onClick={(e) => {
              deleteImg()
              e.stopPropagation()
            }}
          >
            <img src="/images/svgicons/trash.svg" alt="" />
          </Button>
        </div>
        {isZoom && (
          <Button
            variant="btn-dai text-light zoom-icon"
            title="查看圖片"
            onClick={() => {}}
          >
            <img src="/images/svgicons/zoom.svg" alt="" />
          </Button>
        )}
        <Image src={showImg} alt="" className={isLoading ? 'opacity-0' : ''} />
        {isLoading && <Loading />}
      </div>
      <ImageModal
        setting={{
          show,
          handleClose,
          img: showImg,
          regenerate,
          deleteImg,
          target_blank,
          isRegenerate,
          isDownload,
        }}
      />
      <MediaModal setting={{ mediaShow, mediaHandle, updateImg }} />
    </>
  )
}

function ImageModal({ setting }) {
  const {
    show,
    handleClose,
    img,
    regenerate,
    deleteImg,
    target_blank,
    isRegenerate,
    isDownload,
  } = setting
  return (
    <Modal
      size="xl"
      show={show !== false}
      onHide={() => handleClose(false)}
      className="warning-block img-modal"
      centered
    >
      <Image src={img} alt="" className="w-100" />
      <div className="d-flex justify-content-end">
        {isRegenerate && (
          <Button
            title="重新生成"
            variant="btn-dai"
            href={img}
            onClick={(e) => {
              e.stopPropagation()
              e.preventDefault()
              regenerate()
            }}
          >
            <img src="/images/svgicons/generate.svg" alt="" className="icon" />
            重新生成
          </Button>
        )}
        {isDownload && (
          <Button
            title="下載"
            variant="btn-dai"
            href={img}
            download
            onClick={(e) => {
              e.stopPropagation()
            }}
          >
            <FontAwesomeIcon icon={faDownload} />
            下載
          </Button>
        )}
        {target_blank && (
          <Button
            title="另開視窗"
            variant="btn-dai"
            href={img}
            target="_blank"
            onClick={(e) => {
              e.stopPropagation()
            }}
          >
            <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
            另開視窗
          </Button>
        )}

        <Button
          variant="btn-dai "
          title="刪除"
          onClick={(e) => {
            deleteImg()
            e.stopPropagation()
          }}
        >
          <img
            src="/images/svgicons/trash.svg"
            alt=""
            style={{
              filter:
                'invert(100%) sepia(0%) saturate(0%) hue-rotate(0deg) brightness(0%) contrast(100%)',
              marginRight: '5px',
            }}
          />
          刪除
        </Button>
      </div>
      <Button className="btn-text modal-close" onClick={() => handleClose()}>
        <div
          className="svgIcon"
          style={{
            maskImage: `url('/images/svgicons/close.svg')`,
          }}
        >
          close
        </div>
      </Button>
    </Modal>
  )
}

function MediaModal({ setting }) {
  const { mediaShow = false, mediaHandle, updateImg = () => {} } = setting

  return (
    <Modal
      size="fullscreen"
      show={mediaShow}
      onHide={() => mediaHandle(false)}
      className="media-modal"
      centered
    >
      <div className="mediaWrap d-flex flex-wrap">
        <h2 className="modal-title">素材庫</h2>
        <div className="flex-1">
          <div className="mediaHeader">
            <Form.Select aria-label="Default select example">
              <option>全部素材</option>
              <option value="1">素材一</option>
              <option value="2">素材二</option>
              <option value="3">素材三</option>
            </Form.Select>
            <Form.Label htmlFor="uploadfile" className="mb-0">
              <div className="btn btn-primary btn-md">
                <div
                  className="svgIcon"
                  style={{
                    maskImage: `url('/images/svgicons/add.svg')`,
                    transform: 'translateY(1px)',
                    marginRight: '10px',
                  }}
                >
                  icon
                </div>
                上傳素材
              </div>
            </Form.Label>
            <Form.Control
              id="uploadfile"
              name="uploadfile"
              type="file"
              onChange={() => {}}
            />
            <hr className="w-100" />
          </div>
          <div className="mediaList">
            <div className="media-item">
              <img
                src="https://picsum.photos/1920/1080?random=1"
                className="w-100"
                alt=""
              />
              <Button
                onClick={() => {
                  mediaHandle(false)
                  updateImg('https://picsum.photos/1920/1080?random=1')
                }}
              >
                選擇圖片
              </Button>
            </div>
            <div className="media-item">
              <img
                src="https://picsum.photos/1152/1152?random=2"
                className="w-100"
                alt=""
              />
              <Button
                onClick={() => {
                  mediaHandle(false)
                  updateImg('https://picsum.photos/1152/1152?random=2')
                }}
              >
                選擇圖片
              </Button>
            </div>
            <div className="media-item">
              <img
                src="https://picsum.photos/1152/1152?random=3"
                className="w-100"
                alt=""
              />
              <Button
                onClick={() => {
                  mediaHandle(false)
                  updateImg('https://picsum.photos/1920/1080?random=3')
                }}
              >
                選擇圖片
              </Button>
            </div>
          </div>
        </div>
      </div>
      <Button
        className="btn-text modal-close"
        onClick={() => mediaHandle(false)}
      >
        <div
          className="svgIcon"
          style={{
            maskImage: `url('/images/svgicons/close.svg')`,
          }}
        >
          close
        </div>
      </Button>
    </Modal>
  )
}

function Loading() {
  return (
    <div className="img-loading">
      <img src="/images/image-loading.svg" alt="" />
    </div>
  )
}

ImgCard.propTypes = {
  setting: PropTypes.shape().isRequired,
}

ImageModal.propTypes = {
  setting: PropTypes.shape().isRequired,
}

MediaModal.propTypes = {
  setting: PropTypes.shape().isRequired,
}

export default ImgCard
