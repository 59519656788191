/* eslint-disable no-promise-executor-return */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect, useContext } from 'react'
import PropTypes from 'prop-types'
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd'
import { Form, Image, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { DraftContext } from '../../components/ContextProvider'
import CardTool from '../../components/CardTool'
import TootipItem from '../../components/TootipItem'
import AutoHeightText from '../../tools/AutoHeightText'

function MatrixTableColumn({ setting }) {
  const {
    module,
    onDragEnd = () => {},
    addItem = () => {},
    textEdit = () => {},
    deleteCard = () => {},
    priceNote,
    audiencesNote,
    showLoading,
  } = setting

  const dragItemStyle = (isDragging, draggableStyle) => ({
    userSelect: 'none',
    border: isDragging ? 'none' : 'none',
    ...draggableStyle,
  })

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <div className="edit-block">
        <div className="d-flex">
          <div className="table-column-header">
            <div className="table-column-title" style={{ height: '65px' }}>
              品牌
            </div>
            <div className="table-column-title" style={{ height: '65px' }}>
              價格區間
              <OverlayTrigger
                placement="right"
                delay={{ show: 250, hide: 400 }}
                overlay={
                  <Tooltip
                    className="style-dark"
                    style={{
                      zIndex: '9999',
                    }}
                  >
                    <div
                      dangerouslySetInnerHTML={{
                        __html: priceNote,
                      }}
                    />
                  </Tooltip>
                }
              >
                <Image
                  src="/images/svgicons/tooltip.svg"
                  alt=""
                  className="img-fluid mx-2"
                />
              </OverlayTrigger>
            </div>
            <div className="table-column-title" style={{ height: '100px' }}>
              主要客群
              <OverlayTrigger
                placement="right"
                delay={{ show: 250, hide: 400 }}
                overlay={
                  <Tooltip
                    className="style-dark"
                    style={{
                      zIndex: '9999',
                    }}
                  >
                    <div
                      dangerouslySetInnerHTML={{
                        __html: audiencesNote,
                      }}
                    />
                  </Tooltip>
                }
              >
                <Image
                  src="/images/svgicons/tooltip.svg"
                  alt=""
                  className="img-fluid mx-2"
                />
              </OverlayTrigger>
            </div>
          </div>
          <Droppable droppableId="columnDrag" direction="horizontal">
            {(cprovided) => (
              <div className="table-column-wrap" ref={cprovided.innerRef}>
                <div className="table-column-inner">
                  {module.map((item, index) => (
                    <Draggable
                      key={item.id}
                      draggableId={item.id}
                      index={index}
                    >
                      {(dragProvided, dragSnapshot) => (
                        <div
                          className="table-column-2 table-column-info has-drag mx-0"
                          ref={dragProvided.innerRef}
                          {...dragProvided.draggableProps}
                          style={dragItemStyle(
                            dragSnapshot.isDragging,
                            dragProvided.draggableProps.style
                          )}
                          key={`row${item.id}`}
                        >
                          <TootipItem title="拖曳變更排序">
                            <div
                              className="drag-bar"
                              style={{
                                left: '25%',
                                right: '25%',
                              }}
                              {...dragProvided.dragHandleProps}
                            >
                              <div className="module-control-btn">
                                <Image
                                  src="/images/toolicons/drag.svg"
                                  alt=""
                                />
                              </div>
                            </div>
                          </TootipItem>
                          <div className="row-list inner">
                            <Table
                              setting={{
                                content: item,
                                labelID: item.id,
                                deleteCard,
                                cardIndex: index,
                                textEdit,
                                addItem,
                                showLoading,
                              }}
                            />
                          </div>
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {cprovided.placeholder}
                </div>
              </div>
            )}
          </Droppable>
        </div>
      </div>
    </DragDropContext>
  )
}

function Table({ setting }) {
  const {
    labelID,
    content = [],
    addItem = () => {},
    deleteCard = () => {},
    textEdit = () => {},
    showLoading,
  } = setting

  const { showWarn, setShowWarn, showGen, setShowGen } =
    useContext(DraftContext)
  const [brandLoading, setBrandLoading] = useState(showLoading)
  const [itemLoading, setItemLoading] = useState(showLoading)

  const handleAdd = () => {
    setShowGen({
      ...showGen,
      show: true,
      modal: 0,
      func: () => {
        addItem(labelID)
        setItemLoading(true)
        setBrandLoading(true)

        setTimeout(() => {
          setItemLoading(false)
          setBrandLoading(false)
        }, 1000)
      },
      titleLabel: '品牌名稱',
      placeholder: '例: Gogoro',
    })
  }

  const handleRegenerate = () => {
    setItemLoading(true)

    setTimeout(() => {
      setItemLoading(false)
      setBrandLoading(false)
    }, 1000)
  }

  const handleDelete = () => {
    setShowWarn({
      ...showWarn,
      show: true,
      text: '確定移除列表？',
      func: () => {
        deleteCard(labelID)
      },
    })
  }

  useEffect(() => {
    if (itemLoading) {
      setTimeout(() => {
        setItemLoading(false)
      }, 1000)
    }
  }, [itemLoading])

  return (
    <div className="">
      <div className="card-tool pos-left d-block" style={{ top: '55%' }}>
        <CardTool setting={{ handleAdd, handleRegenerate, handleDelete }} />
      </div>
      <div className="row-list inner">
        <div
          data-title="品牌名稱"
          className="table-column-box d-flex align-items-center justify-content-center border-0"
          style={{ height: '65px' }}
        >
          {brandLoading && <Loading />}
          <div className={brandLoading ? 'fade' : ''}>
            <Form.Control
              name="brand_name"
              type="text"
              placeholder="品牌名稱"
              defaultValue={content.brand_name}
              value={content.brand_name}
              className="text-center"
              onChange={(e) => {
                textEdit(labelID, e.target.value, 'brand_name')
              }}
            />
          </div>
          <hr className="colorBar" />
        </div>
        <div
          data-title="價格區間"
          className="table-column-box d-flex align-items-center justify-content-center border-0"
          style={{ height: '65px' }}
        >
          {itemLoading && <Loading setting={{ style: 'one' }} />}
          <div
            className={`d-flex align-items-center ${itemLoading ? 'fade' : ''}`}
          >
            <Form.Control
              name="title"
              type="text"
              placeholder="價格"
              defaultValue={content.price_banding_min}
              className="text-end"
              value={content.price_banding_min}
              onChange={(e) => {
                textEdit(labelID, e.target.value, 'price_banding_min')
              }}
            />
            <span className="px-2">~</span>
            <Form.Control
              name="title"
              type="text"
              placeholder="價格"
              defaultValue={content.price_banding_max}
              value={content.price_banding_max}
              onChange={(e) => {
                textEdit(labelID, e.target.value, 'price_banding_max')
              }}
            />
          </div>
        </div>
        <div
          data-title="主要客群"
          className="table-column-box border-0"
          style={{ height: '100px' }}
        >
          {itemLoading && <Loading setting={{ style: 'one' }} />}
          <div className={`text-justify ${itemLoading ? 'fade' : ''}`}>
            <AutoHeightText
              setting={{
                onChange: (value) => {
                  textEdit(labelID, value, 'audiences')
                },
                value: content.audiences,
                placeholder: '主要客群',
                className: '',
              }}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

function Loading() {
  return (
    <div className="loading-wrap">
      <div className="loading-bar">loading</div>
    </div>
  )
}

Table.propTypes = {
  setting: PropTypes.shape().isRequired,
}

MatrixTableColumn.propTypes = {
  setting: PropTypes.shape().isRequired,
}

export default MatrixTableColumn
