import React, { useState, useEffect, useContext } from 'react'
import PropTypes from 'prop-types'
import { Form } from 'react-bootstrap'
import { DraftContext } from '../components/ContextProvider'
import AutoHeightText from './AutoHeightText'
import CardTool from '../components/CardTool'

function Table({ setting }) {
  const {
    labelID,
    brand_name = '',
    strengths = [],
    weaknesses = [],
    price_banding_min = '',
    price_banding_max = '',
    features = '',
    audiences = '',
    addItem = () => {},
    deleteCard = () => {},
    textEdit = () => {},
    arrayEdit = () => {},
    showLoading,
    newColumn,
    isNewColumn = () => {},
    index,
    noteText,
    noteContent = [],
  } = setting

  const { showWarn, setShowWarn, showGen, setShowGen } =
    useContext(DraftContext)

  const [brandLoading, setBrandLoading] = useState(showLoading)
  const [itemLoading, setItemLoading] = useState(showLoading)

  useEffect(() => {
    if (showLoading) {
      setBrandLoading(true)
      setItemLoading(true)

      setTimeout(() => {
        setBrandLoading(false)
        setItemLoading(false)
      }, 1000)
    }
  }, [showLoading])

  const handleAdd = () => {
    setShowGen({
      ...showGen,
      show: true,
      noteText,
      noteContent,
      modal: 0,
      func: () => {
        isNewColumn(index + 1)
        addItem(labelID)
      },
      titleLabel: '品牌名稱',
      placeholder: '例: Gogoro',
    })
  }

  const handleRegenerate = () => {
    setItemLoading(true)
    setTimeout(() => {
      setItemLoading(false)
    }, 1000)
  }

  const handleDelete = () => {
    setShowWarn({
      ...showWarn,
      show: true,
      text: '確定移除列表？',
      func: () => {
        isNewColumn(-1)
        deleteCard(labelID)
      },
    })
  }

  return (
    <div className="column-item table-column">
      <div className="card-tool pos-left intable">
        <CardTool
          setting={{
            handleAdd,
            handleRegenerate,
            handleDelete,
          }}
        />
      </div>
      <div className="row-list inner">
        <div
          data-title="品牌名稱"
          className="row-column data-title first-col column-title"
        >
          {brandLoading && <Loading setting={{ style: 'one' }} />}
          <div className={`fade ${brandLoading ? '' : 'show'}`}>
            <Form.Control
              name="brand_name"
              type="text"
              placeholder="品牌名稱"
              defaultValue={brand_name}
              className="text-center"
              onChange={(e) => {
                textEdit(labelID, e.target.value, 'brand_name')
              }}
            />
          </div>
        </div>
        <div className="row-column p-0">
          {strengths.map((a, i) => (
            <div className="d-flex flex-wrap column-item " key={`advance${i}`}>
              <div
                tabIndex={0}
                role="button"
                data-title="優勢"
                className="row-column data-title width-3x border-bottom"
              >
                {(itemLoading || newColumn === index) && (
                  <Loading setting={{ style: 'muti' }} />
                )}
                <div
                  className={`text-justify fade ${
                    itemLoading || newColumn === index ? '' : 'show'
                  }`}
                >
                  <AutoHeightText
                    setting={{
                      onChange: (value) => {
                        arrayEdit(labelID, value, 'strengths', i)
                      },
                      value: a,
                      placeholder: '優勢',
                    }}
                  />
                </div>
              </div>
              <div
                tabIndex={0}
                role="button"
                data-title="劣勢"
                className="row-column data-title width-3x border-0 border-bottom"
              >
                {(itemLoading || newColumn === index) && (
                  <Loading setting={{ style: 'muti' }} />
                )}
                <div
                  className={`fade ${
                    itemLoading || newColumn === index ? '' : 'show'
                  }`}
                >
                  <div className="text-justify">
                    <AutoHeightText
                      setting={{
                        onChange: (value) => {
                          arrayEdit(labelID, value, 'weaknesses', i)
                        },
                        value: weaknesses[i],
                        placeholder: '劣勢',
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div
          data-title="價格區間"
          className="row-column data-title column-price"
        >
          {(itemLoading || newColumn === index) && (
            <Loading setting={{ style: 'one' }} />
          )}
          {itemLoading || newColumn === index ? (
            <div>&nbsp;</div>
          ) : (
            <div className="d-flex align-items-center">
              <Form.Control
                name="price_banding_min"
                type="text"
                placeholder="價格"
                defaultValue={`${price_banding_min}`}
                onChange={(e) => {
                  textEdit(labelID, e.target.value, 'price_banding_min')
                }}
                className="text-end"
              />
              <span className="px-2">~</span>
              <Form.Control
                name="price_banding_max"
                type="text"
                placeholder="價格"
                defaultValue={`${price_banding_max}`}
                onChange={(e) => {
                  textEdit(labelID, e.target.value, 'price_banding_max')
                }}
              />
            </div>
          )}
        </div>
        <div data-title="產品特點" className="row-column data-title width-16">
          {(itemLoading || newColumn === index) && (
            <Loading setting={{ style: 'one' }} />
          )}
          {itemLoading || newColumn === index ? (
            <div>&nbsp;</div>
          ) : (
            <div className="text-justify">
              <AutoHeightText
                setting={{
                  onChange: (value) => {
                    textEdit(labelID, value, 'features')
                  },
                  value: features,
                  placeholder: '產品特點',
                }}
              />
            </div>
          )}
        </div>
        <div
          data-title="主要客群"
          className="row-column data-title width-16 border-0"
        >
          {(itemLoading || newColumn === index) && (
            <Loading setting={{ style: 'one' }} />
          )}
          {itemLoading || newColumn === index ? (
            <div>&nbsp;</div>
          ) : (
            <div className="text-justify">
              <AutoHeightText
                setting={{
                  onChange: (value) => {
                    textEdit(labelID, value, 'audiences')
                  },
                  value: audiences,
                  placeholder: '主要客群',
                }}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

function Loading({ setting }) {
  const { style } = setting

  if (style === 'one') {
    return (
      <div
        className="loading-wrap"
        style={{
          position: 'static',
        }}
      >
        <div className="loading-bar">loading</div>
      </div>
    )
  }

  return (
    <div className="loading-wrap">
      <div className="loading-bar mb-2">loading</div>
      <div className="loading-bar mb-4">loading</div>
      <div className="loading-bar mb-2">loading</div>
      <div className="loading-bar">loading</div>
    </div>
  )
}

Loading.propTypes = {
  setting: PropTypes.shape().isRequired,
}

Table.propTypes = {
  setting: PropTypes.shape().isRequired,
}

export default Table
