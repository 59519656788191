import React from 'react'
import PropTypes from 'prop-types'

function Footer({ showRemark = false, hasToolbar = false }) {
  return (
    <div id="footer" className={`${hasToolbar ? 'hasToolbar' : ''}`}>
      {showRemark && (
        <p className="remark-text text-center">
          AI生成的建議僅供參考，請根據實際需求核對資訊後再行決策。
        </p>
      )}
      <p className="text-center">
        Copyright &copy; 2024 TDRI. all rights reserved.
      </p>
    </div>
  )
}

Footer.propTypes = {
  showRemark: PropTypes.bool,
  hasToolbar: PropTypes.bool,
}

export default Footer
