import React from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import PropTypes from 'prop-types'

function TootipItem({ children, title }) {
  return (
    <OverlayTrigger
      overlay={<Tooltip bsPrefix="toolbar-tooltip">{title}</Tooltip>}
    >
      {children}
    </OverlayTrigger>
  )
}

TootipItem.propTypes = {
  title: PropTypes.string,
  children: PropTypes.shape().isRequired,
}

export default TootipItem
