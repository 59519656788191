import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Form, Button } from 'react-bootstrap'
import TootipItem from './TootipItem'

function EditText({ setting }) {
  const {
    title,
    style = 'back',
    handleInputChange,
    bgColor = '#0E1216',
    defauleTitle = '',
  } = setting
  const [showTitleText, setShowTitleText] = useState(false)

  return (
    <div className="edit-text">
      {showTitleText ? (
        <>
          {style === 'front' && (
            <TootipItem title="編輯">
              <Button
                className="btn-text"
                onClick={() => {
                  setShowTitleText(!showTitleText)
                }}
              >
                <EditIcon
                  setting={{
                    bgColor,
                  }}
                />
              </Button>
            </TootipItem>
          )}
          <Form.Control
            name="title"
            type="text"
            onChange={(e) => {
              handleInputChange(e.target.value)
            }}
            onBlur={() => {
              setShowTitleText(!showTitleText)
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                setShowTitleText(!showTitleText)
              }
            }}
            style={{ boxShadow: '0 0 0 0.25rem rgba(63, 117, 237, 0.25)' }}
            defaultValue={title}
          />
          {style === 'back' && (
            <TootipItem title="編輯">
              <Button
                className="btn-text"
                onClick={() => {
                  setShowTitleText(!showTitleText)
                }}
              >
                <EditIcon
                  setting={{
                    bgColor,
                  }}
                />
              </Button>
            </TootipItem>
          )}
        </>
      ) : (
        <Button
          className="btn-text w-100"
          onClick={() => {
            setShowTitleText(!showTitleText)
          }}
        >
          {style === 'front' && (
            <EditIcon
              setting={{
                bgColor,
              }}
            />
          )}
          <div className="text-title">
            {defauleTitle === '' ? title : defauleTitle}
          </div>
          {style === 'back' && <EditIcon setting={{ bgColor }} />}
        </Button>
      )}
    </div>
  )
}

function EditIcon({ setting }) {
  const { bgColor } = setting

  return (
    <div
      className="svgIcon"
      style={{
        maskImage: `url('/images/svgicons/edit.svg')`,
        backgroundColor: bgColor,
      }}
    >
      edit
    </div>
  )
}

EditIcon.propTypes = {
  setting: PropTypes.shape().isRequired,
}

EditText.propTypes = {
  setting: PropTypes.shape().isRequired,
}

export default EditText
