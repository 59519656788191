import React, { useState, useEffect, useContext } from 'react'
import { EditWrapper } from '../../components'
import { DraftContext } from '../../components/ContextProvider'
import Subnav from './Subnav'
import MatrixTable from './MatrixTable'
import MatrixTableColumn from './MatrixTableColumn'
import XYDrag from './XYDrag'
import { activeInput } from '../../lib/utility'
import useCompetitiveStore from '../../store/useCompetitiveStore'
import {
  addItem,
  deleteCard,
  textEdit,
  initCompetitive,
} from '../../components/CompetitiveUtils'

function Product() {
  const { draft } = useContext(DraftContext)
  const [module] = useState(draft.draft_id && draft ? draft : {})
  // const [tab] = useState(0)
  const [records, setRecords] = useState([])
  const [boardStyle, setBoardStyle] = useState('column-row')
  const boards = [
    {
      id: 1,
      title: '直式表格矩陣',
      image: '/images/boards/matrix-row.svg',
      key: 'column-row',
    },
    {
      id: 2,
      title: '橫列表格矩陣',
      image: '/images/boards/matrix-column.svg',
      key: 'column',
    },
  ]

  const { competitive, setField, price_symbol } = useCompetitiveStore(
    (state) => ({
      competitive: state.competitive,
      setField: state.setField,
      price_symbol: state.price_symbol,
    })
  )

  const switchBoard = (board) => {
    setBoardStyle(board)
  }

  // 設定版本紀錄
  useEffect(() => {
    if (module.module2) {
      setRecords(
        module.module2.product.history && module.module2.product.history
      )
    }
  }, [module])

  const reorder = (cardlist, startIndex, endIndex) => {
    const result = Array.from(cardlist)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)

    return result
  }

  const onDragEnd = (result) => {
    const { source, destination } = result

    if (!destination) {
      return
    }

    switch (source.droppableId) {
      case destination.droppableId:
        // 拖拉排序
        setField(
          'competitive',
          reorder(competitive, source.index, result.destination.index)
        )

        break
      default:
        break
    }
  }

  const chartDotChange = (index, xPos, toY) => {
    const temp = JSON.parse(JSON.stringify(competitive))
    temp[index].price_banding_max = xPos
    temp[index].price_banding_min = xPos
    temp[index].target_size = toY
    setField('competitive', temp)
  }

  // loading
  const [showLoading] = useState(false)

  useEffect(() => {
    activeInput()
    if (competitive.length === 0) {
      if (competitive.length === 0) {
        if (module.module2) {
          initCompetitive(setField, module.module2.json_url)
        }
      }
    }
  }, [])

  const priceNote =
    '<p>品牌產品的價格範圍，平均值會反映在右側矩陣中。您可編輯價格以調整圖表上的品牌位置。</p>'
  const audiencesNote =
    '<p>品牌的核心使用者群體，幫助您了解不同品牌的市場定位。</p>'

  return (
    module.module2 && (
      <EditWrapper
        setting={{
          showInput: module.show_input,
          toolbarTitle: '生成競品分析',
          toolbarTitleDesc:
            '生成競品分析有兩個關鍵要素：<strong>「市場」</strong>和<strong>「產業/服務」</strong>。',
          moduleTitle: module.title,
          moduleKeyword: '分析台灣市場的自行車產業',
          records,
          boards,
          boardStyle,
          switchBoard,
        }}
      >
        <>
          <Subnav setting={{ currentID: 2 }} />
          <div className="tab-panel">
            <div className="edit-block">
              <div className="row">
                <div
                  className={boardStyle === 'column-row' ? 'column-table' : ''}
                  style={{
                    paddingBottom: boardStyle === 'column-row' ? '0px' : '30px',
                  }}
                >
                  <p
                    className="remark-text"
                    style={{
                      width: boardStyle === 'column-row' ? '80%' : '100%',
                    }}
                  >
                    擷取競品分析表中的價格和主要客群資訊，幫助您快速分析各品牌的產品市場定位。
                  </p>
                  {boardStyle === 'column-row' ? (
                    <MatrixTable
                      setting={{
                        module: competitive,
                        onDragEnd,
                        addItem: (labelID) =>
                          addItem(labelID, competitive, setField),

                        deleteCard: (labelID) =>
                          deleteCard(labelID, competitive, setField),
                        textEdit: (id, value, label) =>
                          textEdit(id, value, label, competitive, setField),
                        priceType: price_symbol,
                        setPriceType: (value) => {
                          setField('price_symbol', value)
                        },
                        priceNote,
                        audiencesNote,
                        showLoading,
                      }}
                    />
                  ) : (
                    <MatrixTableColumn
                      setting={{
                        module: competitive,
                        onDragEnd,
                        addItem: (labelID) =>
                          addItem(labelID, competitive, setField),

                        deleteCard: (labelID) =>
                          deleteCard(labelID, competitive, setField),
                        textEdit: (id, value, label) =>
                          textEdit(id, value, label, competitive, setField),
                        priceNote,
                        audiencesNote,
                        showLoading,
                      }}
                    />
                  )}
                </div>
                <div className={`column-matrix ${boardStyle}`}>
                  <div className="matrix-note">
                    <p>
                      概覽品牌市場定位：X軸顯示平均價格，Y軸表示客群規模，幫您快速辨識高端或大眾市場品牌，利於後續競品分析。
                    </p>
                    <p className="otherText">
                      * 您可以拖曳品牌圓圈，將品牌調整至理想的市場位置。
                    </p>
                  </div>
                  <div className="suggest-content">
                    <div className="suggest-content-title">
                      <span>客群大小</span>
                    </div>
                    <XYDrag
                      setting={{
                        module: competitive,
                        priceType: price_symbol,
                        chartDotChange,
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      </EditWrapper>
    )
  )
}

export default Product
