/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect, useContext } from 'react'
import PropTypes from 'prop-types'
import { DraftContext } from '../../components/ContextProvider'
import Label from '../../tools/Label'
import TextArea from '../../tools/TextArea'
import SectionTitle from '../../tools/SectionTitle'
import CardTool from '../../components/CardTool'

function DesignCard({ setting }) {
  const {
    label,
    content = {},
    labelEdit = () => {},
    handleAdd = () => {},
    handleDelete = () => {},
    editInit = () => {},
    editHeight = () => {},
    count,
    showLoading,
  } = setting
  const { showWarn, setShowWarn } = useContext(DraftContext)
  const [isLoading, setIsLoading] = useState(showLoading)

  useEffect(() => {
    if (isLoading) {
      setTimeout(() => {
        setIsLoading(false)
      }, 1000)
    }
  }, [])

  const handleRegenerate = () => {
    setIsLoading(true)
    setTimeout(() => {
      setIsLoading(false)
    }, 1000)
  }

  const checkDelete = () => {
    if (count > 1) {
      setShowWarn({
        ...showWarn,
        show: true,
        text: '確定刪除卡片？',
        func: () => {
          handleDelete(label)
        },
      })
    }

    if (count === 1) {
      setShowWarn({
        ...showWarn,
        show: true,
        text: '最少一筆',
        func: () => {},
      })
    }
  }

  const textEdit = (value, itemIndex) => {
    console.log(value, itemIndex)
  }

  return (
    <div className="col has-drag">
      <div className="color-card position-relative flex-column d-flex">
        <div
          className="card-tool"
          style={{ right: '5%', top: '5%', left: 'auto' }}
        >
          <CardTool
            setting={{
              handleRegenerate,
              handleAdd,
              handleDelete: checkDelete,
              onlyRegenerate: false,
              renGenText: '全部重新生成',
            }}
          />
        </div>
        <div className="opportunities">
          <SectionTitle
            setting={{
              title: '設計機會',
              icon: '',
              edit: false,
            }}
          />
          <CardItem
            setting={{
              label,
              headLine: content.opportunities_headline,
              text: content.opportunities,
              labelEdit,
              textEdit,
              itemIndex: 0,
              editInit,
              editHeight,
              showLoading: isLoading,
            }}
          />
        </div>
        <div>
          <SectionTitle
            setting={{
              title: '設計挑戰',
              icon: '',
              edit: false,
            }}
          />
          <CardItem
            setting={{
              label,
              headLine: content.challenges_headline,
              text: content.challenges,
              labelEdit,
              textEdit,
              itemIndex: 1,
              showLoading: isLoading,
            }}
          />
        </div>
      </div>
    </div>
  )
}

function CardItem({ setting }) {
  const {
    label,
    headLine,
    itemIndex,
    labelEdit = () => {},
    textEdit = () => {},
    text,
    editInit = () => {},
    editHeight = () => {},
    showLoading,
  } = setting

  return (
    <div className="inner d-flex flex-1 flex-column">
      <div className="card-label shadow-block">
        {showLoading && <Loading setting={{ style: 'one' }} />}
        <div className={`fade ${showLoading ? '' : 'show'}`}>
          <Label
            setting={{
              label,
              title: headLine,
              itemIndex,
              labelEdit,
              edit: true,
            }}
          />
        </div>
      </div>
      <div className="card-item shadow-block p-0">
        <TextArea
          setting={{
            label,
            value: text,
            itemIndex,
            textEdit,
            editInit,
            editHeight,
            showLoading,
          }}
        />
      </div>
    </div>
  )
}

function Loading({ setting }) {
  const { style } = setting

  if (style === 'one') {
    return (
      <div className="loading-wrap">
        <div className="loading-bar">loading</div>
      </div>
    )
  }

  return (
    <div className="loading-wrap">
      <div className="loading-bar mb-2">loading</div>
      <div className="loading-bar mb-4">loading</div>
      <div className="loading-bar mb-2">loading</div>
      <div className="loading-bar mb-4">loading</div>
      <div className="loading-bar mb-2">loading</div>
      <div className="loading-bar">loading</div>
    </div>
  )
}

Loading.propTypes = {
  setting: PropTypes.shape().isRequired,
}

CardItem.propTypes = {
  setting: PropTypes.shape().isRequired,
}

DesignCard.propTypes = {
  setting: PropTypes.shape().isRequired,
}

export default DesignCard
