/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { Overlay, Tooltip } from 'react-bootstrap'

function ExampleTooltip({ setting }) {
  const {
    text = '例如',
    title,
    content = [],
    option = 1,
    showOther = '',
  } = setting
  const [showExample, setShowExample] = useState(false)
  const target = useRef(null)

  useEffect(() => {
    setShowExample(false)
  }, [option])

  return (
    <>
      <button
        type="button"
        className="note-span"
        ref={target}
        onClick={() => {
          setShowExample(!showExample)
        }}
      >
        {text}
      </button>
      <Overlay target={target.current} show={showExample} placement="bottom">
        {(props) => (
          <Tooltip className="overlay-example" {...props}>
            {title && <p>{title}</p>}
            <ul>
              {content.map((contentText, index) => (
                <li
                  key={index}
                  dangerouslySetInnerHTML={{ __html: contentText }}
                />
              ))}
            </ul>
            {showOther && <p className="otherText">{showOther}</p>}
            <button
              type="button"
              className="example-close"
              onClick={() => {
                setShowExample(false)
              }}
            >
              <span
                className="svgIcon bg-white d-block"
                style={{
                  maskImage: `url('/images/svgicons/close.svg')`,
                }}
              >
                close
              </span>
            </button>
          </Tooltip>
        )}
      </Overlay>
    </>
  )
}

ExampleTooltip.propTypes = {
  setting: PropTypes.shape().isRequired,
}

export default ExampleTooltip
