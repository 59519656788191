/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect, useContext } from 'react'
import PropTypes from 'prop-types'
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd'
import { Image } from 'react-bootstrap'
import { DraftContext } from '../../components/ContextProvider'
import { Label, TextArea } from '../../tools'
import TootipItem from '../../components/TootipItem'
import CardTool from '../../components/CardTool'

function DesignCardColumn({ setting }) {
  const {
    content,
    onDragEnd = () => {},
    handleAdd = () => {},
    handleDelete = () => {},
    count,
    showLoading,
  } = setting

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <div className="d-flex flex-wrap">
        <div className="design-card-column">
          <div className="box-title">設計機會</div>
        </div>
        <div className="design-card-column">
          <div className="box-title">設計挑戰</div>
        </div>
      </div>
      <Droppable droppableId="card-drop-2">
        {(dropProvided) => (
          <div ref={dropProvided.innerRef} className="card-item-list">
            {content.map(
              (
                {
                  opportunities_headline,
                  opportunities,
                  challenges_headline,
                  challenges,
                },
                index
              ) => (
                <Draggable
                  key={`item-${index}`}
                  draggableId={`column-${index}`}
                  index={index}
                >
                  {(dragProvided) => (
                    <div
                      ref={dragProvided.innerRef}
                      // eslint-disable-next-line
                      {...dragProvided.draggableProps}
                      className="design-drap-row has-drag"
                    >
                      <div className="design-card-row position-relative">
                        <TootipItem title="拖曳變更排序">
                          <div
                            className="drag-bar"
                            {...dragProvided.dragHandleProps}
                          >
                            <div className="module-control-btn">
                              <Image src="/images/toolicons/drag.svg" alt="" />
                            </div>
                          </div>
                        </TootipItem>
                        <CardItem
                          setting={{
                            opportunities_headline,
                            challenges_headline,
                            opportunities,
                            challenges,
                            showLoading,
                            handleAdd,
                            handleDelete,
                            count,
                            index,
                          }}
                        />
                      </div>
                    </div>
                  )}
                </Draggable>
              )
            )}
            {dropProvided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  )
}

function CardItem({ setting }) {
  const {
    opportunities_headline,
    challenges_headline,
    opportunities,
    challenges,
    showLoading,
    handleAdd = () => {},
    handleDelete = () => {},
    count,
    index,
  } = setting
  const { showWarn, setShowWarn } = useContext(DraftContext)
  const [isLoading, setIsLoading] = useState(showLoading)

  useEffect(() => {
    if (isLoading) {
      setTimeout(() => {
        setIsLoading(false)
      }, 1000)
    }
  }, [])

  const handleRegenerate = () => {
    setIsLoading(true)
    setTimeout(() => {
      setIsLoading(false)
    }, 1000)
  }

  return (
    <>
      <div
        className="card-tool"
        style={{ top: '5%', left: '0', right: 'auto' }}
      >
        <CardTool
          setting={{
            handleRegenerate,
            onlyRegenerate: false,
            handleAdd,
            handleDelete: () => {
              if (count > 1) {
                setShowWarn({
                  ...showWarn,
                  show: true,
                  text: '確定刪除卡片？',
                  func: () => {
                    handleDelete(index)
                  },
                })
              }

              if (count === 1) {
                setShowWarn({
                  ...showWarn,
                  show: true,
                  text: '最少一筆',
                  func: () => {},
                })
              }
            },
            renGenText: '全部重新生成',
          }}
        />
      </div>
      <div className="design-card-column">
        <div className="inner">
          <div className="position-relative">
            {isLoading && <Loading setting={{ style: 'one' }} />}
            <div className={`fade ${isLoading ? '' : 'show'}`}>
              <Label
                setting={{
                  title: opportunities_headline,
                  edit: true,
                }}
              />
            </div>
          </div>
          <TextArea
            setting={{
              value: opportunities,
              showLoading: isLoading,
            }}
          />
        </div>
      </div>
      <div className="design-card-column">
        <div className="inner">
          <div className="position-relative">
            {isLoading && <Loading setting={{ style: 'one' }} />}
            <div className={`fade ${isLoading ? '' : 'show'}`}>
              <Label
                setting={{
                  title: challenges_headline,
                  edit: true,
                }}
              />
            </div>
          </div>
          <TextArea
            setting={{
              value: challenges,
              showLoading: isLoading,
            }}
          />
        </div>
      </div>
    </>
  )
}

function Loading({ setting }) {
  const { style } = setting

  if (style === 'one') {
    return (
      <div className="loading-wrap">
        <div className="loading-bar">loading</div>
      </div>
    )
  }

  return (
    <div className="loading-wrap">
      <div className="loading-bar mb-2">loading</div>
      <div className="loading-bar mb-4">loading</div>
      <div className="loading-bar mb-2">loading</div>
      <div className="loading-bar mb-4">loading</div>
      <div className="loading-bar mb-2">loading</div>
      <div className="loading-bar">loading</div>
    </div>
  )
}

Loading.propTypes = {
  setting: PropTypes.shape().isRequired,
}

CardItem.propTypes = {
  setting: PropTypes.shape().isRequired,
}

DesignCardColumn.propTypes = {
  setting: PropTypes.shape().isRequired,
}

export default DesignCardColumn
