/* eslint-disable no-param-reassign */
import React, { useState, useEffect, useContext, useRef } from 'react'
import PropTypes from 'prop-types'
import { useLocation, useNavigate } from 'react-router-dom'
import { Button, Image } from 'react-bootstrap'
import { DraftContext } from './ContextProvider'
import SpinnerLoading from './SpinnerLoading'

function SideBar({ setting }) {
  const { setDraftId, showWarn, setShowWarn, showGen, setShowGen } =
    useContext(DraftContext)
  const { toggleNav, toggleMobileNav = () => {} } = setting
  const navigate = useNavigate()

  const [sidebarNav] = useState([
    {
      id: 1,
      name: '市場研究',
      placeholder: '我想分析 [市場] 的 [產業/服務]',
      list: [
        {
          id: 1,
          icon: 'nav-icon01.svg',
          title: 'PESTEL',
          href: '/module1',
          placeholder: '電動車/美國/上班族美國/上班族 ...',
          parent: 1,
        },
        {
          id: 2,
          icon: 'nav-icon02.svg',
          title: '競品分析',
          placeholder: '分析在 OO 市場的 OO 產業/服務',
          href: '/module2/product',
          parent: 1,
        },
      ],
    },
    {
      id: 2,
      name: '使用者研究',
      placeholder:
        '經常 [描述行為特徵] 的 [角色/職業]，想要 [達成什麼目標/任務]',
      list: [
        {
          id: 3,
          icon: 'nav-icon03.svg',
          title: '顧客旅程',
          href: '/module3',
          placeholder: '分析在 OO 市場的 OO 產業/服務',
          parent: 2,
        },
        {
          id: 4,
          icon: 'nav-icon04.svg',
          title: '人物誌',
          href: '/module4',
          placeholder: '穿著和外型為 OO 的 OO 角色',
          parent: 2,
        },
      ],
    },
  ])

  // 側邊欄拖曳功能
  let startX
  let startW

  // 設定最大小寬度
  const minWidth = 80
  const maxWidth = 390
  const dragBar = useRef(null)

  const onDrag = (e) => {
    let newWidth = startW + e.clientX - startX
    if (newWidth <= minWidth) {
      newWidth = minWidth
    }

    if (newWidth >= maxWidth) {
      newWidth = maxWidth
    }

    document.getElementById('sidebar').style.width = `${newWidth}px`
    document.getElementById('moduleContent').style.marginLeft = `${newWidth}px`
    document.getElementById('editToolbar').style.left = `${newWidth}px`

    const isFirefox = navigator.userAgent.toLowerCase().includes('firefox')
    if (document.getElementsByClassName('edit-components')[0]) {
      if (!isFirefox) {
        document.getElementsByClassName(
          'edit-components'
        )[0].style.left = `${newWidth}px`
      }
    }

    if (document.getElementsByClassName('component-detail')[0]) {
      if (!isFirefox) {
        document.getElementsByClassName('component-detail')[0].style.left = `${
          newWidth + 60
        }px`
      }
    }

    document.querySelectorAll('.nav-title').forEach((navTitle) => {
      navTitle.style.display = newWidth <= 265 ? 'none' : 'flex'
    })

    document.querySelectorAll('.return-btn').forEach((returnBtn) => {
      returnBtn.getElementsByClassName('svgIcon')[0].style.marginRight =
        newWidth <= 230 ? '0' : '20px'
    })

    document.querySelectorAll('.sidebar-text').forEach((sidebarText) => {
      sidebarText.style.display = newWidth <= 230 ? 'none' : 'inline-block'
    })
  }

  const stopDrag = () => {
    document.documentElement.removeEventListener('mousemove', onDrag)
    document.documentElement.removeEventListener('mouseup', stopDrag)
  }

  const startDrag = (e) => {
    startX = e.clientX
    startW = parseInt(
      window.getComputedStyle(document.getElementById('sidebar')).width,
      10
    )
    document.documentElement.addEventListener('mousemove', onDrag)
    document.documentElement.addEventListener('mouseup', stopDrag)
  }

  useEffect(() => {
    dragBar.current.addEventListener('mousedown', startDrag)
  }, [])

  // 局部重新生成
  const [reg, setReg] = useState(0)

  return (
    <div id="sidebar" className="sidebar">
      <Button
        className={`navbar-toggler ${toggleNav ? 'open' : ''}`}
        aria-controls="mainmenu"
        aria-expanded="false"
        aria-label="Toggle navigation"
        onClick={() => {
          toggleMobileNav(!toggleNav)
        }}
      >
        <span className="nav-bar"> </span>
        <span className="nav-bar"> </span>
        <span className="nav-bar"> </span>
      </Button>
      <div className={`main-scroll scroll-sidebar ${toggleNav ? 'open' : ''}`}>
        <div className="sidebar-wrap">
          <div className="sidebar-header">
            <Button
              className="sidebar-mainmenu btn-text return-btn"
              onClick={() => {
                setShowWarn({
                  ...showWarn,
                  show: true,
                  text: '如果離開此頁面，會遺失尚未儲存的變更。',
                  yes: '儲存檔案',
                  no: '繼續編輯',
                  func: () => {
                    setDraftId(0)
                    navigate('/list')
                  },
                })
              }}
            >
              <span
                className="svgIcon"
                style={{
                  maskImage: `url('/images/svgicons/return.svg')`,
                }}
              >
                return
              </span>
              <span className="sidebar-text">返回專案列表</span>
            </Button>
          </div>
          <div className="sidebar-nav">
            <ul>
              {sidebarNav.map((mainnav, index) => (
                <li key={`mainnav${index}`}>
                  <div className="nav-title">
                    <span className="text">{mainnav.name}</span>
                    <button
                      type="button"
                      className="btn-regen-outline"
                      onClick={() => {
                        setShowGen({
                          ...showGen,
                          show: true,
                          isToolBarBtn: true,
                          modal: mainnav.id,
                          func: () => {
                            setReg(mainnav.id)
                            setTimeout(() => {
                              setReg(0)
                            }, 1000)
                          },
                          placeholder: mainnav.placeholder,
                        })
                      }}
                    >
                      生成研究範圍
                      <span
                        className="svgIcon"
                        style={{
                          maskImage: `url('/images/svgicons/generate.svg')`,
                        }}
                      >
                        重新生成
                      </span>
                    </button>
                  </div>
                  <ul className="mainnav-ul">
                    {mainnav.list.map((nav, i) => (
                      <NavButton
                        setting={{
                          nav,
                          regen: reg === nav.parent,
                        }}
                        key={`nav-${i}`}
                      />
                    ))}
                  </ul>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className="sidedrag-bar" ref={dragBar}>
          &nbsp;
        </div>
      </div>
    </div>
  )
}

function NavButton({ setting }) {
  const { nav, regen } = setting
  const { draft, currentID, setCurrentID } = useContext(DraftContext)
  const location = useLocation()
  const navigate = useNavigate()
  const [openSubmenu, setOpenSubmenu] = useState(currentID === nav.id)
  const [showLoading, setShowLoading] = useState(false)

  useEffect(() => {
    if (draft.title) {
      setShowLoading(true)

      setTimeout(() => {
        setShowLoading(false)
      }, 1000)
    } else {
      setShowLoading(false)
    }
  }, [])

  useEffect(() => {
    if (regen) {
      setShowLoading(true)
      setTimeout(() => {
        setShowLoading(false)
      }, 1000)
    }
  }, [regen])

  return (
    <li className={`sidebar-li ${openSubmenu ? 'active' : ''}`}>
      <Button
        className={`sidebar-mainmenu ${nav.sub ? 'hassub' : ''}`}
        active={location.pathname === nav.href}
        onClick={() => {
          if (nav.sub) {
            setOpenSubmenu(!openSubmenu)
          } else {
            setCurrentID(nav.id)
            navigate(nav.href)
          }
        }}
      >
        <Image src={`/images/svgicons/${nav.icon}`} alt="" />
        <span className="sidebar-text">{nav.title}</span>
        <div className={`spinner fade ${showLoading ? 'show' : ''}`}>
          <SpinnerLoading />
        </div>
      </Button>
      {nav.sub && (
        <ul className={`sidebar-subnav ${openSubmenu ? '' : 'd-none'}`}>
          {nav.sub.map((subnav, subIndex) => (
            <li key={`subnav-${subIndex}`}>
              <Button
                className="sidebar-submenu"
                active={location.pathname === subnav.href}
                onClick={() => {
                  setCurrentID(nav.id)
                  navigate(subnav.href)
                }}
              >
                {subnav.title}
              </Button>
            </li>
          ))}
        </ul>
      )}
    </li>
  )
}

NavButton.propTypes = {
  setting: PropTypes.shape().isRequired,
}

SideBar.propTypes = {
  setting: PropTypes.shape().isRequired,
}

export default SideBar
