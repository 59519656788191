/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/forbid-prop-types */
import React from 'react'
import PropTypes from 'prop-types'
import { Image } from 'react-bootstrap'
import TootipItem from './TootipItem'

function DragBar({ dragProvided }) {
  return (
    <div {...dragProvided.dragHandleProps} className="drag-bar">
      <TootipItem title="拖曳變更排序">
        <div className="d-flex h-100 align-items-center">
          <div className="module-control-btn">
            <Image src="/images/toolicons/drag.svg" alt="" />
          </div>
        </div>
      </TootipItem>
    </div>
  )
}

DragBar.propTypes = {
  dragProvided: PropTypes.object,
}

export default DragBar
