import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { Image, Form, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faQuestion } from '@fortawesome/free-solid-svg-icons'
import { Label, ImgCard, TextArea, BarChat, AutoHeightText } from '../tools'
import CardTool from './CardTool'

function SuggestPersona({ setting }) {
  const {
    user_info,
    emotions = [],
    opportunities_and_painpoints = [],
    journeys,
    boardStyle,
  } = setting

  const bars = emotions.map((item) => item.score)

  const painpoints = opportunities_and_painpoints.map((item) => ({
    metaKey: 'painpoint',
    content: item.painpoint,
    topic: item.painpoint_topic,
  }))

  const opportunities = opportunities_and_painpoints.map((item) => ({
    metaKey: 'opportunity',
    content: item.opportunity,
    topic: item.opportunity_topic,
  }))

  const points = [...painpoints, ...opportunities]
  // svg size
  const ref = useRef(null)
  const [svgSize, setsvgSize] = useState({
    width: 1000,
    height: 300,
  })

  const getSize = () => {
    if (ref.current) {
      const height = 380
      const width = ref.current.clientWidth
      return { width, height }
    }
    return false
  }

  useEffect(() => {
    const observer = new ResizeObserver(() => {
      const size = getSize()
      if (size.width !== svgSize.width || size.height !== svgSize.height)
        setsvgSize(size)
    })
    observer.observe(ref.current)
    return () => ref.current && observer.unobserve(ref.current)
  }, [])

  // loading
  const [showLoading, setShowLoading] = useState(true)
  useEffect(() => {
    if (showLoading) {
      setTimeout(() => {
        setShowLoading(false)
      }, 1000)
    }
  }, [])

  const [blockLeft, setBlockLeft] = useState(0)
  const [blockOpacity, setBlockOpacity] = useState(0)
  const updatePos = (newPos) => {
    setBlockLeft(newPos)
    setBlockOpacity(1)
  }

  return (
    <div
      ref={ref}
      className={`stage-section ${
        boardStyle === 'horizon' ? 'horizon' : 'vertical'
      }`}
    >
      <div className="info-header">
        <div className="info-header-img position-relative">
          {user_info.image && <ImgCard setting={{ img: user_info?.image }} />}
        </div>
        <div className="info-header-spec position-relative">
          <ul className="spec">
            {user_info.name && (
              <li>
                <span className="label-text">姓名</span>
                <div className="col position-relative">
                  {showLoading && <Loading setting={{ style: 'one' }} />}
                  <Form.Control
                    name="name"
                    type="text"
                    placeholder="姓名"
                    defaultValue={user_info.name}
                    onChange={() => {}}
                  />
                </div>
              </li>
            )}
            {user_info.age && (
              <li>
                <span className="label-text">年齡</span>
                <div className="col position-relative">
                  {showLoading && <Loading setting={{ style: 'one' }} />}
                  <Form.Control
                    name="age"
                    type="text"
                    placeholder="年齡"
                    defaultValue={user_info.age}
                    onChange={() => {}}
                  />
                </div>
              </li>
            )}
            {user_info.male_or_female && (
              <li>
                <span className="label-text">性別</span>
                <div className="col position-relative">
                  {showLoading && <Loading setting={{ style: 'one' }} />}
                  <Form.Control
                    name="male_or_female"
                    type="text"
                    placeholder="性別"
                    defaultValue={user_info.male_or_female}
                    onChange={() => {}}
                  />
                </div>
              </li>
            )}
            {user_info.occupation && (
              <li>
                <span className="label-text">職業</span>
                <div className="col position-relative">
                  {showLoading && <Loading setting={{ style: 'one' }} />}
                  <AutoHeightText
                    setting={{
                      onChange: () => {},
                      value: user_info.occupation,
                      placeholder: '職業',
                      className: 'px-0 pt-0',
                    }}
                  />
                </div>
              </li>
            )}
            {user_info.task && (
              <li>
                <span className="label-text">任務</span>
                <div className="col position-relative">
                  {showLoading && <Loading setting={{ style: 'one' }} />}
                  <AutoHeightText
                    setting={{
                      onChange: () => {},
                      value: user_info.task,
                      placeholder: '任務',
                      className: 'px-0 pt-0',
                    }}
                  />
                </div>
              </li>
            )}
          </ul>
        </div>
        <div className="info-header-info flex-1 has-drag">
          <div className="label-text" style={{ marginLeft: '20px' }}>
            人物基本介紹：
          </div>
          <div className="position-relative">
            <TextArea
              setting={{
                value: user_info.user_scenario,
                labelId: 1,
              }}
            />
          </div>
        </div>
      </div>
      {/* end info-header */}
      <div className="info-body position-relative">
        <div
          className="over-block"
          style={{
            left: `${blockLeft + 2}px`,
            opacity: blockOpacity,
          }}
        >
          &nbsp;
        </div>
        <div className="info-item">
          <OverlayTrigger
            placement="right"
            delay={{ show: 250, hide: 400 }}
            overlay={
              <Tooltip
                className="style-dark tooltip-lg"
                style={{
                  zIndex: '9999',
                }}
              >
                <p>
                  顧客旅程通常以「接觸點」顯示具體的互動情境，例如「探索→比較→購買」或「開箱前→中→後」。每個接觸點對應以下資訊：
                </p>
                <ul>
                  <li>
                    使用者情緒：反映使用者在此階段的滿意度（從低到高），幫助您辨識滿意度低的節點進行優化。
                  </li>
                  <li>
                    痛點：說明此階段使用者可能遇到的挑戰，指出導致挫折或不便的原因。
                  </li>
                  <li>機會點：基於使用者需求和痛點，指出潛在的改進方向。</li>
                </ul>
              </Tooltip>
            }
          >
            <div className="tooltip-icon-wrap">
              如何閱讀
              <div className="tooltip-icon">
                <FontAwesomeIcon icon={faQuestion} />
              </div>
            </div>
          </OverlayTrigger>
          <div className="color-title label-title px-0">
            接觸點
            <span
              style={{
                marginLeft: '10px',
                fontWeight: '300',
              }}
            >
              <OverlayTrigger
                placement="right"
                delay={{ show: 250, hide: 400 }}
                overlay={
                  <Tooltip
                    className="tooltip-lg style-dark"
                    style={{
                      zIndex: '9999',
                    }}
                  >
                    <p>
                      使用者在體驗產品或服務過程中的關鍵階段，涵蓋行動、場景、接觸到的人事物。
                    </p>
                  </Tooltip>
                }
              >
                <Image
                  src="/images/svgicons/tooltip.svg"
                  alt=""
                  className="img-fluid"
                />
              </OverlayTrigger>
            </span>
          </div>
          <div className="row journeys-list">
            {journeys &&
              journeys.map((item, index) => (
                <EventItem
                  key={`event${index}`}
                  setting={{
                    item,
                    updatePos,
                    updateOpacity: () => {
                      setBlockOpacity(0)
                    },
                  }}
                />
              ))}
          </div>
        </div>
        <div className="info-item">
          <div className="color-title label-title px-0">
            使用者情緒
            <OverlayTrigger
              placement="right"
              delay={{ show: 250, hide: 400 }}
              overlay={
                <Tooltip
                  className="style-dark"
                  style={{
                    zIndex: '9999',
                  }}
                >
                  顯示使用者在各接觸點的情緒分數（1-5分），從負面到正面。負面情緒揭示改善空間；正面情緒則突顯體驗強項，適合進一步強化。
                </Tooltip>
              }
            >
              <Image
                src="/images/svgicons/tooltip.svg"
                alt=""
                className="img-fluid"
                style={{
                  marginLeft: '14px',
                }}
              />
            </OverlayTrigger>
          </div>
          <div className="bar-char">
            <BarChat
              setting={{
                emotions: bars,
                boardStyle,
                ...svgSize,
              }}
            />
          </div>
        </div>
        <div className="info-item">
          <div className="color-title label-title px-0">痛點</div>
          <div className="point-list">
            {points &&
              points.map(
                (item, index) =>
                  item.metaKey === 'painpoint' && (
                    <div
                      className="point-card"
                      key={`ponit${index}`}
                      onFocus={() => {}}
                      onMouseOver={(e) => {
                        if (e.target.closest('.point-card')) {
                          updatePos(
                            e.target.closest('.point-card').offsetLeft - 7
                          )
                        }
                      }}
                      onMouseLeave={() => {
                        setBlockOpacity(0)
                      }}
                    >
                      <div
                        style={{
                          marginLeft: '-20px',
                          marginRight: '-20px',
                        }}
                      >
                        <Label
                          setting={{
                            title: item.topic,
                            edit: true,
                          }}
                        />
                      </div>
                      <PointItem
                        setting={{
                          content: item.content,
                          metaKey: item.metaKey,
                        }}
                      />
                    </div>
                  )
              )}
          </div>
        </div>
        <div className="info-item">
          <div className="color-title label-title px-0">機會點</div>
          <div className="point-list">
            {points &&
              points.map(
                (item, index) =>
                  item.metaKey === 'opportunity' && (
                    <div
                      className="point-card"
                      key={`ponit${index}`}
                      onFocus={() => {}}
                      onMouseOver={(e) => {
                        if (e.target.closest('.point-card')) {
                          updatePos(
                            e.target.closest('.point-card').offsetLeft - 7
                          )
                        }
                      }}
                      onMouseLeave={() => {
                        setBlockOpacity(0)
                      }}
                    >
                      <div
                        style={{
                          marginLeft: '-20px',
                          marginRight: '-20px',
                        }}
                      >
                        <Label
                          setting={{
                            title: item.topic,
                            edit: true,
                          }}
                        />
                      </div>
                      <PointItem
                        setting={{
                          content: item.content,
                          metaKey: item.metaKey,
                        }}
                      />
                    </div>
                  )
              )}
          </div>
        </div>
      </div>
      {/* end info-body */}
    </div>
  )
}

function PointItem({ setting }) {
  const { content, metaKey } = setting

  // 模擬痛點與機會點列表
  const [pointList, setPointList] = useState([content])
  const addPoint = () => {
    setPointList([...pointList, ''])
  }

  return pointList.map((pointItem, i) => (
    <PointTextItem
      key={`point-${i}`}
      setting={{
        addPoint,
        pointItem,
        metaKey,
      }}
    />
  ))
}

function PointTextItem({ setting }) {
  const { addPoint = () => {}, pointItem, metaKey } = setting
  const [isLoading, setIsLoading] = useState(true)

  // 是否按下Enter新增痛點與機會點列表
  const [openEnter] = useState(false)

  useEffect(() => {
    if (isLoading) {
      setTimeout(() => {
        setIsLoading(false)
      }, 1000)
    }
  }, [isLoading])

  return (
    <div className={`has-drag ${metaKey === 'painpoint' ? 'weak' : 'chance'}`}>
      <div className="card-tool">
        <CardTool
          setting={{
            handleRegenerate: () => {
              setIsLoading(true)
            },
            onlyRegenerate: true,
          }}
        />
      </div>
      {isLoading && <Loading setting={{ style: '' }} />}
      <div className={`fade ${isLoading ? '' : 'show'}`}>
        <AutoHeightText
          setting={{
            onChange: () => {},
            onEnter: (e) => {
              if (openEnter) {
                if (e.key === 'Enter') {
                  addPoint()
                  e.preventDefault(false)
                }
              }
            },
            value: pointItem,
            placeholder: metaKey === 'painpoint' ? '痛點' : '機會點',
            className: '',
          }}
        />
      </div>
    </div>
  )
}

function Loading({ setting }) {
  const { style } = setting

  if (style === 'one') {
    return (
      <div
        className="loading-wrap"
        style={{
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
        }}
      >
        <div className="loading-bar h-100">loading</div>
      </div>
    )
  }

  return (
    <div className="loading-wrap">
      <div className="loading-bar mb-2">loading</div>
      <div className="loading-bar mb-4">loading</div>
      <div className="loading-bar mb-2">loading</div>
      <div className="loading-bar mb-4">loading</div>
      <div className="loading-bar mb-2">loading</div>
      <div className="loading-bar">loading</div>
    </div>
  )
}

function EventItem({ setting }) {
  const { item, updatePos = () => {}, updateOpacity = () => {} } = setting

  const array = [...item.action, ...[item.channels_and_stakeholders]]
  let textAreaList = ''
  array.forEach((innerItem) => {
    textAreaList += `<li>${innerItem}</li>`
  })
  textAreaList = `<ul>${textAreaList}</ul>`

  return (
    <div
      className="event-item"
      onFocus={() => {}}
      onMouseOver={(e) => {
        if (e.target.closest('.event-item')) {
          updatePos(e.target.closest('.event-item').offsetLeft)
        }
      }}
      onMouseLeave={() => {
        updateOpacity()
      }}
    >
      <div className="event-item-header">
        <Form.Control
          name="name"
          type="text"
          placeholder="行為標題"
          defaultValue={item.touchpoint}
          onChange={() => {}}
          className="text-center"
        />
      </div>
      <div className="position-relative">
        <div className="event-item-info">
          <TextArea
            setting={{
              value: textAreaList,
              labelId: 1,
            }}
          />
        </div>
      </div>
    </div>
  )
}

PointTextItem.propTypes = {
  setting: PropTypes.shape().isRequired,
}

PointItem.propTypes = {
  setting: PropTypes.shape().isRequired,
}

Loading.propTypes = {
  setting: PropTypes.shape().isRequired,
}

EventItem.propTypes = {
  setting: PropTypes.shape().isRequired,
}

SuggestPersona.propTypes = {
  setting: PropTypes.shape().isRequired,
}

export default SuggestPersona
