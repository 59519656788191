import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'
import { Row, Col, Button, Image, Form, DropdownButton } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { DraftContext } from '../components/ContextProvider'
import { EditText, Pagination } from '../components'
import TootipItem from '../components/TootipItem'
import AutoHeightText from '../tools/AutoHeightText'

function List() {
  const { list, setDraftId, setCurrentID, setDraft } = useContext(DraftContext)
  const navigate = useNavigate()

  return (
    <div className="content">
      <Row className="page-header">
        <Col>
          <h2 className="page-title">專案列表</h2>
        </Col>
        <Col className="column-right">
          {/* <ImportBtn setting={{ className: 'btn btn-primary btn-lg' }} /> */}
          <Button className="btn-lg" onClick={() => {}}>
            <Form className="position-relative">
              <Form.Label
                htmlFor="uploadfile"
                className="d-flex align-items-center mb-0 fw-normal text-white"
              >
                <div
                  className="svgIcon"
                  style={{
                    maskImage: `url('/images/svgicons/import2.svg')`,
                    marginRight: '10px',
                  }}
                >
                  import
                </div>
                匯入專案
              </Form.Label>
              <Form.Control
                id="uploadfile"
                name="uploadfile"
                type="file"
                accept=".xlsx"
                onChange={() => {}}
              />
            </Form>
          </Button>
          <Button
            className="btn-lg"
            onClick={(e) => {
              navigate('/create_module')
              e.stopPropagation()
            }}
          >
            <FontAwesomeIcon
              icon={faPlus}
              className="mx-3"
              style={{ transform: 'translateY(1px)' }}
            />
            新增專案
          </Button>
        </Col>
      </Row>
      <div className="list">
        <Row>
          {list.map(({ draft_id, title, note, update_date, img }, index) => (
            <div
              key={`card-${draft_id}`}
              className="project-card"
              style={{
                zIndex: list.length - index,
              }}
            >
              <div
                className="mask"
                role="button"
                tabIndex="0"
                onClick={() => {
                  setCurrentID(1)
                  setDraftId(draft_id)
                }}
                onKeyDown={(e) => {
                  if (e.key === 'Enter' || e.key === ' ') {
                    setCurrentID(1)
                    setDraftId(draft_id)
                  }
                }}
              >
                &nbsp;
              </div>
              <div className="inner">
                <div className="project-img">
                  <Image
                    src={`/images/project/${img}`}
                    className="d-block object-fit-cover"
                  />
                </div>
                <div className="project-excerpt">
                  <h3>
                    {draft_id}. {title}
                  </h3>
                  <p>{note}</p>
                  <div className="project-date">更新時間：{update_date}</div>
                </div>
              </div>
              <ProjectSetting
                setting={{
                  id: draft_id,
                  title,
                  note,
                  handleInputChange: (value) => {
                    setDraft({ module: { title: value } }, draft_id)
                  },
                  handleNoteChange: (value) => {
                    setDraft({ module: { note: value } }, draft_id)
                  },
                }}
              />
            </div>
          ))}
        </Row>
      </div>
      <Pagination />
    </div>
  )
}

function ProjectSetting({ setting }) {
  const { setList, showWarn, setShowWarn } = useContext(DraftContext)
  const { id, title, note, handleInputChange, handleNoteChange } = setting

  // 移除專案
  const handleDraftDelete = async (draft_id) => {
    setList((prevState) => prevState.filter((p) => draft_id !== p.draft_id))
  }

  return (
    <TootipItem title="點選編輯專案">
      <div className="project-setting">
        <DropdownButton
          title={
            <div className="btn-setting">
              <Image src="/images/svgicons/setting.svg" className="" />
            </div>
          }
        >
          <div className="project-setting-card toggle-wrap shadow-block">
            <Form>
              <div className="card-header">
                <EditText
                  setting={{
                    title,
                    handleInputChange,
                  }}
                />
              </div>
              <Form.Group className="form-group">
                <AutoHeightText
                  setting={{
                    onChange: (value) => {
                      handleNoteChange(value)
                    },
                    placeholder: '備註內容',
                    value: note,
                    showBorder: true,
                  }}
                />
              </Form.Group>
              <ul className="toggle-wrap-nav">
                <li>
                  <Button className="btn-text">
                    <div
                      className="svgIcon"
                      style={{
                        maskImage: `url('/images/svgicons/export.svg')`,
                      }}
                    >
                      export
                    </div>
                    匯出PDF
                  </Button>
                </li>
                <li>
                  <Button className="btn-text">
                    <div
                      className="svgIcon"
                      style={{
                        maskImage: `url('/images/svgicons/export.svg')`,
                      }}
                    >
                      export
                    </div>
                    匯出xlsx
                  </Button>
                </li>
                <li>
                  <Button
                    className="btn-text"
                    onClick={() => {
                      setShowWarn({
                        ...showWarn,
                        show: true,
                        text: '確定移除專案？',
                        yes: '確定',
                        no: '取消',
                        func: () => {
                          handleDraftDelete(id)
                        },
                      })
                    }}
                  >
                    <div
                      className="svgIcon"
                      style={{ maskImage: `url('/images/svgicons/trash.svg')` }}
                    >
                      刪除
                    </div>
                    移至垃圾桶
                  </Button>
                </li>
              </ul>
            </Form>
          </div>
        </DropdownButton>
      </div>
    </TootipItem>
  )
}

ProjectSetting.propTypes = {
  setting: PropTypes.shape().isRequired,
}

export default List
