import React, { useState, useContext } from 'react'
import PropTypes from 'prop-types'
import ToolBar from './ToolBar'
import EditText from './EditText'
import HeaderAccount from './HeaderAccount'
import SideBar from './SideBar'
import Records from './Records'
import Board from './Board'
import Footer from './Footer'
import { DraftContext } from './ContextProvider'

function EditWrapper({ children, setting }) {
  const {
    toolbarTitle = '',
    toolbarTitleDesc = '',
    moduleTitle,
    moduleKeyword,
    showToolbar = false,
    boards = [],
    boardStyle,
    switchBoard = () => {},
  } = setting
  const [showRecord, setShowRecord] = useState(false)
  const [showBoard, setShowBoard] = useState(false)

  // 版本紀錄
  const openRecord = () => {
    setShowRecord(true)
    setShowBoard(false)
  }
  const closeRecord = () => setShowRecord(false)

  // 變更面板
  const openBoard = () => {
    setShowRecord(false)
    setShowBoard(true)
  }
  const closeBoard = () => setShowBoard(false)

  // 手機版開關選單
  const [toggleNav, setToggleNav] = useState(false)
  const toggleMobileNav = (isOpen) => {
    setToggleNav(isOpen)
  }

  return (
    <div className="content edit-content flex-1">
      <EditHeader
        setting={{
          moduleTitle,
        }}
      />
      <SideBar
        setting={{
          toggleMobileNav,
          toggleNav,
        }}
      />
      <div className={`tab-group ${toggleNav ? 'open' : ''}`}>
        <ToolBar
          setting={{
            toolbarTitle,
            toolbarTitleDesc,
            moduleKeyword,
            openRecord,
            openBoard,
          }}
        />
      </div>
      <div
        id="moduleContent"
        className={`module-content ${toggleNav ? 'open' : ''}`}
      >
        <div className={`module-body ${showToolbar ? 'hasToolbar' : ''}`}>
          {children}
        </div>
        <Footer showRemark hasToolbar={showToolbar} />
      </div>
      {showRecord && <Records setting={{ showRecord, closeRecord }} />}
      {showBoard && (
        <Board
          setting={{
            closeBoard,
            boards,
            boardStyle,
            switchBoard,
            onClickOutside: closeBoard,
          }}
        />
      )}
    </div>
  )
}

function EditHeader({ setting }) {
  const { setDraft, draft } = useContext(DraftContext)
  const { moduleTitle } = setting
  const handleInputChange = (value) => {
    setDraft({ module: { title: value } }, draft.draft_id)
  }

  return (
    <div id="header" className="fixed pageNav">
      <nav className="navbar navbar-expand-lg">
        <div className="d-flex align-items-center">
          <EditText
            setting={{
              title: draft.title || moduleTitle,
              handleInputChange,
              bgColor: '#FFFFFF',
            }}
          />
        </div>
        <div className="d-flex">
          <HeaderAccount />
        </div>
      </nav>
    </div>
  )
}

EditWrapper.propTypes = {
  children: PropTypes.shape().isRequired,
  setting: PropTypes.shape().isRequired,
}

EditHeader.propTypes = {
  setting: PropTypes.shape().isRequired,
}

export default EditWrapper
