import React, { useState, useMemo, createContext } from 'react'
import PropTypes from 'prop-types'
import Toast from 'react-bootstrap/Toast'
import ToastContainer from 'react-bootstrap/ToastContainer'
import { v4 as uuid } from 'uuid'
import { moduleData } from '../data/moduleData'

function ContextProvider(props) {
  const { children } = props
  const [currentID, setCurrentID] = useState(1)
  const [editItems] = useState([
    {
      id: uuid(),
      type: 'text',
      name: '文字',
      icon: 'text.svg',
    },
    {
      id: uuid(),
      type: 'card',
      name: '卡片',
      icon: 'card.svg',
    },
    {
      id: uuid(),
      type: 'media',
      name: '多媒體',
      icon: 'media.svg',
    },
  ])

  const [componentType, setComponentType] = useState('table')
  const [editDetail] = useState({
    table: [],
    text: [
      {
        id: uuid(),
        name: '區塊標題',
        type: 'title',
        img: 'title.png',
        content: '區塊標題',
      },
      {
        id: uuid(),
        name: '文字',
        type: 'text',
        img: 'text.png',
        content: [
          '<strong>政策支持</strong><p>政府是否提供吉他產業相關的補助或稅收優惠？2.政府是否積極推動音樂教育，以提升吉他市場需求？3.政府是否鼓勵本土吉他製造業的發展，以提高產業競爭力？</p>',
        ],
      },
    ],
    card: [
      {
        id: uuid(),
        name: '卡片一',
        img: 'card-1.png',
        type: 'card',
        content: [
          [
            '政策支持',
            '<p>政府是否提供吉他產業相關的補助或稅收優惠？2.政府是否積極推動音樂教育，以提升吉他市場需求？3.政府是否鼓勵本土吉他製造業的發展，以提高產業競爭力？</p>',
          ],
        ],
      },
      {
        id: uuid(),
        name: '卡片二',
        img: 'card-2.png',
        type: 'card',
        content: [
          [
            '政策支持',
            '<p>政府是否提供吉他產業相關的補助或稅收優惠？2.政府是否積極推動音樂教育，以提升吉他市場需求？3.政府是否鼓勵本土吉他製造業的發展，以提高產業競爭力？</p>',
          ],
          [
            '政策支持',
            '<p>政府是否提供吉他產業相關的補助或稅收優惠？2.政府是否積極推動音樂教育，以提升吉他市場需求？3.政府是否鼓勵本土吉他製造業的發展，以提高產業競爭力？</p>',
          ],
        ],
      },
      {
        id: uuid(),
        name: '卡片三',
        img: 'card-3.png',
        type: 'card',
        content: [
          [
            '政策支持',
            '<p>政府是否提供吉他產業相關的補助或稅收優惠？2.政府是否積極推動音樂教育，以提升吉他市場需求？3.政府是否鼓勵本土吉他製造業的發展，以提高產業競爭力？</p>',
          ],
          [
            '政策支持',
            '<p>政府是否提供吉他產業相關的補助或稅收優惠？2.政府是否積極推動音樂教育，以提升吉他市場需求？3.政府是否鼓勵本土吉他製造業的發展，以提高產業競爭力？</p>',
          ],
          [
            '政策支持',
            '<p>政府是否提供吉他產業相關的補助或稅收優惠？2.政府是否積極推動音樂教育，以提升吉他市場需求？3.政府是否鼓勵本土吉他製造業的發展，以提高產業競爭力？</p>',
          ],
        ],
      },
    ],
    media: [],
  })

  const [list, setList] = useState(
    moduleData.filter((item) => item.is_enable === true)
  )
  const [draftId, setDraftId] = useState('')
  const [cardList, setCardList] = useState({})

  const [suggestCardList, setSuggestCardList] = useState([])

  // 側邊欄元件展開
  const [sidebar, setSidebar] = useState(false)
  const openSidebar = () => {
    setSidebar(true)
  }

  const closeSidebar = () => {
    setSidebar(false)
  }

  const draft = useMemo(
    () =>
      list && draftId ? list.find(({ draft_id }) => draft_id === draftId) : {},
    [list, draftId]
  )

  // undo and redo
  const [history, setHistory] = useState([])
  const [recoverTarget, setRecoverTarget] = useState(-1)
  const [cursor, setCursor] = useState(-1)
  const handleRecover = (target) => setRecoverTarget(target)

  // 警告視窗
  const [showWarn, setShowWarn] = useState({
    show: false,
    text: '',
    yes: '確定',
    no: '關閉',
  })

  // 重新生成視窗
  const [showGen, setShowGen] = useState({ show: false, text: '' })

  const draftValue = useMemo(
    () => ({
      list,
      setList,
      currentID,
      setCurrentID,
      editItems,
      editDetail,
      draft,
      draftId,
      setDraftId,
      history,
      setHistory,
      recoverTarget,
      setRecoverTarget,
      cursor,
      setCursor,
      handleRecover,
      componentType,
      setComponentType,
      showWarn,
      setShowWarn,
      sidebar,
      openSidebar,
      closeSidebar,
      cardList,
      setCardList,
      suggestCardList,
      setSuggestCardList,
      showGen,
      setShowGen,
      setDraft: (data, selectedId = '') => {
        setList((prevState) =>
          prevState
            ? prevState.map((p) =>
                p.draft_id === (draftId || selectedId)
                  ? {
                      ...p,
                      ...data.module,
                    }
                  : p
              )
            : prevState
        )
      },
    }),
    [
      list,
      editItems,
      editDetail,
      draftId,
      cursor,
      recoverTarget,
      history,
      cardList,
      componentType,
      sidebar,
      currentID,
      showWarn,
      showGen,
    ]
  )

  // 警告視窗
  const [toast, setToast] = useState({
    show: false,
    text: '',
    func: () => {},
  })
  const toastValue = useMemo(() => ({ toast, setToast }), [toast])

  return (
    <>
      <ToastContext.Provider value={toastValue}>
        <DraftContext.Provider value={draftValue}>
          {children}
        </DraftContext.Provider>
      </ToastContext.Provider>
      <ToastContainer position="top-center">
        <Toast
          onClose={() => setToast({ ...toast, show: false })}
          show={toast.show}
          delay={3000}
          autohide
          style={{ width: '100%' }}
        >
          <Toast.Body>{toast.text}</Toast.Body>
        </Toast>
      </ToastContainer>
    </>
  )
}

ContextProvider.propTypes = {
  children: PropTypes.shape().isRequired,
}

export const ToastContext = createContext({
  toast: { show: false, text: '' },
  setToast: () => {},
  func: () => {},
})

export const DraftContext = createContext(null)

export default ContextProvider
