import { create } from 'zustand'

const useDesignStore = create((set) => ({
  opportunities_and_challenges: [],
  setField: (field, value) => set((state) => ({ ...state, [field]: value })),
  addDesignStore: (field, obj) =>
    set((state) => ({
      ...state,
      [field]: [...state[field], ...[obj]],
    })),
  removeDesignStore: (field, id) =>
    set((state) => ({
      ...state,
      [field]: state[field].filter((item, index) => index !== id),
    })),
}))

export default useDesignStore
