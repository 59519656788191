import React from 'react'
import PropTypes from 'prop-types'
import { Label, TextArea } from '../tools'

function StoryCard({ setting }) {
  const { label, text, className = '', icon = '', showLoading = true } = setting

  return (
    <div className="h-100">
      {className === 'hor-card' && (
        <div className="story-title">
          <div className="story-icon">
            <img src={icon} alt="" className="w-100" />
          </div>
          {label && (
            <Label
              setting={{
                label: '',
                title: label,
                edit: true,
              }}
            />
          )}
        </div>
      )}
      <div className={`position-relative ${className} h-100`}>
        {className !== 'hor-card' && label && (
          <div style={{ marginLeft: '-20px', marginRight: '-20px' }}>
            <Label
              setting={{
                label: '',
                title: label,
                edit: true,
              }}
            />
          </div>
        )}
        <div style={{ marginLeft: '-20px', marginRight: '-20px' }}>
          <TextArea
            setting={{
              label: '',
              value: text,
              showLoading,
            }}
          />
        </div>
      </div>
    </div>
  )
}

StoryCard.propTypes = {
  setting: PropTypes.shape().isRequired,
}

export default StoryCard
