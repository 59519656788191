import React, { useState, useEffect, useContext } from 'react'
import { EditWrapper } from '../../components'
import { DraftContext } from '../../components/ContextProvider'
import SuggestDesign from './SuggestDesign'
import Subnav from './Subnav'
import { useDesignStore } from '../../store'
import { initMultipleDataFromJson } from '../../lib/utility'

function Product() {
  const { draft, showGen, setShowGen } = useContext(DraftContext)
  const [module] = useState(draft.draft_id && draft ? draft : {})

  // temp record
  const [records] = useState([])
  const [boardStyle, setBoardStyle] = useState('card')
  const boards = [
    {
      id: 1,
      title: '設計機會及挑戰橫式',
      image: '/images/boards/board-card.svg',
      key: 'card',
    },
    {
      id: 2,
      title: '設計機會及挑戰直式',
      image: '/images/boards/board-list.svg',
      key: 'list',
    },
  ]

  const switchBoard = (board) => {
    setBoardStyle(board)
  }

  const { opportunities_and_challenges, setField } = useDesignStore(
    (store) => ({
      opportunities_and_challenges: store.opportunities_and_challenges,
      setField: store.setField,
    })
  )

  // loading
  const [showLoading, setShowLoading] = useState(true)
  const [keyword, setKeyword] = useState('')

  useEffect(() => {
    if (module.module2) {
      const jsonUrl = module.module2.design.json_urls
      // const json_urls = [
      //   {
      //     url: '/data/competitive_product_analysis/design.json',
      //     dataName: 'opportunities_and_challenges',
      //     target: '2-3_opportunities-and-challenges',
      //   },
      // ]
      initMultipleDataFromJson(jsonUrl, setField)
    }

    if (!module.title) {
      setKeyword('')
      setShowLoading(false)
      setShowGen({
        ...showGen,
        show: true,
        isToolBarBtn: true,
        noteText:
          '生成競品分析有兩個關鍵要素：<strong>「市場」</strong>和<strong>「產業/服務」</strong>，您可用習慣的句型輸入提示語。',
        noteContent: [
          '<strong>歐洲</strong>可以賣<strong>珍珠奶茶</strong>嗎？',
          '<strong>泰國</strong> <strong>電動輔助自行車</strong> 城鄉需求',
          '我想分析Netflix造成<strong>韓國旅遊市場</strong>的新興趨勢',
        ],
        showOther: '*若無輸入特定參數，AI會根據上下文自動匹配適合的內容。',
        modal: 0,
        func: () => {},
        placeholder: '我想分析 [市場] 的 [產業/服務]',
      })
    } else {
      setKeyword('分析台灣市場的自行車產業')
    }

    if (showLoading) {
      setTimeout(() => {
        setShowLoading(false)
      }, 1000)
    }
  }, [])

  return (
    module.module2 && (
      <EditWrapper
        setting={{
          showInput: module.show_input,
          toolbarTitle: '生成競品分析',
          toolbarTitleDesc:
            '生成競品分析有兩個關鍵要素：<strong>「市場」</strong>和<strong>「產業/服務」</strong>。',
          moduleTitle: module.title,
          moduleKeyword: keyword,
          records,
          boards,
          boardStyle,
          switchBoard,
        }}
      >
        <>
          <Subnav setting={{ currentID: 3 }} />
          <div className="tab-panel">
            <SuggestDesign
              setting={{
                module: opportunities_and_challenges,
                boardStyle,
                showLoading,
              }}
            />
          </div>
        </>
      </EditWrapper>
    )
  )
}

export default Product
