/* eslint-disable no-nested-ternary */
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Image, OverlayTrigger, Tooltip } from 'react-bootstrap'

function Star({ setting }) {
  const { star, showLoading } = setting
  const [newStar, setNewStar] = useState(star)
  const [hoverRate, setHoverRate] = useState(0)

  return (
    <div className="stars">
      推薦度：
      <div
        className="d-inline-block position-relative"
        style={{
          marginRight: '10px',
        }}
      >
        {showLoading && (
          <div
            className="loading-wrap"
            style={{
              top: '6px',
              bottom: '5px',
              left: '0',
              right: '0',
            }}
          >
            <div className="loading-bar">loading</div>
          </div>
        )}
        <div className={`fade ${showLoading ? '' : 'show'}`}>
          {[1, 2, 3, 4, 5].map((rate) => (
            <button
              type="button"
              key={`star${rate}`}
              className={`star-icon ${
                hoverRate > 0
                  ? hoverRate >= rate
                    ? 'active'
                    : ''
                  : parseInt(newStar, 10) >= rate
                  ? 'active'
                  : ''
              }`}
              onFocus={() => {}}
              onBlur={() => {}}
              onMouseOver={() => {
                setHoverRate(rate)
              }}
              onMouseOut={() => {
                setHoverRate(0)
              }}
              onClick={() => {
                setNewStar(rate)
              }}
            >
              {rate}
            </button>
          ))}
        </div>
      </div>
      <OverlayTrigger
        placement="right"
        delay={{ show: 250, hide: 400 }}
        overlay={
          <Tooltip
            className="style-dark"
            style={{
              zIndex: '9999',
            }}
          >
            <p>
              AI根據方案的市場競爭優勢給予1-5星的推薦分數，星數越高，代表該方案具更高的創新潛力。
            </p>
          </Tooltip>
        }
      >
        <Image
          src="/images/svgicons/tooltip.svg"
          alt=""
          className="img-fluid mx-2"
        />
      </OverlayTrigger>
    </div>
  )
}

Star.propTypes = {
  setting: PropTypes.shape().isRequired,
}

export default Star
