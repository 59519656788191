/* eslint-disable no-promise-executor-return */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react'
import { Image } from 'react-bootstrap'
import AOS from 'aos'
import 'aos/dist/aos.css'

function Home() {
  const [bannerInfo] = useState([
    '透過AI為產品產生強大的分析模型',
    'We have assembled 4,500 of our best infographics into one amazing package for you! Not only do you receive the premium, fully-customizable infographics that are specially-designed for 105+ niche markets, when you register today you receive lifetime access.',
  ])

  const [sectionInfo] = useState([
    'Create perfect Model right from the beginning',
    'We have assembled 4,500 of our best infographics into one amazing package for you! Not only do you receive the premium, fully-customizable infographics that are specially-designed for 105+ niche markets, when you register today you receive lifetime access.',
  ])

  const [toolCards] = useState([
    {
      id: 1,
      title: 'AI生成',
      content: '',
      icon: 'icon01.png',
      icon_2x: 'icon01@2x.png',
    },
    {
      id: 2,
      title: '從電腦匯入內容',
      content: '',
      icon: 'icon02.png',
      icon_2x: 'icon02@2x.png',
    },
    {
      id: 3,
      title: '方便的模組',
      content: '',
      icon: 'icon03.png',
      icon_2x: 'icon03@2x.png',
    },
    {
      id: 4,
      title: '可重複編輯物件',
      content:
        'We have assembled 4,500 of our best infographics into one amazing package for you! Not only do you receive the premium, fully-customizable infographics that are specially-designed for 105+ niche markets, when you register today you receive lifetime access.',
      icon: 'icon04.png',
      icon_2x: 'icon04@2x.png',
    },
    {
      id: 5,
      title: '雲端儲存與資料匯出',
      content:
        'We have assembled 4,500 of our best infographics into one amazing package for you! Not only do you receive the premium, fully-customizable infographics that are specially-designed for 105+ niche markets, when you register today you receive lifetime access.',
      icon: 'icon05.png',
      icon_2x: 'icon05@2x.png',
    },
  ])

  useEffect(() => {
    AOS.init()
  }, [])

  return (
    <>
      <div
        className="full-banner"
        style={{ backgroundImage: "url('/images/banner.jpg')" }}
      >
        <div
          className="banner-info"
          data-aos="fade-right"
          data-aos-duration="1200"
        >
          <strong>{bannerInfo[0]}</strong>
          <p>{bannerInfo[1]}</p>
        </div>

        <div className="scroll-animate">
          <hr />
          Scroll Down
          <div className="mousey">
            <div className="scroller">&nbsp;</div>
          </div>
        </div>
      </div>
      <div className="section">
        <div className="section-info text-center">
          <strong className="section-title">{sectionInfo[0]}</strong>
          <p className="section-p">{sectionInfo[1]}</p>
          <a href="/" className="link">
            Start for free
          </a>
        </div>
        <div
          className="dashboard-img"
          data-aos="fade-up"
          data-aos-offset="300"
          data-aos-duration="600"
        >
          <Image src="/images/dashboard.png" className="img-fluid" />
          <strong className="text-center">Four AI analysis modules</strong>
        </div>
        <Image
          src="/images/hero-assets-ac-1.jpg"
          srcSet="/images/hero-assets-ac-1@2x.jpg 2x"
          className="section-img"
          alt=""
        />
      </div>
      <div className="tools-section">
        <div className="tool-cards">
          {toolCards.map((card, index) => (
            <div
              className="tool-card"
              key={`card-${index}`}
              data-aos="fade-up"
              data-aos-offset="300"
              data-aos-duration="600"
            >
              <div className="inner">
                <strong>{card.title}</strong>
                <div className="d-lg-flex flex-wrap">
                  <div className="col">
                    {card.content && <p>{card.content}</p>}
                  </div>
                  <div className="img">
                    <Image
                      src={`/images/index-icons/${card.icon}`}
                      srcSet={`/images/index-icons/${card.icon_2x} 2x`}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  )
}

export default Home
