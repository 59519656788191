/* eslint-disable no-param-reassign */
import React, { useEffect, useState, useRef } from 'react'
import PropTypes from 'prop-types'
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd'
import DesignCard from './DesignCard'
import DesignCardColumn from './DesignCardColumn'
import DragBar from '../../components/DragBar'
import { useDesignStore } from '../../store'

function SuggestDesign({ setting }) {
  const { boardStyle, showLoading } = setting

  const {
    setField,
    addDesignStore,
    removeDesignStore,
    opportunities_and_challenges,
  } = useDesignStore((store) => ({
    opportunities_and_challenges: store.opportunities_and_challenges,
    setField: store.setField,
    addDesignStore: store.addDesignStore,
    removeDesignStore: store.removeDesignStore,
  }))

  const labelEdit = (label, id, value) => {
    const updatedOpportunitiesAndChallenges = [...opportunities_and_challenges]
    if (id === 0) {
      updatedOpportunitiesAndChallenges[label] = {
        ...updatedOpportunitiesAndChallenges[label],
        opportunities_headline: value,
      }
    } else {
      updatedOpportunitiesAndChallenges[label] = {
        ...updatedOpportunitiesAndChallenges[label],
        challenges_headline: value,
      }
    }

    setField('opportunities_and_challenges', updatedOpportunitiesAndChallenges)
  }

  const textEdit = (label, id, value) => {
    const updatedOpportunitiesAndChallenges = {
      ...opportunities_and_challenges,
    }
    if (id === 0) {
      updatedOpportunitiesAndChallenges[label] = {
        ...updatedOpportunitiesAndChallenges[label],
        opportunities: value,
      }
    } else {
      updatedOpportunitiesAndChallenges[label] = {
        ...updatedOpportunitiesAndChallenges[label],
        challenges: value,
      }
    }
    setField('opportunities_and_challenges', updatedOpportunitiesAndChallenges)
  }

  // 高度對齊
  const cardsRef = useRef(null)
  const [isReady, setIsReady] = useState(false)
  let maxH = 0

  // 清除高度
  const clearHeight = () => {
    maxH = 0
    if (cardsRef.current.querySelectorAll('.opportunities')) {
      cardsRef.current.querySelectorAll('.opportunities').forEach((item) => {
        item.removeAttribute('style')
        if (item.offsetHeight > maxH) {
          maxH = item.offsetHeight
        }
      })
    }
  }

  const editHeight = () => {
    clearHeight()
    if (cardsRef.current.querySelectorAll('.opportunities')) {
      if (cardsRef.current.querySelectorAll('.opportunities')) {
        cardsRef.current.querySelectorAll('.opportunities').forEach((item2) => {
          item2.style.height = `${maxH}px`
        })
      }
    }
  }

  useEffect(() => {
    if (cardsRef.current.querySelectorAll('.opportunities')) {
      cardsRef.current.querySelectorAll('.opportunities').forEach((item) => {
        if (item.offsetHeight > maxH) {
          maxH = item.offsetHeight
        }
      })
      if (isReady) {
        cardsRef.current.querySelectorAll('.opportunities').forEach((item2) => {
          item2.style.height = `${maxH}px`
        })
      }
    }
  }, [opportunities_and_challenges, isReady])

  // 判斷tiny edit 已載入
  const editInit = () => {
    setIsReady(true)
  }

  const handleDelete = (id) => {
    removeDesignStore('opportunities_and_challenges', id)
    clearHeight()
  }

  const handleAdd = () => {
    // 最多4筆
    if (opportunities_and_challenges.length < 4) {
      addDesignStore('opportunities_and_challenges', [])
      clearHeight()
    }
  }

  const reorder = (cardlist, startIndex, endIndex) => {
    const result = Array.from(cardlist)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)

    return result
  }

  const onDragEnd = (result) => {
    const { destination } = result
    if (!destination) {
      return
    }

    const updatedData = reorder(
      opportunities_and_challenges,
      result.source.index,
      result.destination.index
    )

    setField('opportunities_and_challenges', updatedData)
  }

  return (
    <div ref={cardsRef} className="edit-block">
      {boardStyle === 'card' && (
        <div className="row">
          <div className="card-wrapper">
            <div className="module-control design-card">
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="card-drop-1" direction="horizontal">
                  {(dropProvided) => (
                    <div
                      // eslint-disable-next-line
                      {...dropProvided.droppableProps}
                      ref={dropProvided.innerRef}
                      className="card-item-list row"
                    >
                      {opportunities_and_challenges.map((content, index) => (
                        <Draggable
                          key={`item-${index}`}
                          draggableId={`column-${index}`}
                          index={index}
                        >
                          {(dragProvided) => (
                            <div
                              ref={dragProvided.innerRef}
                              // eslint-disable-next-line
                              {...dragProvided.draggableProps}
                              // eslint-disable-next-line
                              // {...dragProvided.dragHandleProps}
                              className="col has-drag"
                            >
                              <div className="position-relative">
                                <DragBar dragProvided={dragProvided} />
                                <DesignCard
                                  key={index}
                                  setting={{
                                    label: index,
                                    content,
                                    labelEdit,
                                    textEdit,
                                    editInit,
                                    editHeight,
                                    handleAdd,
                                    handleDelete,
                                    count: opportunities_and_challenges.length,
                                    showLoading,
                                  }}
                                />
                              </div>
                            </div>
                          )}
                        </Draggable>
                      ))}
                      {dropProvided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </div>
          </div>
        </div>
      )}

      {boardStyle === 'list' && (
        <DesignCardColumn
          setting={{
            content: opportunities_and_challenges,
            onDragEnd,
            handleAdd,
            handleDelete,
            count: opportunities_and_challenges.length,
            showLoading,
          }}
        />
      )}
    </div>
  )
}

SuggestDesign.propTypes = {
  setting: PropTypes.shape().isRequired,
}

export default SuggestDesign
