import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd'
import {
  SectionTitle,
  Label,
  Star,
  Text,
  DragGallery,
  Loading,
} from '../../tools'
import DragBar from '../../components/DragBar'
import CardTool from '../../components/CardTool'

function InovationRow({ setting }) {
  const { module, handleList, list_index, showLoading } = setting

  const onDragEnd = (result) => {
    const { source, destination } = result
    if (!destination) {
      return
    }

    handleList(source, destination, list_index)
  }

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId={`card-drop-${list_index}`} direction="horizontal">
        {(dropProvided) => (
          <div
            // eslint-disable-next-line
            {...dropProvided.droppableProps}
            ref={dropProvided.innerRef}
            className="card-item-list row"
          >
            {module.map((item, index) => (
              <Draggable
                key={`item-${index}`}
                draggableId={`column-${index}`}
                index={index}
              >
                {(dragProvided) => (
                  <div
                    ref={dragProvided.innerRef}
                    // eslint-disable-next-line
                    {...dragProvided.draggableProps}
                    // eslint-disable-next-line
                    // {...dragProvided.dragHandleProps}
                    className="stage-section board-column3x has-drag"
                  >
                    <InovationItem
                      setting={{
                        item,
                        dragProvided,
                        showLoading,
                        index,
                      }}
                    />
                  </div>
                )}
              </Draggable>
            ))}
            <div style={{ height: 0 }}>{dropProvided.placeholder}</div>
          </div>
        )}
      </Droppable>
    </DragDropContext>
  )
}

function InovationItem({ setting }) {
  const { item = {}, dragProvided, showLoading, index } = setting
  const [isLoading, setIsLoading] = useState(showLoading)
  const handleRegenerateAll = () => {
    setIsLoading(true)
    setTimeout(() => {
      setIsLoading(false)
    }, 1000)
  }

  useEffect(() => {
    if (isLoading) {
      setTimeout(() => {
        setIsLoading(false)
      }, 1000)
    }
  }, [])

  return (
    <div className="shadow-block h-100 column-item position-relative">
      <div className="card-tool" style={{ left: 'auto', right: '10px' }}>
        <CardTool
          setting={{
            handleRegenerate: () => handleRegenerateAll(),
            onlyRegenerate: true,
            renGenText: '全部重新生成',
          }}
        />
      </div>
      <DragBar dragProvided={dragProvided} />
      <div className="color-card-body d-flex flex-column">
        <SectionTitle
          setting={{
            title: `方案${index + 1}`,
            edit: false,
          }}
        />
        <div
          className="position-relative"
          style={{
            marginLeft: '-20px',
            marginRight: '-20px',
            marginBottom: '20px',
          }}
        >
          {isLoading && <Loading setting={{ style: 'one' }} />}
          <div className={`fade ${isLoading ? '' : 'show'}`}>
            <Label
              setting={{
                title: item.headline,
                edit: true,
              }}
            />
          </div>
        </div>
        <div className="case-content position-relative">
          <div className="module-item">
            <Star
              setting={{
                star: item.star,
                showLoading: isLoading,
              }}
            />
          </div>
          <div
            className="module-item flex-1"
            style={{
              marginTop: '20px',
              marginBottom: '20px',
            }}
          >
            <Text
              setting={{
                content: [item.ideas],
                label: index,
                showLoading: isLoading,
              }}
            />
          </div>
        </div>
        <div className="module-item">
          <DragGallery
            setting={{
              imgs: item.images,
              list_index: index,
              showLoading: isLoading,
            }}
          />
        </div>
      </div>
    </div>
  )
}

InovationItem.propTypes = {
  setting: PropTypes.shape().isRequired,
}

InovationRow.propTypes = {
  setting: PropTypes.shape().isRequired,
}

export default InovationRow
