import React from 'react'
import { Image, OverlayTrigger, Tooltip } from 'react-bootstrap'
import ExampleTooltip from '../ExampleTooltip'

function User() {
  return (
    <div className="create-note">
      在使用者研究中，有三個關鍵要素：「
      <OverlayTrigger
        placement="bottom"
        overlay={
          <Tooltip className="style-dark">
            <p className="note-text">
              描述目標使用者的行為模式或習慣（如，經常出差、偏好環保）。
            </p>
          </Tooltip>
        }
      >
        <span className="note-span">
          行為特徵
          <Image
            src="/images/svgicons/tooltip-primary.svg"
            alt=""
            className="img-fluid"
          />
        </span>
      </OverlayTrigger>
      」、「
      <OverlayTrigger
        placement="bottom"
        overlay={
          <Tooltip className="style-dark">
            <p className="note-text">
              指使用者的身份或職業類型（如，通勤族、產品設計師、健身教練）。
            </p>
          </Tooltip>
        }
      >
        <span className="note-span">
          角色/職業
          <Image
            src="/images/svgicons/tooltip-primary.svg"
            alt=""
            className="img-fluid"
          />
        </span>
      </OverlayTrigger>
      」和「
      <OverlayTrigger
        placement="bottom"
        overlay={
          <Tooltip className="style-dark">
            <p className="note-text">
              使用者想達成的特定目標或任務（如，減少通勤時間、提升體能表現）。
            </p>
          </Tooltip>
        }
      >
        <span className="note-span">
          目標/任務
          <Image
            src="/images/svgicons/tooltip-primary.svg"
            alt=""
            className="img-fluid"
          />
        </span>
      </OverlayTrigger>
      」。
      <ExampleTooltip
        setting={{
          text: '例如',
          title: '您可用習慣的句型輸入提示語：',
          content: [
            '經常<strong>騎自行車</strong>的上班族，希望<strong>找到通勤最佳方案</strong>',
            '<strong>折疊自行車設計師</strong> <strong>注重ESG</strong> <strong>設計模組化產品</strong> <strong>容易回收的服務</strong>',
            '對<strong>科技感興趣</strong>的<strong>健身教練</strong>，<strong>探索最新AI自動化課程工具</strong>',
          ],
          option: 2,
        }}
      />
      <span className="otherText d-block mt-2">
        * 若無輸入特定關鍵字，AI會根據上下文自動匹配適合的內容。
      </span>
    </div>
  )
}

export default User
