import Home from './Home'

// 會員
import Login from './Login'
import Register from './Register'
import ForgetPassword from './ForgetPassword'
import EditPassword from './EditPassword'
import Dashboard from './Dashboard'

// 專案
import List from './List'
import Module1 from './Module1'
import Product from './module2/Product'
import Matrix from './module2/Matrix'
import Design from './module2/Design'
import Weak from './module2/Weak'
import Inovation from './module2/Inovation'
import Module3 from './Module3'
import Module4 from './Module4'
import CreateModule from './CreateModule'

export {
  Home,
  Login,
  Register,
  ForgetPassword,
  EditPassword,
  Dashboard,
  List,
  Module1,
  Product,
  Matrix,
  Design,
  Weak,
  Inovation,
  Module3,
  Module4,
  CreateModule,
}
