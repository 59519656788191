import React from 'react'
import PropTypes from 'prop-types'
import { Card, Text, Gallery, Label, TextArea, Title } from '../tools'

function AllEditor({ setting }) {
  const {
    type,
    content,
    label,
    addItem,
    removeItem,
    setType = () => {},
  } = setting

  return (
    <div>
      {type === 'title' && (
        <Title
          setting={{
            content,
            showLoading: false,
          }}
        />
      )}

      {type === 'text' && (
        <Text
          setting={{
            content,
            label,
            showLoading: false,
          }}
        />
      )}

      {type === 'card' && (
        <Card
          setting={{
            label,
            content,
            addItem,
            removeItem,
            hideToolbar: false,
            showLoading: false,
          }}
        />
      )}

      {type === 'gallery' && (
        <Gallery
          setting={{
            imgs: content || [],
            setType,
            showLoading: false,
          }}
        />
      )}

      {type === 'story-card' && (
        <div className="inner shadow-block">
          <div style={{ marginLeft: '-20px', marginRight: '-20px' }}>
            <Label
              setting={{
                label: '',
                title: content.title,
                edit: true,
              }}
            />
            <TextArea
              setting={{
                label: '',
                value: content.content,
                showLoading: false,
              }}
            />
          </div>
        </div>
      )}
    </div>
  )
}

AllEditor.propTypes = {
  setting: PropTypes.shape().isRequired,
}

export default AllEditor
