import React, { useState, useEffect, useContext } from 'react'
// import { v4 as uuid } from 'uuid'
import { DraftContext } from '../components/ContextProvider'
import { EditWrapper } from '../components'
import EditorArea from '../components/EditorArea'
import { usePersonaStore } from '../store'
import { initMultipleDataFromJson } from '../lib/utility'

function Module4() {
  const { draft, showGen, setShowGen } = useContext(DraftContext)
  const [module] = useState(draft.draft_id && draft ? draft : {})
  const [boardStyle, setBoardStyle] = useState('vertical')
  const boards = [
    {
      id: 1,
      title: '人物誌/編輯狀態直式',
      image: '/images/boards/module4-vertical.svg',
      key: 'vertical',
    },
    {
      id: 2,
      title: '人物誌/編輯狀態橫式',
      image: '/images/boards/module4-horizon.svg',
      key: 'horizon',
    },
  ]

  const switchBoard = (board) => {
    setBoardStyle(board)
  }

  // temp records
  const [records] = useState([])
  // const [records, setRecords] = useState([])

  // useEffect(() => {
  //   if (module.module4) {
  //     setRecords(module.module4.history && module.module4.history)
  //   }
  // }, [module])

  const { user_info, quote, health_advocate_profile, setField } =
    usePersonaStore((store) => ({
      user_info: store.user_info,
      quote: store.quote,
      health_advocate_profile: store.health_advocate_profile,

      setField: store.setField,
    }))

  const noteText =
    '生成人物誌有三個關鍵要素：<strong>「行為特徵」</strong>、<strong>「角色/職業」</strong>和<strong>「目標/任務」</strong>'
  const [keyword, setKeyword] = useState('')

  useEffect(() => {
    // const json_urls = [
    //   {
    //     url: 'data/persona/user_info.json',
    //     dataName: 'user_info',
    //   },
    //   {
    //     url: 'data/persona/quote.json',
    //     dataName: 'quote',
    //   },
    //   {
    //     url: 'data/persona/health_advocate_profile.json',
    //     dataName: 'health_advocate_profile',
    //   },
    // ]

    if (module.module4) {
      initMultipleDataFromJson(module.module4.json_urls, setField)
    }

    if (!module.title) {
      setKeyword('')
      setShowGen({
        ...showGen,
        show: true,
        isToolBarBtn: true,
        noteText: `${noteText}，您可用習慣的句型輸入提示語。`,
        noteContent: [
          '經常<strong>騎自行車</strong>的上班族，希望<strong>找到通勤最佳方案</strong>',
          '<strong>折疊自行車設計師</strong> <strong>注重ESG</strong> <strong>設計模組化產品</strong> <strong>容易回收的服務</strong>',
          '對<strong>科技感興趣</strong>的<strong>健身教練</strong>，<strong>探索最新AI自動化課程工具</strong>',
        ],
        showOther: '*若無輸入特定參數，AI會根據上下文自動匹配適合的內容。',
        modal: 0,
        func: () => {},
        placeholder:
          '經常 [描述行為特徵] 的 [角色/職業]，想要 [達成什麼目標/任務]',
      })
    } else {
      setKeyword(
        '一位積極參與自行車同好會的物理治療師，每天都在思考著如何讓騎自行車更加安全、普及，養成全民運動習慣'
      )
    }
  }, [])

  const [tab] = useState(0)

  return (
    module.module4 && (
      <EditWrapper
        setting={{
          showInput: module.show_input,
          toolbarTitle: '生成人物誌',
          toolbarTitleDesc: noteText,
          moduleTitle: module.title,
          moduleKeyword: keyword,
          records,
          showToolbar: true,
          boards,
          boardStyle,
          switchBoard,
        }}
      >
        <div className="tab-panel">
          <EditorArea
            setting={{
              user_info,
              health_advocate_profile,
              quote: quote ? quote.quote : [],
              newList: module.module4.category.filter((item, i) => i === tab)[0]
                .newList,
              showToolbar: true,
              boardStyle,
            }}
          />
        </div>
      </EditWrapper>
    )
  )
}

export default Module4
