/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useContext } from 'react'
import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'
import {
  Image,
  Form,
  Button,
  DropdownButton,
  OverlayTrigger,
  Tooltip,
} from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { DraftContext } from './ContextProvider'
import Market from './regenText/Market'
import User from './regenText/User'

function CreateModule({ setting }) {
  const { mode = 'create', handleClose = () => {}, records = [] } = setting
  const { setDraftId, setCurrentID } = useContext(DraftContext)
  const navigate = useNavigate()
  const [options] = useState([
    {
      id: 1,
      title: '市場研究',
      placeholder: '我想分析 [市場] 的 [產業/服務]',
      sub: [
        {
          id: 1,
          title: 'PESTEL',
          content:
            '幫助團隊在專案初期了解目標市場的六大外部因素：「政治、經濟、社會、技術、環境、法規」，以評估產品發展機會及潛在挑戰。',
          icon: 'option-icon01.svg',
          img: 'pestel.svg',
        },
        {
          id: 2,
          title: '競品分析',
          content:
            '提供四個分析面向：「競品分析表、產品分析矩陣、設計機會及挑戰、創新機會方案」，協助辨識產業趨勢、比較市場競爭者的優劣，更搭配不同面向的創新方案，發掘創新機會，以幫助團隊制定產品設計決策。',
          icon: 'option-icon02.svg',
          placeholder: '',
          img: 'bubble.svg',
        },
      ],
    },
    {
      id: 2,
      title: '使用者研究',
      placeholder:
        '經常 [描述行為特徵] 的 [角色/職業]，想要 [達成什麼目標/任務]',
      sub: [
        {
          id: 3,
          title: '顧客旅程',
          content:
            '系統化地描繪潛在客戶與產品互動的旅程，紀錄產品或服務的關鍵接觸點及相應的情緒變化，以協助團隊檢視使用者的需求和痛點，分析需求缺口和創新機會。',
          icon: 'option-icon03.svg',
          img: 'progress.svg',
        },
        {
          id: 4,
          title: '人物誌',
          content:
            '勾勒目標使用者的樣貌，協助團隊深入了解使用者的「動機、行為、個性、目標、偏好、痛點、需求」，幫助團隊根據洞察提出優化設計的建議。',
          icon: 'option-icon04.svg',
          placeholder: '',
          img: 'human.svg',
        },
      ],
    },
  ])
  const [file, setFile] = useState(null)
  const handleChange = (e) => {
    setFile(e)
  }

  const handleDownload = () => {
    if (!(file instanceof Blob)) {
      console.error('The provided file is not a Blob instance')
      return
    }

    const reader = new FileReader()
    // 用 FileReader 讀取檔案
    reader.readAsDataURL(file)
    reader.onload = () => {
      const url = reader.result
      const a = document.createElement('a')
      a.href = url
      a.download = file.name
      document.body.appendChild(a)
      a.click()
      document.body.removeChild(a)
    }
    reader.onerror = (error) => {
      console.error('Error reading file:', error)
    }
  }

  const [keyword, setKeyword] = useState('')
  const [selectOption, setSelectOption] = useState(1)
  const [keywordList] = useState([
    { list: ['超跑產業在美國明尼蘇達州的市場分析'] },
    { list: ['超跑產業在美國明尼蘇達州的市場分析'] },
    { list: ['運用超跑進行環美國比賽的賽車選手'] },
    { list: ['超跑產業在美國明尼蘇達州的市場分析'] },
    { list: ['超跑產業在美國明尼蘇達州的市場分析'] },
  ])

  const [placeholder, setPlaceholder] = useState(options[0].placeholder)

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      上傳文件讓AI根據您提供的資料生成客製化內容
    </Tooltip>
  )

  return (
    <div className="create-module">
      <Form className={mode === 'edit' && `mt-4`}>
        {mode === 'create' && <h2>請選擇研究範圍</h2>}
        {mode === 'create' && (
          <div className="option-group">
            {options.map((group, index) => (
              <div className="option-item" key={`group${index}`}>
                <h3>{group.title}</h3>
                <input
                  name="option"
                  type="radio"
                  id={`option${group.id}`}
                  className="form-check-input d-none"
                  value={group.id}
                  defaultChecked={group.id === 1}
                  onChange={(e) => {
                    setPlaceholder(group.placeholder)
                    setSelectOption(Number(e.target.value))
                  }}
                />
                <div
                  className={`option-item-block ${
                    selectOption === group.id ? 'active' : ''
                  }`}
                  role="button"
                  tabIndex={0}
                  onClick={() => {
                    setPlaceholder(group.placeholder)
                    setSelectOption(Number(group.id))
                  }}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter' || e.key === ' ') {
                      setPlaceholder(group.placeholder)
                      setSelectOption(Number(group.id))
                    }
                  }}
                  onTouchStart={() => {
                    setPlaceholder(group.placeholder)
                    setSelectOption(Number(group.id))
                  }}
                >
                  <div className="d-flex flex-wrap justify-content-between">
                    {group.sub.map((option) => (
                      <div className="option" key={`option-${option.id}`}>
                        <div className="option-title">
                          <strong>{option.title}</strong>
                        </div>
                        <div className="option-icon">
                          <OverlayTrigger
                            placement="left"
                            delay={{ show: 250, hide: 400 }}
                            overlay={
                              <Tooltip
                                id={`button-tooltip-${group.id}-${option.id}`}
                              >
                                <p
                                  dangerouslySetInnerHTML={{
                                    __html: option.content,
                                  }}
                                />
                              </Tooltip>
                            }
                          >
                            <Image
                              src={`/images/svgicons/${option.icon}`}
                              alt=""
                              className="img-fluid"
                            />
                          </OverlayTrigger>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
        <div className="create-area">
          {mode === 'create' ? (
            <p className="text-center">輸入簡單的提示語讓AI幫您生成分析結果</p>
          ) : (
            <div className="records">
              <div className="d-flex align-items-center">
                <Button
                  className="return-btn btn-text"
                  style={{ marginRight: '10px' }}
                  onClick={() => handleClose()}
                >
                  <span
                    className="svgIcon"
                    style={{
                      maskImage: `url('/images/svgicons/return.svg')`,
                    }}
                  >
                    return
                  </span>
                  返回專案
                </Button>
              </div>
              <div className="records-wrap">
                {records.map((record, index) => (
                  <div className="record-card" key={index}>
                    <div className="inner h-100">
                      <div className="date">{record.create_date} 生成：</div>
                      <strong>{record.title}</strong>
                      <p className="flex-1">{record.content}</p>
                      <Button
                        className="btn-primary"
                        size="md"
                        onClick={() => {
                          handleClose()
                        }}
                      >
                        <Image
                          src="/images/svgicons/preview.svg"
                          alt=""
                          className="mx-3"
                        />
                        還原此版本
                      </Button>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
          {mode === 'create' && (
            <div className="create-group-wrap">
              <div className="create-group">
                <DropdownButton
                  className="select-list"
                  title={
                    <>
                      <OverlayTrigger
                        placement="bottom"
                        delay={{ show: 250, hide: 400 }}
                        overlay={renderTooltip}
                      >
                        <Form.Label className="mb-0 file-input" htmlFor="file">
                          <div
                            className="svgIcon"
                            style={{
                              maskImage: `url('/images/svgicons/keyword.svg')`,
                            }}
                          >
                            keyword
                          </div>
                        </Form.Label>
                      </OverlayTrigger>
                      <Form.Control
                        type="file"
                        id="file"
                        name="file"
                        defaultValue={null}
                        className="d-none"
                        onChange={(e) => {
                          handleChange(e.target.files[0])
                        }}
                      />
                      <Form.Control
                        id="keyword"
                        name="keyword"
                        type="text"
                        placeholder={placeholder}
                        className="border-0 flex-1 mx-0"
                        aria-label={placeholder}
                        defaultValue={keyword}
                      />
                    </>
                  }
                >
                  <div className="keyword-list">
                    <ul>
                      <li>
                        {keywordList[selectOption].list.map((item, index) => (
                          <Button
                            className="btn-text"
                            key={index}
                            onClick={() => setKeyword(item)}
                          >
                            {item}
                          </Button>
                        ))}
                      </li>
                    </ul>
                  </div>
                </DropdownButton>
                <Button
                  className="btn btn-primary btn-md"
                  onClick={() => {
                    setDraftId(100)
                    if (selectOption === 2) {
                      setCurrentID(3)
                      navigate('/module3')
                    } else {
                      setCurrentID(1)
                      navigate('/module1')
                    }
                  }}
                >
                  <div
                    className="svgIcon bg-white mx-2"
                    style={{
                      maskImage: `url('/images/svgicons/status.svg')`,
                      transform: 'translateY(1px)',
                    }}
                  >
                    icon
                  </div>
                  開始生成
                </Button>
              </div>
              {selectOption === 1 ? <Market /> : <User />}
            </div>
          )}
          {file && (
            <div className="d-flex justify-content-center mt-4">
              <button
                type="button"
                onClick={handleDownload}
                className="btn-link btn border-0"
              >
                {file.name}
              </button>
              <button
                type="button"
                onClick={() => handleChange(null)}
                className="btn px-0"
              >
                <FontAwesomeIcon icon={faTimes} />
              </button>
            </div>
          )}
        </div>
      </Form>
    </div>
  )
}

CreateModule.propTypes = {
  setting: PropTypes.shape().isRequired,
}

export default CreateModule
