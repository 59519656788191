import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { Editor } from '@tinymce/tinymce-react'
import Loading from './Loading'

// https://www.tiny.cloud/docs/tinymce/latest/react-pm-bundle/
function TextArea({ setting }) {
  const {
    value,
    editInit = () => {},
    editHeight = () => {},
    handleRegenerate = () => {},
    showLoading = true,
  } = setting
  const editorRef = useRef(null)
  const defaultToolbar = `H1 H2 H3 | bold italic underline link | numlist bullist | regenerate`

  const [textLoading, setTextLoading] = useState(showLoading)
  const regenerate = () => {
    setTextLoading(true)
    if (setTextLoading) {
      setTimeout(() => {
        handleRegenerate()
      }, 900)

      setTimeout(() => {
        setTextLoading(false)
      }, 1000)
    }
  }

  useEffect(() => {
    setTextLoading(showLoading)
    if (setTextLoading) {
      setTimeout(() => {
        setTextLoading(false)
      }, 1000)
    }
  }, [showLoading])

  return (
    <div className="textarea-group">
      {textLoading && <Loading setting={{ style: '' }} />}
      <div className={`fade ${textLoading ? '' : 'show'}`}>
        <Editor
          tinymceScriptSrc="/tinymce/tinymce.min.js"
          onInit={(evt, editor) => {
            editorRef.current = editor

            // 判斷編輯器已載入後執行
            editInit()
          }}
          initialValue={value}
          // value={value}
          init={{
            height: 500,
            menubar: false,
            inline: true,
            license_key: 'gpl',
            file_picker_callback: (callback, fileValue, meta) => {
              if (meta.filetype === 'file') {
                callback('https://www.google.com/logos/google.jpg', {
                  text: 'My text',
                })
              }

              if (meta.filetype === 'image') {
                callback('https://www.google.com/logos/google.jpg', {
                  alt: 'My alt text',
                })
              }
            },
            setup: (editor) => {
              // 新增重新生成按鈕
              editor.ui.registry.addIcon(
                'regenerateIcon',
                '<img src="/images/svgicons/generate.svg" class="customIcon" alt="" />'
              )

              editor.ui.registry.addToggleButton('regenerate', {
                icon: 'regenerateIcon',
                tooltip: '重新生成',
                onAction: () => {
                  regenerate()
                },
              })

              editor.on('focus', () => {
                const editorHtml = editor.getElement()
                editorHtml.style.cursor = 'text'
                let parent = editor.getElement().parentElement
                while (parent) {
                  if (
                    parent.classList.contains('column-item') ||
                    parent.classList.contains('has-drag')
                  ) {
                    parent.classList.add('unfocus')
                  }
                  parent = parent.parentElement
                }
              })
              editor.on('blur', () => {
                const editorHtml = editor.getElement()
                editorHtml.style.cursor = ''
                let parent = editor.getElement().parentElement
                while (parent) {
                  if (
                    parent.classList.contains('column-item') ||
                    parent.classList.contains('has-drag')
                  ) {
                    parent.classList.remove('unfocus')
                  }
                  parent = parent.parentElement
                }
              })
            },
            plugins: [
              'advlist',
              'anchor',
              'autolink',
              'image',
              'link',
              'lists',
              'searchreplace',
              'table',
              'wordcount',
            ],
            toolbar: defaultToolbar,
          }}
          onEditorChange={() => {
            // 判斷編輯器高度
            editHeight()
            // console.log(e, itemIndex)
            // textEdit(e, itemIndex)
            // textEdit(label, itemIndex, e)
          }}
        />
      </div>
    </div>
  )
}

TextArea.propTypes = {
  setting: PropTypes.shape().isRequired,
}

export default TextArea
