import React, { useState, useEffect, useContext } from 'react'
// import { v4 as uuid } from 'uuid'
import { DraftContext } from '../components/ContextProvider'
import { EditWrapper } from '../components'
import SuggestPersona from '../components/SuggestPersona'
import { useCustomerJourneyStore } from '../store'
import { initMultipleDataFromJson } from '../lib/utility'

function Module3() {
  const { draft, showGen, setShowGen } = useContext(DraftContext)
  const [module] = useState(draft.draft_id && draft ? draft : {})
  const [boardStyle, setBoardStyle] = useState('horizon')
  const boards = [
    {
      id: 1,
      title: '顧客旅程橫式',
      image: '/images/boards/module3-horizon.svg',
      key: 'horizon',
    },
    {
      id: 2,
      title: '顧客旅程直式',
      image: '/images/boards/module3-vertical.svg',
      key: 'vertical',
    },
  ]

  const switchBoard = (board) => {
    setBoardStyle(board)
  }

  const {
    user_info,
    emotions,
    opportunities_and_painpoints,
    journeys,
    setField,
  } = useCustomerJourneyStore((store) => ({
    user_info: store.user_info,
    emotions: store.emotions,
    opportunities_and_painpoints: store.opportunities_and_painpoints,
    journeys: store.journeys,

    setField: store.setField,
  }))

  const noteText =
    '生成顧客旅程有三個關鍵要素：<strong>「行為特徵」</strong>、<strong>「角色/職業」</strong>和<strong>「目標/任務」</strong>'
  const [keyword, setKeyword] = useState('')

  useEffect(() => {
    // const json_urls = [
    //   {
    //     url: 'data/customer_journey/user_info.json',
    //     dataName: 'user_info',
    //   },
    //   {
    //     url: 'data/customer_journey/journeys.json',
    //     dataName: 'journeys',
    //   },
    //   {
    //     url: 'data/customer_journey/emotions.json',
    //     dataName: 'emotions',
    //   },
    //   {
    //     url: 'data/customer_journey/opportunities_and_painpoints.json',
    //     dataName: 'opportunities_and_painpoints',
    //   },
    // ]
    if (module.module3) {
      initMultipleDataFromJson(module.module3.json_urls, setField)
    }

    if (!module.title) {
      setKeyword('')
      setShowGen({
        ...showGen,
        show: true,
        isToolBarBtn: true,
        noteText: `${noteText}，您可用習慣的句型輸入提示語。`,
        noteContent: [
          '經常<strong>騎自行車</strong>的上班族，希望<strong>找到通勤最佳方案</strong>',
          '<strong>折疊自行車設計師</strong> <strong>注重ESG</strong> <strong>設計模組化產品</strong> <strong>容易回收的服務</strong>',
          '對<strong>科技感興趣</strong>的<strong>健身教練</strong>，<strong>探索最新AI自動化課程工具</strong>',
        ],
        showOther: '*若無輸入特定參數，AI會根據上下文自動匹配適合的內容。',
        modal: 0,
        func: () => {},
        placeholder:
          '經常 [描述行為特徵] 的 [角色/職業]，想要 [達成什麼目標/任務]',
      })
    } else {
      setKeyword(
        '一位積極參與自行車同好會的物理治療師，每天都在思考著如何讓騎自行車更加安全、普及，養成全民運動習慣'
      )
    }
  }, [])

  // temp records
  const [records] = useState([])

  return (
    <EditWrapper
      setting={{
        showInput: '',
        moduleTitle: module.title,
        toolbarTitle: '生成顧客旅程',
        toolbarTitleDesc: noteText,
        moduleKeyword: keyword,
        records,
        boards,
        boardStyle,
        switchBoard,
      }}
    >
      <div className="tab-panel">
        <div className="edit-block">
          <SuggestPersona
            setting={{
              user_info,
              emotions: emotions ? emotions.emotions : [],
              opportunities_and_painpoints: opportunities_and_painpoints
                ? opportunities_and_painpoints.opportunities_and_painpoints
                : [],
              journeys: journeys ? journeys.journeys : [],
              boardStyle,
            }}
          />
        </div>
      </div>
    </EditWrapper>
  )
}

export default Module3
