/* eslint-disable no-promise-executor-return */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect, useContext, useRef } from 'react'
import PropTypes from 'prop-types'
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd'
import { Form, Image, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { DraftContext } from '../../components/ContextProvider'
import AutoHeightText from '../../tools/AutoHeightText'
import CardTool from '../../components/CardTool'
import TootipItem from '../../components/TootipItem'
// import { getBgColor, getBglength } from '../../components/CompetitiveUtils'

function MatrixTable({ setting }) {
  const {
    module,
    onDragEnd = () => {},
    addItem = () => {},
    textEdit = () => {},
    deleteCard = () => {},
    priceType,
    setPriceType = () => {},
    priceNote,
    audiencesNote,
    showLoading,
  } = setting

  const dragItemStyle = (isDragging, draggableStyle) => ({
    userSelect: 'none',
    border: isDragging ? '2px solid #3F75ED' : 'none',
    ...draggableStyle,
  })

  const handlePriceType = (e) => {
    setPriceType(e.target.value)
  }

  // 價格區間幣別寬度
  const [currencyWidth, setCurrencyWidth] = useState(0)
  const textRef = useRef(null)
  const resizeTextArea = () => {
    setCurrencyWidth(textRef.current.offsetWidth + 20)
  }
  useEffect(resizeTextArea, [priceType])

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <div className="edit-block">
        <div className="row-header d-table w-100">
          <div className="row-list">
            <div className="row-column" style={{ width: '20%' }}>
              品牌名稱
            </div>
            <div className="row-column" style={{ width: '30%' }}>
              <div className="price-title">
                價格區間
                <span className="fade position-absolute" ref={textRef}>
                  {priceType}
                </span>
                <Form.Control
                  name="currency"
                  type="text"
                  placeholder="幣別"
                  defaultValue={priceType}
                  className="text-start"
                  style={{
                    width: `${currencyWidth}px`,
                    padding: '12px 10px',
                  }}
                  onChange={(e) => {
                    handlePriceType(e)
                  }}
                />
                <OverlayTrigger
                  placement="right"
                  delay={{ show: 250, hide: 400 }}
                  overlay={
                    <Tooltip
                      className="style-dark"
                      style={{
                        zIndex: '9999',
                      }}
                    >
                      <div
                        dangerouslySetInnerHTML={{
                          __html: priceNote,
                        }}
                      />
                    </Tooltip>
                  }
                >
                  <Image
                    src="/images/svgicons/tooltip.svg"
                    alt=""
                    className="img-fluid"
                  />
                </OverlayTrigger>
              </div>
            </div>
            <div className="row-column">
              主要客群
              <OverlayTrigger
                placement="right"
                delay={{ show: 250, hide: 400 }}
                overlay={
                  <Tooltip
                    className="style-dark"
                    style={{
                      zIndex: '9999',
                    }}
                  >
                    <div
                      dangerouslySetInnerHTML={{
                        __html: audiencesNote,
                      }}
                    />
                  </Tooltip>
                }
              >
                <Image
                  src="/images/svgicons/tooltip.svg"
                  alt=""
                  className="img-fluid mx-2"
                />
              </OverlayTrigger>
            </div>
          </div>
        </div>
        <div className="table-body">
          <Droppable droppableId="rowDrag">
            {(cprovided) => (
              <div className="stage-section" ref={cprovided.innerRef}>
                {module.map((item, index) => (
                  <Draggable key={item.id} draggableId={item.id} index={index}>
                    {(dragProvided, dragSnapshot) => (
                      <div
                        className="table-column dragrow has-drag"
                        ref={dragProvided.innerRef}
                        {...dragProvided.draggableProps}
                        style={{
                          ...dragItemStyle(
                            dragSnapshot.isDragging,
                            dragProvided.draggableProps.style
                          ),
                          minWidth: '100px',
                        }}
                        key={`row${item.id}`}
                      >
                        <TootipItem title="拖曳變更排序">
                          <div
                            className="drag-bar"
                            {...dragProvided.dragHandleProps}
                          >
                            <div className="module-control-btn">
                              <Image src="/images/toolicons/drag.svg" alt="" />
                            </div>
                          </div>
                        </TootipItem>
                        <Table
                          setting={{
                            content: item,
                            labelID: item.id,
                            deleteCard,
                            cardIndex: index,
                            textEdit,
                            addItem,
                            index,
                            showLoading,
                          }}
                        />
                      </div>
                    )}
                  </Draggable>
                ))}
                {cprovided.placeholder}
              </div>
            )}
          </Droppable>
        </div>
      </div>
    </DragDropContext>
  )
}

function Table({ setting }) {
  const {
    labelID,
    content = [],
    addItem = () => {},
    deleteCard = () => {},
    textEdit = () => {},
    showLoading,
    // index,
  } = setting

  const { showWarn, setShowWarn, showGen, setShowGen } =
    useContext(DraftContext)
  const [brandLoading, setBrandLoading] = useState(showLoading)
  const [itemLoading, setItemLoading] = useState(showLoading)

  const handleAdd = () => {
    setShowGen({
      ...showGen,
      show: true,
      modal: 0,
      func: () => {
        addItem(labelID)
        setItemLoading(true)
        setBrandLoading(true)

        setTimeout(() => {
          setItemLoading(false)
          setBrandLoading(false)
        }, 1000)
      },
      titleLabel: '品牌名稱',
      placeholder: '例: Gogoro',
    })
  }

  const handleRegenerate = () => {
    setItemLoading(true)
    setTimeout(() => {
      setItemLoading(false)
    }, 1000)
  }

  const handleDelete = () => {
    setShowWarn({
      ...showWarn,
      show: true,
      text: '確定移除列表？',
      func: () => {
        deleteCard(labelID)
      },
    })
  }

  return (
    <div
      className="column-item table-column"
      style={{ backgroundColor: content.bg }}
    >
      <div className="card-tool pos-left">
        <CardTool
          setting={{
            handleAdd,
            handleRegenerate,
            handleDelete,
          }}
        />
      </div>
      <div className="row-list inner">
        <div
          data-title="品牌名稱"
          className="row-column data-title first-col"
          style={{
            width: '20%',
            paddingLeft: '10px',
            paddingRight: '10px',
          }}
        >
          {brandLoading && <Loading />}
          <div className={brandLoading ? 'd-none' : ''}>
            <Form.Control
              name="brand_name"
              type="text"
              placeholder="品牌名稱"
              defaultValue={content.brand_name}
              className="text-center"
              onChange={(e) => {
                textEdit(labelID, e.target.value, 'brand_name')
              }}
            />
          </div>
        </div>
        <div
          data-title="價格區間"
          className="row-column data-title first-col"
          style={{
            width: '30%',
            paddingLeft: '10px',
            paddingRight: '10px',
          }}
        >
          {itemLoading && <Loading setting={{ style: 'one' }} />}
          <div
            className={`d-flex align-items-center ${
              itemLoading ? 'd-none' : ''
            }`}
          >
            <Form.Control
              name="title"
              type="text"
              placeholder="價格"
              defaultValue={content.price_banding_min}
              value={content.price_banding_min}
              className="text-end"
              onChange={(e) => {
                textEdit(labelID, e.target.value, 'price_banding_min')
              }}
            />
            <span className="px-2">~</span>
            <Form.Control
              name="title"
              type="text"
              placeholder="價格"
              defaultValue={content.price_banding_max}
              value={content.price_banding_max}
              onChange={(e) => {
                textEdit(labelID, e.target.value, 'price_banding_max')
              }}
            />
          </div>
        </div>
        <div
          data-title="主要客群"
          className="row-column data-title"
          style={{
            padding: '0px 10px',
          }}
        >
          {itemLoading && <Loading setting={{ style: 'one' }} />}
          <div
            className={`text-justify ${
              itemLoading ? 'fade position-absolute' : ''
            }`}
          >
            <AutoHeightText
              setting={{
                onChange: (value) => {
                  textEdit(labelID, value, 'audiences')
                },
                value: content.audiences,
                placeholder: '主要客群',
                className: '',
              }}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

function Loading() {
  return (
    <div
      className="loading-wrap"
      style={{
        position: 'static',
      }}
    >
      <div className="loading-bar">loading</div>
    </div>
  )
}

Table.propTypes = {
  setting: PropTypes.shape().isRequired,
}

MatrixTable.propTypes = {
  setting: PropTypes.shape().isRequired,
}

export default MatrixTable
