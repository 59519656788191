import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd'
import { Label, Star, Text, DragGallery, Loading } from '../../tools'
import DragBar from '../../components/DragBar'
import CardTool from '../../components/CardTool'

function InovationColumn({ setting }) {
  const { module, handleList = () => {}, list_index, showLoading } = setting

  const onDragEnd = (result) => {
    const { source, destination } = result
    if (!destination) {
      return
    }

    handleList(source, destination, list_index)
  }

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId={`card-drop-${list_index}`}>
        {(dropProvided) => (
          <div
            // eslint-disable-next-line
            {...dropProvided.droppableProps}
            ref={dropProvided.innerRef}
            className=""
          >
            {module.map((item, index) => (
              <Draggable
                key={`item-${index}`}
                draggableId={`column-${index}`}
                index={index}
              >
                {(dragProvided) => (
                  <div
                    ref={dragProvided.innerRef}
                    // eslint-disable-next-line
                    {...dragProvided.draggableProps}
                    // eslint-disable-next-line
                    // {...dragProvided.dragHandleProps}
                    className="inovation-card has-drag"
                  >
                    <InovationItem
                      setting={{
                        item,
                        dragProvided,
                        showLoading,
                        index,
                        list_index,
                      }}
                    />
                  </div>
                )}
              </Draggable>
            ))}
            <div>{dropProvided.placeholder}</div>
          </div>
        )}
      </Droppable>
    </DragDropContext>
  )
}

function InovationItem({ setting }) {
  const { item = {}, dragProvided, showLoading, index, list_index } = setting
  const [isLoading, setIsLoading] = useState(showLoading)
  const handleRegenerateAll = () => {
    setIsLoading(true)
    setTimeout(() => {
      setIsLoading(false)
    }, 1000)
  }

  useEffect(() => {
    if (isLoading) {
      setTimeout(() => {
        setIsLoading(false)
      }, 1000)
    }
  }, [])

  return (
    <div className="position-relative">
      <div className="card-tool pos-left">
        <CardTool
          setting={{
            handleRegenerate: () => handleRegenerateAll(index),
            onlyRegenerate: true,
            renGenText: '全部重新生成',
          }}
        />
      </div>
      <DragBar dragProvided={dragProvided} />
      <div className="inner d-flex">
        <div className="column-left position-relative">
          <div className="badge rounded-pill bg-white">
            {`方案${index + 1}`}
          </div>
          <div
            className="position-relative"
            style={{
              marginLeft: '-20px',
              marginRight: '-20px',
              marginBottom: '20px',
            }}
          >
            {isLoading && <Loading setting={{ style: 'one' }} />}
            <div className={`fade ${isLoading ? '' : 'show'}`}>
              <Label
                setting={{
                  title: item.headline,
                  edit: true,
                }}
              />
            </div>
          </div>
          <div className="position-relative">
            <div className="flex-1">
              <Text
                setting={{
                  content: [`<p>${item.ideas}</p>`],
                  label: list_index,
                  showLoading: isLoading,
                }}
              />
            </div>
            <div className="star">
              <Star
                setting={{
                  star: item.star,
                  showLoading: isLoading,
                }}
              />
            </div>
          </div>
        </div>
        <div className="column-right">
          <DragGallery
            setting={{
              imgs: item.images,
              list_index: index,
              showLoading: isLoading,
            }}
          />
        </div>
      </div>
    </div>
  )
}

InovationItem.propTypes = {
  setting: PropTypes.shape().isRequired,
}

InovationColumn.propTypes = {
  setting: PropTypes.shape().isRequired,
}

export default InovationColumn
