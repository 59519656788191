/* eslint-disable no-promise-executor-return */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-eval */
import React, { useState, useEffect, useContext } from 'react'
import PropTypes from 'prop-types'
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd'
import { Image, Button, Form } from 'react-bootstrap'
import { DraftContext } from './ContextProvider'
import SidebarClose from './SidebarClose'
import AllEditor from './AllEditor'
import Gallery from '../tools/Gallery'
import StoryCard from './StoryCard'
import TootipItem from './TootipItem'
import CardTool from './CardTool'
import { Label, TextArea } from '../tools'

function DragWrapper({ setting }) {
  const {
    // module = {},
    moduleList = {},
    user_info,
    quote = [],
    characteristics_and_focus = [],
    onDragEnd,
    handleInputChange = () => {},
    removeCard = () => {},
    showToolbar = false,
    boardStyle,
    health_advocate_profile = [],
  } = setting
  const {
    editDetail,
    componentType,
    setComponentType,
    sidebar,
    showWarn,
    setShowWarn,
  } = useContext(DraftContext)
  const [showDetail] = useState(false)
  const [editList, setEditList] = useState(editDetail[componentType])

  const setType = (type) => {
    setComponentType(type)
    setEditList(editDetail[type])
  }

  const dragAreaStyle = (isDraggingOver) => ({
    border: isDraggingOver ? '1px dashed #4099ff' : '',
  })

  const dragItemStyle = (isDragging, draggableStyle) => ({
    userSelect: 'none',
    border: isDragging ? '2px solid #717477' : 'none',
    ...draggableStyle,
  })

  const transToQuote = (array) => {
    let res = ''
    for (let index = 0; index < array.length; index += 1) {
      res += `<blockquote>${array[index]}</blockquote>`
    }
    return res
  }

  const transToList = (arr) => {
    if (!arr) return ''
    const listHtml = arr.map((item) => `<li>${item}</li>`).join('')
    return `<ul>${listHtml}</ul>`
  }

  const [constList] = useState([
    {
      title: '目標',
      meta_key: 'user_end_goal',
      content: transToList(health_advocate_profile.user_end_goal),
    },
    {
      title: '偏好',
      meta_key: 'lifestyle_preferences',
      content: transToList(health_advocate_profile.lifestyle_preferences),
    },
    {
      title: '痛點',
      meta_key: 'painpoints',
      content: transToList(health_advocate_profile.painpoints),
    },
    {
      title: '需求',
      meta_key: 'needs',
      content: transToList(health_advocate_profile.needs),
    },
  ])

  // loading
  const [showLoading, setShowLoading] = useState(true)
  const [badgeLoading, setBadgeLoading] = useState(true)
  useEffect(() => {
    if (showLoading) {
      setTimeout(() => {
        setShowLoading(false)
        setBadgeLoading(false)
      }, 1000)
    }
  }, [])

  const handleRegenerate = () => {
    setBadgeLoading(true)
    setTimeout(() => {
      setBadgeLoading(false)
    }, 1000)
  }

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      {showToolbar && (
        <>
          <Component setting={{ setType }} />
          <ComponentDetail
            setting={{
              show: showDetail,
              list: editList,
            }}
          />
        </>
      )}
      <div className={`edit-block ${sidebar ? 'open' : ''}`}>
        <div className="row">
          <div>
            {boardStyle === 'vertical' ? (
              <div className="d-xl-flex flex-wrap align-items-start">
                <div className="story-left">
                  <Gallery
                    setting={{ imgs: user_info?.gallery || [], setType }}
                  />
                </div>
                <div className="story-right">
                  <div className="position-relative ">
                    <div className="card-tool">
                      <CardTool
                        setting={{
                          handleRegenerate: () => handleRegenerate(0),
                          onlyRegenerate: true,
                          hideRegenerate: true,
                        }}
                      />
                    </div>
                    <ul className="spec">
                      {user_info.name && (
                        <PersonLabel
                          setting={{
                            labelTitle: '姓名',
                            name: 'name',
                            placeholder: '姓名',
                            value: user_info.name,
                            itemLoading: showLoading,
                          }}
                        />
                      )}
                      {user_info.age && (
                        <PersonLabel
                          setting={{
                            labelTitle: '年齡',
                            name: 'age',
                            placeholder: '年齡',
                            value: user_info.age,
                            itemLoading: showLoading,
                          }}
                        />
                      )}
                      {user_info.gender && (
                        <PersonLabel
                          setting={{
                            labelTitle: '性別',
                            name: 'gender',
                            placeholder: '性別',
                            value: user_info.gender,
                            itemLoading: showLoading,
                          }}
                        />
                      )}
                      {user_info.occupation && (
                        <PersonLabel
                          setting={{
                            labelTitle: '職業',
                            name: 'occupation',
                            placeholder: '職業',
                            value: user_info.occupation,
                            itemLoading: showLoading,
                          }}
                        />
                      )}
                      {user_info.family_status && (
                        <PersonLabel
                          setting={{
                            labelTitle: '家庭狀況',
                            name: 'family_status',
                            placeholder: '家庭狀況',
                            value: user_info.family_status,
                            itemLoading: showLoading,
                          }}
                        />
                      )}
                      {user_info.frequently_used_items && (
                        <PersonLabel
                          setting={{
                            labelTitle: '常用物件',
                            name: 'commonItems',
                            placeholder: '常用物件',
                            value: user_info.frequently_used_items,
                            itemLoading: showLoading,
                          }}
                        />
                      )}
                    </ul>
                    <div className="badge-label-text label-text">個性</div>

                    <div className="  position-relative has-drag mb-5">
                      {badgeLoading && <Loading setting={{ style: 'one' }} />}

                      <div className="card-tool pos-right">
                        <CardTool
                          setting={{
                            handleRegenerate: () => handleRegenerate(),
                            onlyRegenerate: true,
                          }}
                        />
                      </div>
                      <div className="badges-group position-relative has-drag">
                        <div className={`fade ${badgeLoading ? '' : 'show'}`}>
                          {characteristics_and_focus.map((item, index) => (
                            <span key={index} className="badge rounded-pill">
                              {item}
                            </span>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div>
                <div className="story-header">
                  <Gallery
                    setting={{ imgs: user_info?.gallery || [], setType }}
                  />
                  <div className="story-right horizon">
                    {user_info.name && (
                      <div className="story-name">
                        <strong>姓名</strong>
                        <Form.Control
                          name="name"
                          type="text"
                          placeholder="姓名"
                          defaultValue={user_info.name}
                          onChange={() => {}}
                        />
                      </div>
                    )}
                    <div className="position-relative flex-1">
                      <div className="card-tool">
                        <CardTool
                          setting={{
                            handleRegenerate: () => handleRegenerate(0),
                            onlyRegenerate: true,
                            hideRegenerate: true,
                          }}
                        />
                      </div>
                      <ul className="spec border-left">
                        {user_info.age && (
                          <PersonLabel
                            setting={{
                              labelTitle: '年齡',
                              name: 'age',
                              placeholder: '年齡',
                              value: user_info.age,
                              itemLoading: showLoading,
                            }}
                          />
                        )}
                        {user_info.gender && (
                          <PersonLabel
                            setting={{
                              labelTitle: '性別',
                              name: 'gender',
                              placeholder: '性別',
                              value: user_info.gender,
                              itemLoading: showLoading,
                            }}
                          />
                        )}
                        {user_info.occupation && (
                          <PersonLabel
                            setting={{
                              labelTitle: '職業',
                              name: 'occupation',
                              placeholder: '職業',
                              value: user_info.occupation,
                              itemLoading: showLoading,
                            }}
                          />
                        )}
                        {user_info.family_status && (
                          <PersonLabel
                            setting={{
                              labelTitle: '家庭狀況',
                              name: 'family_status',
                              placeholder: '家庭狀況',
                              value: user_info.family_status,
                              itemLoading: showLoading,
                            }}
                          />
                        )}
                        {user_info.frequently_used_items && (
                          <PersonLabel
                            setting={{
                              labelTitle: '常用物件',
                              name: 'commonItems',
                              placeholder: '常用物件',
                              value: user_info.frequently_used_items,
                              itemLoading: showLoading,
                            }}
                          />
                        )}
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="badge-label-text label-text">個性</div>
                <div className="badges-group position-relative has-drag">
                  <div className="card-tool">
                    <CardTool
                      setting={{
                        handleRegenerate: () => handleRegenerate(3),
                        onlyRegenerate: true,
                      }}
                    />
                  </div>
                  {badgeLoading && <Loading setting={{ style: 'one' }} />}
                  <div className={`fade ${badgeLoading ? '' : 'show'}`}>
                    {characteristics_and_focus.map((item, index) => (
                      <span key={index} className="badge rounded-pill">
                        {item}
                      </span>
                    ))}
                  </div>
                </div>
              </div>
            )}
            <div className="card-wrapper">
              {boardStyle === 'horizon' && (
                <div className="story-info">
                  <div className="card-wrapper">
                    <div className="position-relative has-drag">
                      <StoryCard
                        setting={{
                          label: '動機與行為',
                          text: user_info.motivations_and_behaviors,
                          className: 'hor-card',
                          icon: '/images/svgicons/story-icon04.svg',
                          showLoading,
                        }}
                      />
                    </div>
                    <div className="position-relative has-drag">
                      <StoryCard
                        setting={{
                          label: 'Quote',
                          text: transToQuote(quote),
                          className: 'hor-card',
                          icon: '/images/svgicons/story-icon05.svg',
                          showLoading,
                        }}
                      />
                    </div>
                  </div>
                  {constList.map(({ title, meta_key }, index) => (
                    <div key={index} className="position-relative has-drag">
                      <StoryCard
                        key={`storyInfo${index}`}
                        setting={{
                          label: `${title}`,
                          text: transToList(
                            eval(`health_advocate_profile.${meta_key}`)
                          ),
                          className: 'hor-card',
                          icon: `/images/svgicons/story-icon0${
                            (index % 5) + 1
                          }.svg`,
                          showLoading,
                        }}
                      />
                    </div>
                  ))}
                </div>
              )}
              {boardStyle === 'vertical' && (
                <div className="card-wrapper">
                  <div className="module-control">
                    <div className="row row-item">
                      {quote && (
                        <div className="blockquote-list col-xl-6">
                          {quote.map((text, index) => (
                            <blockquote
                              key={`blockquote${index}`}
                              className="blockquote"
                            >
                              <div className="inner">
                                <TextArea
                                  setting={{
                                    label: '',
                                    value: text,
                                    showLoading,
                                  }}
                                />
                              </div>
                            </blockquote>
                          ))}
                        </div>
                      )}
                      <div className="col-xl-6 position-relative has-drag mb-5">
                        <StoryCard
                          setting={{
                            label: '動機與行為',
                            text: user_info.motivations_and_behaviors,
                            className: 'inner shadow-block',
                            showLoading,
                          }}
                        />
                      </div>
                      {constList.map(({ title, meta_key }, index) => (
                        <div
                          className="col-xl-6 position-relative has-drag mb-5"
                          key={`constList_${index}`}
                        >
                          <div className="inner shadow-block">
                            <div
                              style={{
                                marginLeft: '-20px',
                                marginRight: '-20px',
                              }}
                            >
                              <Label
                                setting={{
                                  label: '',
                                  title,
                                  edit: true,
                                }}
                              />
                              <TextArea
                                setting={{
                                  label: '',
                                  value: transToList(
                                    eval(`health_advocate_profile.${meta_key}`)
                                  ),
                                  showLoading,
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              )}
            </div>
            {boardStyle === 'vertical' && (
              <div className="feature-list position-relative has-drag mb-5">
                <div className="card-tool pos-left">
                  <CardTool
                    setting={{
                      handleRegenerate: () => handleRegenerate(),
                      onlyRegenerate: true,
                    }}
                  />
                </div>
              </div>
            )}
          </div>

          {Object.keys(moduleList).map((cardKey) => (
            <Droppable key={cardKey} droppableId={cardKey}>
              {(cprovided, csnapshot) => (
                <div
                  key={cardKey}
                  className="stage-section"
                  ref={cprovided.innerRef}
                  style={dragAreaStyle(csnapshot.isDraggingOver)}
                >
                  <div className="card-wrapper">
                    {moduleList[cardKey].cards.length === 0 ? (
                      <div className="empty-placeholder">Drop items here</div>
                    ) : (
                      moduleList[cardKey].cards.map(
                        ({ id, type, content }, index) => (
                          <Draggable key={id} draggableId={id} index={index}>
                            {(dragProvided, dragSnapshot) => (
                              <div
                                ref={dragProvided.innerRef}
                                {...dragProvided.draggableProps}
                                style={dragItemStyle(
                                  dragSnapshot.isDragging,
                                  dragProvided.draggableProps.style
                                )}
                                className="dragrow has-drag"
                              >
                                <TootipItem title="拖曳變更排序">
                                  <div
                                    className="drag-bar"
                                    {...dragProvided.dragHandleProps}
                                  >
                                    <div className="module-control-btn">
                                      <Image
                                        src="/images/toolicons/drag.svg"
                                        alt=""
                                      />
                                    </div>
                                  </div>
                                </TootipItem>
                                <div className="module-control">
                                  <div
                                    className="card-tool"
                                    style={{
                                      left: '10%',
                                      right: 'auto',
                                    }}
                                  >
                                    <TootipItem title="移除">
                                      <Button
                                        className="btn-text"
                                        onClick={() => {
                                          setShowWarn({
                                            ...showWarn,
                                            show: true,
                                            text: '確定移除元件？',
                                            func: () => {
                                              removeCard(cardKey, id)
                                            },
                                          })
                                        }}
                                      >
                                        <div
                                          className="svgIcon"
                                          style={{
                                            maskImage: `url('/images/svgicons/trash.svg')`,
                                          }}
                                        >
                                          刪除
                                        </div>
                                      </Button>
                                    </TootipItem>
                                  </div>
                                  <AllEditor
                                    setting={{
                                      id,
                                      label: cardKey,
                                      type,
                                      content,
                                      handleInputChange,
                                      index,
                                    }}
                                  />
                                </div>
                              </div>
                            )}
                          </Draggable>
                        )
                      )
                    )}
                    {cprovided.placeholder}
                  </div>
                </div>
              )}
            </Droppable>
          ))}
        </div>
      </div>
    </DragDropContext>
  )
}

// 人物標籤
function PersonLabel({ setting }) {
  const {
    labelTitle = '',
    name = '',
    value,
    placeholder = '',
    itemLoading,
  } = setting

  return (
    <li>
      <span className="label-text">{labelTitle}</span>
      <div className="col position-relative">
        {itemLoading && <Loading setting={{ style: 'one' }} />}
        <div className={`fade ${itemLoading ? '' : 'show'}`}>
          <Form.Control
            name={name}
            type="text"
            placeholder={placeholder}
            defaultValue={value}
            onChange={() => {}}
          />
        </div>
      </div>
    </li>
  )
}

// 拖拉元件
function Component({ setting }) {
  const { setType } = setting
  const [currentEle, setCurrentEle] = useState(0)
  const { editItems, openSidebar, sidebar } = useContext(DraftContext)

  return (
    <div className={`edit-components ${sidebar ? 'show' : ''}`}>
      <div className="scroll-sidebar">
        <div className="sidebar-wrap">
          {editItems.map((item, index) => (
            <Button
              key={`item-${index}`}
              className="edit-item btn-text"
              active={item.id === currentEle}
              onClick={() => {
                setType(item.type)
                setCurrentEle(item.id)
                openSidebar()
              }}
            >
              <div className="edit-item-icon">
                <Image
                  src={`/images/toolicons/${item.icon}`}
                  className="mx-0"
                  alt=""
                />
              </div>
              {item.name}
            </Button>
          ))}
        </div>
      </div>
    </div>
  )
}

function ComponentDetail({ setting }) {
  const { list } = setting
  const { sidebar } = useContext(DraftContext)

  // const getItemStyle = (isDragging, draggableStyle) => ({
  //   userSelect: 'none',
  //   background: isDragging ? 'transparent' : '',
  //   ...draggableStyle,
  // })

  // const getListStyle = (isDraggingOver) => ({
  //   background: isDraggingOver ? '#00FF00' : '#FF0000',
  // })

  return (
    <div className={`component-detail ${sidebar ? 'show' : ''}`}>
      <div className="inner position-relative">
        <div className="scroll-sidebar">
          <div className="sidebar-wrap">
            <Droppable droppableId="elements" isDropDisabled>
              {(provided) => (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  // style={getListStyle(snapshot.isDraggingOver)}
                >
                  {list.map((item, index) => (
                    <Draggable
                      key={`${item.id}`}
                      draggableId={`${item.id}`}
                      index={index}
                    >
                      {(dragProvided, dragSnapshot) => (
                        <React.Fragment key={index}>
                          <div
                            className="snippet-item"
                            ref={dragProvided.innerRef}
                            {...dragProvided.draggableProps}
                            {...dragProvided.dragHandleProps}
                            // style={getItemStyle(
                            //   dragSnapshot.isDragging,
                            //   dragProvided.draggableProps.style
                            // )}
                          >
                            <div className="elememt">
                              {item.img ? (
                                <Image
                                  src={`/images/tools/${item.img}`}
                                  alt=""
                                  className="w-100"
                                />
                              ) : (
                                item.name
                              )}
                            </div>
                          </div>
                          {dragSnapshot.isDragging && (
                            <div className="elememt clone-item">
                              <Image
                                src={`/images/tools/${item.img}`}
                                alt=""
                                className="w-100"
                              />
                            </div>
                          )}
                        </React.Fragment>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </div>
        </div>
        <SidebarClose />
      </div>
    </div>
  )
}

function Loading({ setting }) {
  const { style } = setting

  if (style === 'one') {
    return (
      <div
        className="loading-wrap"
        style={{
          top: '5px',
          bottom: '5px',
          left: 0,
          right: 0,
        }}
      >
        <div className="loading-bar h-100">loading</div>
      </div>
    )
  }

  return (
    <div className="loading-wrap">
      <div className="loading-bar mb-2">loading</div>
      <div className="loading-bar mb-4">loading</div>
      <div className="loading-bar mb-2">loading</div>
      <div className="loading-bar mb-4">loading</div>
      <div className="loading-bar mb-2">loading</div>
      <div className="loading-bar">loading</div>
    </div>
  )
}

Loading.propTypes = {
  setting: PropTypes.shape().isRequired,
}

PersonLabel.propTypes = {
  setting: PropTypes.shape().isRequired,
}

ComponentDetail.propTypes = {
  setting: PropTypes.shape().isRequired,
}

Component.propTypes = {
  setting: PropTypes.shape().isRequired,
}

DragWrapper.propTypes = {
  setting: PropTypes.shape().isRequired,
}

export default DragWrapper
