/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-nested-ternary */
import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import SvgEmoji from '../components/SvgEmoji'

function EmojiIcon(props) {
  const { value, x, y } = props
  let emoji = 'smile'

  if (value >= 1 && value < 2.5) {
    emoji = 'bad'
  } else if (value >= 2.5 && value < 4.5) {
    emoji = 'normal'
  } else if (value >= 4.5 && value <= 5) {
    emoji = 'smile'
  }

  return <SvgEmoji value={emoji} x={x} y={y} />
}

function BarChat({ setting }) {
  const {
    emotions,
    width = 1000,
    height = 380,
    // boardStyle = 'horizon',
  } = setting
  const ref = useRef(null)
  const margin = 24

  const [dataPoints, setDataPoints] = useState([
    { x: 0, y: 0 },
    { x: 1, y: 0 },
    { x: 2, y: 0 },
    { x: 3, y: 0 },
    { x: 4, y: 0 },
  ])

  useEffect(() => {
    setDataPoints([
      { x: 1, y: emotions[0] },
      { x: 3, y: emotions[1] },
      { x: 5, y: emotions[2] },
      { x: 7, y: emotions[3] },
      { x: 9, y: emotions[4] },
    ])
  }, [])

  // 設定座標範圍到畫布範圍的轉換函數
  const scaleX = (x) => (x / 10) * (width - 2 * margin) + margin
  const scaleY = (y) => Math.round(height - ((height - margin) / 5) * y)
  const invertScaleY = (cy) => (height - cy) / ((height - margin) / 5)

  useEffect(() => {
    const svg = ref.current
    let isDragging = false
    let currentCircle = 0

    // svg.querySelectorAll('circle').forEach((circleItem, index) => {
    //   circleItem.addEventListener('mousedown', () => {
    //     isDragging = true
    //     currentCircle = index
    //   })
    // })
    svg.querySelectorAll('.emojiIcon').forEach((circleItem, index) => {
      circleItem.addEventListener('mousedown', () => {
        isDragging = true
        currentCircle = index
      })
    })

    svg.addEventListener('mousemove', (e) => {
      if (isDragging) {
        const rect = ref.current.getBoundingClientRect()
        const mouseY = e.clientY - rect.top
        let newY = invertScaleY(mouseY)

        // 限制 y 軸範圍在 1 到 5，並四捨五入到最近的 0.5
        newY = Math.round(Math.max(1, Math.min(5, newY)) * 2) / 2

        setDataPoints(
          dataPoints.map((item, index) =>
            index === currentCircle ? { x: item.x, y: newY } : item
          )
        )
      }
    })

    svg.addEventListener('mouseup', () => {
      isDragging = false
    })
  }, [dataPoints])

  return width < 10 ? null : (
    <svg ref={ref} width="100%" height={height}>
      <g className="visx-group visx-rows">
        {Array(6)
          .fill(1)
          .map((el, i) => (
            <g key={`line-${i}`}>
              <line
                x1={50}
                x2={width}
                y1={scaleY(i)}
                y2={scaleY(i)}
                stroke="#E2E4E7"
                strokeWidth={1}
              />
              {/* {i === 1 && <SvgEmoji value="bad" y={scaleY(i) - 18} />}
              {i === 3 && <SvgEmoji value="normal" y={scaleY(i) - 18} />}
              {i === 5 && <SvgEmoji value="smile" y={scaleY(i) - 15} />} */}
              {i > 0 && (
                <text x="10" y={scaleY(i) + 5}>
                  {i} 分
                </text>
              )}
            </g>
          ))}
      </g>
      <polyline
        id="polyline"
        fill="none"
        stroke="#0E1216"
        strokeDasharray="4"
        points={dataPoints
          .map((d) => `${scaleX(d.x)},${scaleY(d.y)}`)
          .join(' ')}
      />
      <g className="visx-group visx-points">
        {dataPoints.map((data, i) => (
          <EmojiIcon
            key={`circle-${i}`}
            value={data.y}
            x={scaleX(data.x) - 18}
            y={scaleY(data.y) - 18}
          />
        ))}
      </g>
      {/* <g className="visx-group visx-points">
        {dataPoints.map((data, i) => (
          <circle
            key={`circle-${i}`}
            r={15}
            cx={scaleX(data.x)}
            cy={scaleY(data.y)}
            fill="#FFD764"
            style={{
              cursor: 'grab',
            }}
          />
        ))}
      </g> */}
    </svg>
  )
}

EmojiIcon.propTypes = {
  value: PropTypes.number,
  x: PropTypes.number,
  y: PropTypes.number,
}

BarChat.propTypes = {
  setting: PropTypes.shape().isRequired,
}

export default BarChat
