/* eslint-disable no-promise-executor-return */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd'
import { Image, Form } from 'react-bootstrap'
import { Table } from '../../tools'
import TootipItem from '../../components/TootipItem'

function TableRow({ setting }) {
  const {
    module = [],
    onDragEnd = () => {},
    addItem = () => {},
    textEdit = () => {},
    arrayEdit = () => {},
    deleteCard = () => {},
    priceType,
    setPriceType = () => {},
    noteText,
    noteContent = [],
    showLoading,
  } = setting

  const dragItemStyle = (isDragging, draggableStyle) => ({
    userSelect: 'none',
    border: isDragging ? '2px solid #3F75ED' : 'none',
    ...draggableStyle,
  })

  const combinedStyle = (dragProvided, dragSnapshot, bg) => ({
    ...dragItemStyle(
      dragSnapshot.isDragging,
      dragProvided.draggableProps.style
    ),
    backgroundColor: bg,
  })

  // 新增時, 判斷是否loading
  const [newColumn, setNewColumn] = useState(-1)
  const isNewColumn = (newIndex) => {
    setNewColumn(newIndex)
    setTimeout(() => {
      setNewColumn(-1)
    }, 1000)
  }

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <div className="edit-block">
        <div className="table-body">
          <div className="color-card-body tableRow">
            <div className="row-header d-table w-100">
              <div className="row-list">
                <div className="row-column column-title">品牌名稱</div>
                <div className="row-column p-0">
                  <div className="d-flex flex-wrap">
                    <div className="row-column data-title width-3x">優勢</div>
                    <div className="row-column data-title width-3x border-0">
                      劣勢
                    </div>
                  </div>
                </div>
                <div className="row-column column-price">
                  <div className="price-title">
                    價格區間
                    <Form.Control
                      name="price"
                      type="text"
                      placeholder="幣別"
                      defaultValue={priceType}
                      className="text-start"
                      style={{ width: '80px' }}
                      onChange={(e) => {
                        setPriceType(e.target.value)
                      }}
                    />
                  </div>
                </div>
                <div className="row-column width-16">產品特點</div>
                <div className="row-column width-16">主要客群</div>
              </div>
            </div>
            <Droppable droppableId="rowDrag">
              {(cprovided) => (
                <div className="stage-section" ref={cprovided.innerRef}>
                  {module.map(
                    (
                      {
                        id,
                        brand_name,
                        strengths,
                        weaknesses,
                        price_banding_min,
                        price_banding_max,
                        features,
                        audiences,
                        bg,
                      },
                      index
                    ) => (
                      <Draggable key={id} draggableId={id} index={index}>
                        {(dragProvided, dragSnapshot) => (
                          <div
                            className="column-item table-column dragrow has-drag"
                            ref={dragProvided.innerRef}
                            {...dragProvided.draggableProps}
                            style={combinedStyle(
                              dragProvided,
                              dragSnapshot,
                              bg
                            )}
                            key={`row${index}`}
                          >
                            <TootipItem title="拖曳變更排序">
                              <div
                                className="drag-bar"
                                {...dragProvided.dragHandleProps}
                              >
                                <div className="module-control-btn">
                                  <Image
                                    src="/images/toolicons/drag.svg"
                                    alt=""
                                  />
                                </div>
                              </div>
                            </TootipItem>
                            <div className="row-list inner">
                              <Table
                                setting={{
                                  brand_name,
                                  strengths,
                                  weaknesses,
                                  price_banding_min,
                                  price_banding_max,
                                  features,
                                  audiences,
                                  labelID: id,
                                  deleteCard,
                                  cardIndex: index,
                                  textEdit,
                                  arrayEdit,
                                  addItem,
                                  showLoading,
                                  newColumn,
                                  isNewColumn,
                                  index,
                                  noteText,
                                  noteContent,
                                }}
                              />
                            </div>
                          </div>
                        )}
                      </Draggable>
                    )
                  )}
                  {cprovided.placeholder}
                </div>
              )}
            </Droppable>
          </div>
        </div>
      </div>
    </DragDropContext>
  )
}

TableRow.propTypes = {
  setting: PropTypes.shape().isRequired,
}

export default TableRow
